.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter {
  /* padding: 0.5rem; */
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 1rem;
  /* line-height: 1.75rem; */
  padding: 16px 24px;
  border: 1px solid var(--font-color-05);
  border-radius: 8px;
  text-decoration: none;
}

.show-counter .bg-detail-nft {
  background-color: #944fff;
  border: 2px solid #944fff;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.icon-countdown-lightmode svg circle {
  stroke: black;
}

.icon-countdown-lightmode-detail svg circle {
  stroke: white;
}

.icon-countdown-lightmode svg path {
  stroke: black;
}

.icon-countdown-lightmode-detail svg path {
  stroke: white;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (max-width: 639px) {
  .show-counter .countdown-link {
    gap: 8px;
    font-size: 12px;
    padding: 12px 20px;
    border: 1px solid var(--font-color-05);
    border-radius: 8px;
  }

  .show-counter .countdown-link svg {
    width: 14px;
    height: 14px;
  }

  .show-counter .countdown {
    line-height: 13px;
  }

  .show-counter .bg-detail-nft {
    border: 2px solid #944fff;
  }
}