.featured-page {
  min-height: calc(100vh - 50px - 215px - 65px);

  //section 02
  .section-02 {
    color: var(--font-color-title);
    width: $page-content-width;
    padding: 0 32px;
    margin: auto;
    .featured-collections {
      // margin-bottom: 105px;
      padding-bottom: 70px;
      .wrapper-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: 100px auto 10px;
        padding: 80px 0 10px 10px;
        font-weight: 700;
        font-size: 32px;
        white-space: nowrap;
        flex-direction: column;
        align-items: flex-start;
        .header-describe {
          color: var(--font-color-describle);
        }
        .show-all-item {
          font-size: 16px;
          line-height: 45px;
          color: #dc4de8;
          font-weight: 700;
        }
      }
      .grid-container-featured {
        position: relative;
        place-items: center;
        .react-multi-carousel-item {
          display: flex;
          justify-content: center;
        }
        .custom-link {
          text-decoration: none;
          color: #000000;
          height: 221px;
          // border-radius: 15px;
          margin: 12px 0;
          padding: 0 12px;
          .grid-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: end;
            justify-content: flex-end;
            border: var(--border-box-card);
            border-radius: 15px;
            // background-color: #333333;
            margin: 0;
            padding: 0;
            overflow: hidden;
            position: relative;
            box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
            //background-color: #ffffff;

            .banner-image {
              img {
                position: absolute;
                top: 0px;
                height: 50%;
                width: 100%;
                z-index: 0;
                object-fit: cover;
                touch-action: none;
              }
            }

            .wrapper-content {
              padding: 0px 15px 3px;
              z-index: 3;
              flex: 0.5 1 0%;
              text-align: left;
              width: 100%;
              color: var(--font-color);
              background-color: var(--background-color);

              .avatar {
                position: absolute;
                top: 35%;
                z-index: 1;
                width: 60px;
                height: 60px;
                img {
                  min-width: 60px;
                  min-height: 60px;
                  max-width: 60px;
                  max-height: 60px;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  border: 2px solid #ffffff;
                  object-fit: cover;
                  background-color: #ffffff;
                }
              }

              .name-label {
                margin-top: 50px;
                margin-bottom: 5px;
                font-size: 14px;
                letter-spacing: normal;
                font-weight: 700;
              }

              .total-item {
                font-size: 14px;
                color: var(--font-color-03);
                font-weight: 500;
              }
            }
          }
          &:hover {
            transform: scale(1.05);
            transition: 0.5s;
            .grid-item {
              border: 1px solid #2f8af5;
              box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
      &:hover {
        .arrow-featured-collections {
          .arrow-one {
            // background: #ffffff;
            opacity: 1;
          }
          .arrow-two {
            // background: #ffffff;
            opacity: 1;
          }
        }
      }
      .hot-collectibles {
        z-index: 100;
        position: unset;
        overflow: hidden !important;
        .react-multi-carousel-track {
          .react-multi-carousel-item {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .custom-box {
          // background-color: var(--background-color);
          border-radius: 15px;
          display: flex;
          color: var(--font-color);
          width: auto;
          padding: 10px 0;
          margin: auto;
          // border: 1px solid #444444;
          .hot-ollectibles-wrapper {
            position: relative;
            padding: 16px 24px;
            .hot-ollectibles-item:nth-child(1) {
              width: 100%;
              font-size: 12px;
              // margin: 10px 0px 0px;
              // padding: 0px 0px 0px 12px;
              // flex: 1 1 0%;
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .chain-name {
                font-weight: 400;
                font-size: 12px;
                color: #777777;
                text-transform: capitalize;
              }
            }
            // .hot-ollectibles-item:nth-child(2) {
            //   width: auto;
            //   text-align: right;
            //   // margin: 10px 12px 0 0;
            //   color: var(--color-title-chain);
            //   font-size: 12px;
            //   padding-left: 0px;
            //   text-transform: uppercase;
            // }
            .hot-ollectibles-item:nth-child(2) {
              padding: 8px 0 12px;
              display: flex;
              justify-content: center;
              width: fit-content;
              .img-token {
                width: 234px;
                height: 234px;
                position: relative;
                // margin: 32px auto;
                img,
                video {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  touch-action: none;
                  border-radius: 8px;
                }
              }
            }
            .hot-ollectibles-item:nth-child(3) {
              padding-bottom: 8px;
              .wrapper-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                // margin: 0 12px;
                font-size: 18px;
                font-weight: 700;
                font-size: 12px;
                // min-height: 52px;
                .content-left {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  gap: 4px;
                  color: var(--color-title-chain);

                  .avatar {
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      background-color: #ffffff;
                    }
                  }
                }
                // .content-left::after {
                //   background-color: transparent;
                //   background-position: 50% center;
                //   background-repeat: no-repeat;
                //   background-size: cover;
                //   content: '';
                //   display: block;
                //   margin: 0px 0px 0.3125rem 0.2rem;
                //   width: 0.55rem;
                //   height: 0.55rem;
                //   background-image: url('../../../assets/img/verify-icon.png');
                // }
                .content-right {
                  color: $purple-color-primary;
                  font-size: 12px;
                  // font-family: BrandonBlack, sans-serif;
                  text-transform: uppercase;
                  white-space: nowrap;
                }
              }
            }
            .hot-ollectibles-item:nth-child(4) {
              // display: flex;
              // justify-content: center;
              // text-align: center;
              .product-name {
                font-size: 16px;
                text-align: left;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 700;
                // margin: 12px;
              }
            }
            .hot-items-editions {
              // position: absolute;
              // top: 30px;
              // left: 10px;
              border-radius: 100%;
              background-color: #777777;
              width: 26px;
              height: 26px;
              display: grid;
              place-items: center;
              .icon-eternal {
                width: 24px;
                border-radius: 100%;
              }
            }
            .price-and-remaining {
              color: #fff;
              position: absolute;
              background: rgba(27, 27, 53, 0.5);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 9px;
              gap: 8px;
              left: 8px;
              bottom: 8px;
              width: 218px;
              border-radius: 8px;
              opacity: 0.9;
              .wrapper-price {
                width: 50%;
              }
              .wrapper-remaining {
                width: 50%;
              }
            }
            .wrapper-price-type {
              color: #fff;
              position: absolute;
              background: rgba(27, 27, 53, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 8px;
              gap: 7px;
              left: 8px;
              bottom: 8px;
              width: 218px;
              border-radius: 8px;
              opacity: 0.9;
              height: 57px;
              .price-type-home {
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }
      .responsive-list-nft {
        grid-template-columns: repeat(4, 1fr);
      }
      .faucet {
        .margin-top--50 {
          margin-top: -50px;
        }
        .box-faucet {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 700;
          margin: 0 8px;
          max-width: 100%;
          color: var(--font-color);
          background: var(--background-bottom-box-card);
          border-radius: 8px;
          .grid-col-6 {
            grid-template-columns: repeat(6, calc(100% / 6 - 12px));
          }
          .circle {
            width: 28px;
            height: 28px;
            border: 1px solid white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--color-circle-stt);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .title-1 {
            color: var(--color-circle-stt);
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 12px;
          }
          .title-2 {
            color: var(--color-circle-stt);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 28px;
          }
          .title-3 {
            color: var(--color-circle-stt);
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            //padding-top: 38px;
          }
          .title-4 {
            color: var(--color-circle-stt);
            text-align: right;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            //padding-top: 38px;
          }
          .title-3-box-first {
            position: relative;
            padding-top: 0;
          }
          .wrapper-twitter-faucet {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 8px;
            margin-left: 28px;
            .follow-x {
              text-decoration: underline;
              color: #2f8af5;
              font-size: 14px;
              line-height: 16.41px;
            }
            .text-follow-x {
              font-size: 12px;
              line-height: 14.06px;
              color: var(--color-circle-stt);
            }
            .text-color-tele {
              color: var(--color-tele-bot);
            }
          }
          .table {
            --bs-table-bg: unset !important;
            --bs-table-color: var(--font-color) !important;
            font-size: 14px;
            white-space: nowrap;
            tr {
              border-style: unset !important;
            }
            thead {
              border-color: #2c2c2c;
            }
            tr td {
              border: none;
            }
          }
        }
        .box-grid-faucet {
          display: grid;
          align-items: start;
          gap: 24px 10px;
          grid-template-areas:
            'faucet-grid-1 faucet-grid-2'
            'faucet-grid-3 faucet-grid-3';
          .faucet-grid-1 {
            grid-area: faucet-grid-1;
          }
          .faucet-grid-2 {
            grid-area: faucet-grid-2;
          }
          .faucet-grid-3 {
            grid-area: faucet-grid-3;
          }
        }
        .box-grid-faucet-box2 {
          //display: grid;
          align-items: start;
          gap: 10px;
          grid-template-areas:
            'faucet-grid-1 faucet-grid-2'
            'faucet-grid-3 faucet-grid-3';
          .faucet-grid-1 {
            grid-area: faucet-grid-1;
          }
          .faucet-grid-2 {
            grid-area: faucet-grid-2;
          }
          .faucet-grid-3 {
            grid-area: faucet-grid-3;
          }
        }
        .label-coin {
          color: var(--color-label-coin);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          justify-content: center;
        }
        .label-sign {
          color: var(--font-color);
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        // bonk roulette
        .bonk-roulette-div {
          display: flex;
          justify-content: center;
          padding: 0;
        }
        .bonk-roulette {
          position: relative;
          /* transform: rotate(-47deg) scale(0.7); */
        }
        .roulette-button {
          padding: 5px;
          cursor: pointer;

          position: absolute;
          top: 50%;
          left: 50%;
          z-index: +9;
          border-radius: 5rem;
          width: 7rem;
          height: 7rem;
          transform: translate(-50%, -50%) scale(0.75);
          background: white;
          font-weight: bold;
        }
        .bonk-spin-button {
          width: -webkit-fill-available;
        }
        .bonk-spin-button-disabled {
          width: -webkit-fill-available;
          opacity: 0.65;
        }
        .bonk-spin-button-count {
          position: absolute;
          font-size: 32px;
          color: #ffffff;
          display: flex;
          justify-content: flex-end;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -20%);
        }
        .bonk-spin-button-count-disabled {
          position: absolute;
          font-size: 32px;
          color: #ffffff;
          display: flex;
          justify-content: flex-end;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -20%);
        }
        .bonk-margin-left {
          margin-left: 44px;
        }
        .bonk-roulette-description-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        .bonk-roulette-description-div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .bonk-roulette-description {
          display: flex;
          flex-direction: row;
          background: #ffffff;
          padding: 18.5px 24px 18.5px 24px;
          margin: 5px;
          border-radius: 8px;
          width: 260px;
          justify-content: space-between;
        }
        .bonk-roulette-level-title {
          display: flex;
          flex-direction: row;
          font-size: 16px;
          font-weight: 700;
        }
        .bonk-roulette-level-text {
          display: flex;
          flex-direction: row;
          font-size: 12px;
          font-weight: 700;
        }
        .bonk-roulette-level-text-number {
          display: flex;
          flex-direction: row;
          color: #2061e2;
          font-weight: 700;
          margin-right: 4px;
          font-size: 16px;
        }
        .bonk-roulette-level-text-lv {
          display: flex;
          flex-direction: row;
          font-weight: 700;
          margin-top: 4px;
          font-size: 12px;
        }
        .bonk-count-div {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          justify-content: center;
          gap: 16px 10px;
        }
        .mobile-bonk-count-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
        }
        .bonk-count-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          padding: 8px 24px 8px 24px;
          background-color: #26263f;
          // width: 260px;
          height: 60px;
          // margin: 5px;
        }
        .center-box {
          grid-column: 2 / span 1; /* Start from the second column and span 1 column */
        }
        .bonk-count-text-1 {
          color: #92929f;
          font-size: 16px;
          font-weight: 700;
          display: flex;
          align-items: center;
          .big-text {
            line-height: 18.75px;
          }
          .small-text {
            font-size: 12px;
            line-height: 12px;
            margin-top: 4px;
          }
        }
        .bonk-count-text-2 {
          display: flex;
          flex-direction: row;
          color: #92929f;
          font-size: 16px;
          font-weight: 700;
          gap: 4px;
          align-items: center;
        }
        .bonk-count-1 {
          color: #6db0ff;
          font-size: 16px;
        }
        .bonk-count-2 {
          font-size: 12px;
          color: #92929f;
          font-weight: 700;
          margin-top: 3.5px;
          // margin-left: 3px;
        }
      }
    }
  }
  // @media (max-width: 1023px) {
  //   .section-02 {
  //     .featured-collections {
  //       .faucet {
  //         .box-grid-faucet {
  //           grid-template-areas:
  //             'faucet-grid-1'
  //             'faucet-grid-3'
  //             'faucet-grid-2';
  //         }
  //       }
  //     }
  //   }
  // }
  @media (max-width: 1299px) {
    .section-02 {
      .featured-collections {
        .responsive-list-nft {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
  @media (max-width: 999px) {
    .section-02 {
      .featured-collections {
        .responsive-list-nft {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @media (max-width: 768px) {
    .section-02 {
      padding: 0 20px;
      .featured-page {
        padding: 0 26px;
      }
      .featured-collections {
        padding-bottom: 40px;
        .grid-col-xs-2 {
          grid-template-columns: repeat(2, calc(100% / 2 - 10px)) !important;
          gap: 16px;
          // grid-template-columns: repeat(2, 1fr);
        }
        .grid-container-featured {
          // grid-template-columns: 1fr;
          .custom-link {
            // margin: 10px;
            height: 154px;
            width: 100%;
            .grid-item {
              width: 100%;
              .wrapper-content {
                .name-label {
                  margin-top: 24px;
                  font-size: 14px;
                  font-weight: 700;
                  margin-bottom: 0px;
                }
                .total-item {
                  font-size: 12px;
                  font-weight: 500;
                  color: #777777;
                }
              }
            }
          }
        }
        .hot-collectibles {
          display: flex;
          justify-content: space-between;
          // gap: 0;
          overflow-x: hidden !important;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          .custom-box {
            border-radius: 15px;
            display: flex;
            color: var(--font-color);
            width: 100%;
            // max-width: calc(100vw / 2 - 10px);
            padding: 0;
            .hot-ollectibles-wrapper {
              position: relative;
              width: 100%;
              height: 100%;
              padding: 8px 8px 12px 8px;
              .hot-ollectibles-item:nth-child(1) {
                font-size: 10px;
                .chain-name {
                  font-size: 10px;
                }
              }
              // .hot-ollectibles-item:nth-child(2) {
              //   display: none;
              //   width: auto;
              //   text-align: right;
              //   // margin: 10px 8px 0 0;
              //   color: var(--color-title-chain);
              //   // opacity: 0.8;
              //   font-size: 10px;
              //   // padding-left: 0px;
              //   text-transform: uppercase;
              // }
              .hot-ollectibles-item:nth-child(2) {
                padding: 4px 0 46px;
                width: 100%;
                .img-token {
                  width: 100%;
                  height: 146px;
                  position: relative;
                  // margin: 24px auto 14px;
                  img,
                  video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    touch-action: none;
                    border-radius: 8px;
                  }
                }
              }
              .hot-ollectibles-item:nth-child(3) {
                padding-bottom: 0;
                .wrapper-item {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  // margin: 0 10px;
                  // font-weight: 700;
                  font-size: 12px;
                  .content-left {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    gap: 4px;
                    color: var(--color-title-chain);
                    .avatar {
                      min-width: 18px;
                      width: 18px;
                      height: 18px;
                      border-radius: 100%;
                      box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                      overflow: hidden;
                      img {
                        width: 100%;
                        height: 100%;
                        background-color: #ffffff;
                      }
                    }
                  }
                  .content-right {
                    color: $purple-color-primary;
                    font-size: 10px;
                    text-transform: uppercase;
                    white-space: nowrap;
                  }
                }
              }
              .hot-ollectibles-item:nth-child(4) {
                display: flex;
                justify-content: center;
                text-align: center;
                .product-name {
                  font-size: 14px;
                  text-align: left;
                  overflow: hidden;
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-weight: 700;
                  // margin: 8px 10px;
                  padding-top: 6px;
                }
              }
              .hot-items-editions {
                // position: absolute;
                // top: 28px;
                // left: 10px;
                border-radius: 100%;
                background-color: #777777;
                width: 14px;
                height: 14px;
                display: grid;
                place-items: center;
                .icon-eternal {
                  width: 12px;
                  border-radius: 100%;
                }
              }
              .price-and-remaining {
                position: absolute;
                background: rgba(27, 27, 53, 0.5);
                backdrop-filter: blur(5px);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 4px;
                gap: 8px;
                left: 5%;
                bottom: -40px;
                width: 90%;
                height: 86px;
                border-radius: 8px;
                opacity: 1;
                flex-direction: column-reverse;
                .wrapper-price {
                  width: 100%;
                }
                .wrapper-remaining {
                  width: 100%;
                }
              }
              .wrapper-price-type {
                background: rgba(26, 26, 51, 0.5);
                backdrop-filter: blur(5px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 8px 4px;
                gap: 8px;
                left: 5%;
                bottom: -28px;
                width: 90%;
                height: 58px;
                border-radius: 8px;
                opacity: 1;
                .wrapper-remaining {
                  width: 100%;
                }
                .price-type-home {
                  width: 80px;
                  border-radius: 20px;
                  font-size: 10px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  background-color: #2f8af5;
                  padding: 2px;
                }
              }
            }
          }
        }
        .faucet {
          .box-faucet {
            margin: 0 auto;
            .circle {
              width: 24px;
              height: 24px;
              border: 1px solid white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--color-circle-stt);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
            .title-1 {
              color: var(--color-circle-stt);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-wrap: nowrap;
              margin-bottom: 11px;
            }
            .title-2 {
              color: var(--color-circle-stt);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-left: -10px;
            }
            .title-3 {
              color: var(--color-circle-stt);
              text-align: right;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-wrap: nowrap;
              position: static;
              padding-top: 0px;
            }
            .title-4 {
              color: var(--color-circle-stt);
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding-top: 0px;
              padding-bottom: 16px;
            }
            .wrapper-twitter-faucet {
              flex-direction: column;
              margin-left: 0;
              justify-content: center;
            }
          }
          .box-grid-faucet {
            grid-template-areas:
              'faucet-grid-1'
              'faucet-grid-3'
              'faucet-grid-2';
          }
          .box-grid-faucet-box2 {
            grid-template-areas:
              'faucet-grid-1'
              'faucet-grid-2'
              'faucet-grid-3';
          }
          .label-coin {
            color: var(--color-label-coin);
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            justify-content: center;
          }
          .label-sign {
            color: var(--font-color);
            text-align: center;
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .bonk-count-box {
            padding: 8px 10px 8px 10px;
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    .section-02 {
      .featured-collections {
        .wrapper-header {
          font-size: 26px;
          padding: 60px 0 10px 0;
        }
        .grid-container-featured {
          .custom-link {
            height: 140px;
            margin: 8px 0;
            padding: 0 6px;
            .grid-item {
              .wrapper-content {
                height: 50%;
                .avatar {
                  width: 30px;
                  height: 30px;
                  img {
                    min-width: 30px;
                    min-height: 30px;
                    max-width: 30px;
                    max-height: 30px;
                    border: 0.488px solid #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include Mobile() {
    .section-02 {
      // margin-top: 50px;
      .featured-collections {
        .wrapper-header {
          margin: auto;
          gap: 4px;
          .header-name {
            font-size: 24px;
          }
          .header-describe {
            font-size: 20px;
            font-weight: 400;
          }
          .show-all-item {
            font-size: 14px;
          }
        }
      }
      .grid-container-featured {
      }
      .page-grid {
        .hot-collectibles {
        }
        .title-header {
          font-size: 22px;
          margin-bottom: 5px;
        }
        margin: 0 auto 40px;
      }
    }
  }
  @media (max-width: 425px) {
    .section-02 {
      .featured-collections {
        gap: 8px;
        .grid-container-featured {
          .custom-link {
            height: 150px;
          }
        }
        .wrapper-header {
          gap: 4px;
          .header-name {
            font-size: 24px;
          }
        }
      }
      .grid-container-featured {
      }
    }
  }
  @media (max-width: 340px) {
    .section-02 {
      .grid-container-featured {
      }
    }
  }
  @media (max-width: 280px) {
    .section-02 {
      .featured-collections {
        .grid-container-featured {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
.tooltip-box-faucet {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
  color: #92929f !important;
  font-size: 12px !important;
  line-height: 18px !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  max-width: 240px !important;
  font-weight: 400;
}
.tooltip-box-faucet::after {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
}
