.payments-wallets-overlay {
  position: fixed;
  inset: 0;
  background: rgb(0, 0, 0, 0.75);
  color: #ffffff;

  .modal-dialog-payment {
    z-index: 21;
    position: fixed;
    inset: 0px;
    margin: auto;
    box-shadow: black -5px 5px 20px;
    outline: none;
    transition: all 500ms;
    width: 600px;
    height: 708px;
    background: var(--background-modal);
    color: var(--font-color);
    border-radius: 30px;

    .header {
      position: relative;
      font-size: 24px;
      font-weight: 700;
      color: var(--font-color);
      padding: 24px 0 26px;

      .title {
        text-align: center;
      }

      .close-button {
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 30px;
        width: 16px;
        height: 16px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .sub-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding-top: 24px;
    }

    .grid-payments {
      display: grid;
      grid-template-columns: auto auto;
      gap: 24px;
      padding: 26px 88px 24px;

      .payment-box {
        width: 200px;
        height: 200px;
        background: var(--background-box-baobab);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin: auto;
        cursor: pointer;

        &.disabled {
          // background-color: #272a3d;
          cursor: default;
          background: var(--bg-line-footer);
          color: var(--font-color-btn);
          opacity: 0.5;
        }

        .pay-item {
          width: 68px;
          height: 68px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .pay-talken {
          width: 74px;
        }
      }

      .active {
        border: 3px solid #2f8af5;
      }
    }

    .grid-payments-one {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      padding: 26px 88px 24px;

      .payment-box {
        width: 200px;
        height: 200px;
        background: var(--background-box-baobab);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin: auto;
        cursor: pointer;

        &.disabled {
          // background-color: #272a3d;
          cursor: default;
          background: var(--bg-line-footer);
          color: var(--font-color-btn);
          opacity: 0.5;
        }

        .pay-item {
          width: 68px;
          height: 68px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .pay-talken {
          width: 74px;
        }
      }

      .active {
        border: 3px solid #2f8af5;
      }
    }

    .custom-submit {
      // max-width: 600px;
      padding: 0 50px 24px;

      .payments-btn-submit {
        width: 100%;
        height: 60px;
        background: #2f8af5;
        border-radius: 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto;
        color: #ffffff;
      }

      .payment-error {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        color: red;
      }
    }
  }

  //payment wallet success

  .modal-dialog-pay-success {
    height: 592px;
    color: var(--font-color);

    .header {
      position: relative;
      font-size: 24px;
      font-weight: 700;
      color: var(--font-color);
      padding: 24px 0 26px;

      .title {
        text-align: center;
      }

      .close-button {
        position: absolute;
        top: 24px;
        right: 30px;
        width: 16px;
        height: 16px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .grid-content {
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      height: 480px;
      gap: 24px;
      padding: 0 50px;

      .grid-item {
        margin: auto;
        text-align: center;
      }

      .sub-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding-top: 24px;
      }

      .image-success {
        width: 182px;
        height: 182px;

        // margin: 24px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title-image {
        font-weight: 900;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
      }

      .sub-image {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-content-featured);
      }

      .button-got-it {
        background: #2f8af5;
        border-radius: 10px;
        width: 100%;
        height: 60px;
      }

      .share-contain {
        height: 56px;

        .icon-share {
          background-color: #ffffff;
          border-radius: 50%;
          width: 34px;
          height: 34px;
          cursor: pointer;

          &:hover {
            background-color: #bae7ff;
          }

          img {
            width: 16px;
            // border-radius: 50%;
            // margin: 5px;
          }
        }
      }
    }
  }

  .line-or {
    width: 100%;
    display: flex;
    padding: 20px 0 6px;

    &::before {
      content: '';
      border-bottom: 1px solid var(--color-or);
      flex: 1 0 auto;
      height: 10px;
      margin: 0;
    }

    .title-line-or {
      text-align: center;
      flex: 0.3 0 auto;
      margin: 0;
      color: var(--color-or);
    }

    &::after {
      content: '';
      border-bottom: 1px solid var(--color-or);
      flex: 1 0 auto;
      height: 10px;
      margin: 0;
    }
  }

  .modal-header {
    height: 44px;

    .modal-tittle {
      text-align: center;
      position: relative;
      font-size: 26px;
      margin-bottom: 50px;
      margin-top: 70px;

      .title {
        font-size: 36px;
      }

      .sub-title {
        font-size: 18px;
      }
    }

    .close-button {
      position: absolute;
      top: 20px;
      right: 15px;
    }
  }

  .modal-body {
    padding-top: 4rem;

    .payment-modal-box {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      margin-bottom: 44px;

      .abc {
        display: block;
        position: relative;
        width: 200px;
        height: 200px;
        background-color: #171717;
        border-radius: 40px;
        cursor: pointer;
      }

      img {
        position: absolute;
        inset: 0px;
        margin: auto;
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
    }

    .payment-modal-box> :first-child {
      margin-right: 30px;
    }

    .payments-btn-submit {
      border: none;
      color: #ffffff;
      width: 100%;
      font-size: 21px;
      height: 65px;
      cursor: pointer;
      background: linear-gradient(to left, rgb(103, 129, 249), rgb(227, 100, 190));
      border-radius: 14px;
    }

    .disabled {
      opacity: 0.5 !important;
      cursor: default;
      pointer-events: all !important;
      background: var(--bg-line-footer);
      color: var(--font-color-btn);
      opacity: 0.5;
    }

    .payment-modal-success-box {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;
        height: 100%;
      }
    }

    .content-payment-modal-success {
      text-align: center;
      margin-bottom: 2rem;

      .tittle {
        font-size: 32px;
        font-weight: 600;
      }

      .content {
        font-size: 16px;
      }
    }
  }

  @include Tablet_Mobile {
    .modal-dialog-payment {
      height: -webkit-fill-available;
      border-radius: 0px;
      margin: 0px;
      width: -webkit-fill-available;

      .modal-content {
        position: absolute;
        display: contents;
      }
    }
  }

  @media (max-width: 540px) {
    .modal-dialog-payment {
      padding: 0 20px;

      .header {
        font-size: 18px;

        .close-button {
          right: 0;
        }
      }

      .custom-submit {
        padding: 0 0 24px;
      }

      .grid-payments {
        padding: 26px 0px 24px;

        .payment-box {
          width: 120px;
          height: 120px;

          .pay-item {
            width: 50px;
            height: 50px;
          }

          .pay-talken {
            width: 56px;
          }
        }
      }

      .grid-payments-one {
        padding: 26px 0px 24px;

        .payment-box {
          width: 120px;
          height: 120px;

          .pay-item {
            width: 50px;
            height: 50px;
          }

          .pay-talken {
            width: 56px;
          }
        }
      }
    }

    .modal-dialog-pay-success {
      padding: 0 20px;
      overflow: scroll;

      .grid-content {
        padding: 0;
      }
    }
  }
}

//modal zoom image
.modal-zoom-image {
  position: absolute;
  inset: 0px;
  border: none;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
  border-radius: 0px;
  outline: none;
  padding: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 70px;
    top: 50px;
  }

  .image {
    display: block;
    width: 85%;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @media (max-width: 768px) {
    .close-modal {
      top: 20px;
      right: 20px;
    }

    .image {
      width: 90%;
      padding-top: 75px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 540px) {
    .image {
      width: 100%;
      padding-top: 0;
      padding-bottom: 150px;
    }
  }
}

// @media (max-width: 768px) {
//   .modal-dialog-payment {
//     .modal-content {
//       .modal-header {
//         .modal-tittle {
//           margin-top: 10px;
//         }
//       }
//     }
//   }
//   .modal-body {
//     .content-payment-modal-success {
//       .tittle {
//         font-size: 26px;
//         padding-bottom: 10px;
//       }
//     }
//   }
// }
// .modal-sm {
//   width: 300px;
// }

// .modal-lg {
//   width: 800px;
// }

// .modal-xl {
//   width: 1140px;
// }

// @media (max-width: 600px) {
//   .payments-wallets-overlay .modal-header {
//     height: 120px;
//     @include Mobile {
//       .modal-tittle {
//         .title {
//           font-size: 30px;
//         }
//       }
//     }
//   }
// }

// detail nft modal
.detail-nft-overlay {
  position: fixed;
  inset: 0;
  background: rgb(0, 0, 0, 0.75);
  color: #ffffff;

  .detail-nft-modal {
    z-index: 21;
    position: fixed;
    inset: 0;
    margin: auto;
    box-shadow: -5px 5px 20px #000;
    outline: none;
    transition: all 0.5s;
    width: 540px;
    // min-height: 588px;
    height: min-content;
    background: #fff;
    background: var(--background-modal);
    color: #1f1f1f;
    color: var(--font-color);
    border-radius: 24px;
    padding: 24px 24px 32px 24px;

    .close-button {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 30px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title-nft {
      font-size: 34px;
      font-weight: 900;
      line-height: 40px;
    }

    .info-creator {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      >img {
        border-radius: 100%;
        background-color: white;
        width: 32px;
        height: 32px;
      }

      .logo-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .creator {
          font-size: 10px;
          text-decoration: none;
          width: max-content;
        }

        .name {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
        }
      }
    }

    .wrapper-bidding-period {
      margin: 24px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .bidding-period-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 16px;

        .detailed-bidding-period {
          display: flex;
          gap: 4px;
          font-weight: 600;
        }
      }
    }

    .title-set-price {
      font-size: 14px;
      font-weight: 400;
      margin: 24px 0 8px;
      opacity: 0.7;
      line-height: 16.41px;
    }

    .wrapper-set-price {
      position: relative;
      width: 100%;
      margin-bottom: 56px;

      input {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: #ffffff1a;
        padding: 16px;
        border: unset;
        font-size: 16px;
        color: var(--font-color);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        &:focus {
          outline: none;
        }
      }

      .input-start-price {
        padding-right: 124px;
      }
    }

    .wrapper-set-price-ver {
      position: relative;
      width: 100%;
      margin-bottom: 24px;

      input {
        width: 100%;
        height: 48px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #ffffff1a;
        padding: 16px 0px;
        border: unset;
        font-size: 14px;
        color: var(--font-color);

        &:focus {
          outline: none;
        }
      }

      &-icon-plholder {
        background-color: #ffffff1a;
        height: 48px;
        width: 10%;
        padding: 10px 10px 10px 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border: none;
        color: var(--font-color);
      }

      .input-start-price {
        padding-right: 124px;
      }

      @media (max-width: 768px) {
        padding: 0px 0 16px;
        font-size: 10px;
        margin-bottom: 0px;

        input {
          height: 32px;
          padding: 10px 5px;
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
          font-size: 11px;
        }

        &-icon-plholder {
          background-color: #ffffff1a;
          height: 32px;
          width: 10%;
          padding: 6px 0px 0px 5px;
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          border: none;
          color: var(--font-color);
          font-size: 11px;
        }

        .btn-paste-otp {
          width: 74px;
          height: 32px;
          border-radius: 6px;
        }
      }
    }
  }

  .list-nft-modal {
    z-index: 21;
    position: fixed;
    inset: 0;
    margin: auto;
    box-shadow: -5px 5px 20px #000;
    outline: none;
    transition: all 0.5s;
    max-width: 540px;
    max-height: 620px;
    background: #fff;
    background: var(--background-modal);
    color: #1f1f1f;
    color: var(--font-color);
    border-radius: 24px;
    padding: 40px 24px 32px;

    .close-button {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 30px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title-nft {
      font-size: 34px;
      font-weight: 900;
      line-height: 40px;
    }

    .info-creator {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      >img {
        border-radius: 100%;
        background-color: white;
        width: 24px;
        height: 24px;
      }

      .logo-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .creator {
          font-size: 10px;
          text-decoration: none;
          width: max-content;
        }

        .name {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
        }
      }
    }

    .wrapper-bidding-period {
      margin: 26px 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .bidding-period-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detailed-bidding-period {
          font-weight: 600;
        }
      }
    }

    .title-set-price {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 8px;
      color: var(--text-detail);
    }

    .title-select {
      color: var(--color-select);
    }

    .wrapper-set-price {
      position: relative;
      width: 100%;
      margin-bottom: 24px;

      input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        background-color: #ffffff1a;
        padding: 16px;
        border: unset;
        font-size: 16px;
        color: var(--font-color);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        &:focus {
          outline: none;
        }
      }

      .input-start-price {
        padding-right: 100px;
      }
    }
  }

  @media (max-width: 639px) {
    .detail-nft-modal {
      width: 338px;
      min-height: 465px;
      border-radius: 12px;
      padding: 46px 20px 24px;

      .title-nft {
        font-size: 22px;
        line-height: 26px;
      }

      .wrapper-bidding-period {
        font-size: 11px;
        line-height: 9px;
        margin: 16px 0;
      }

      .title-set-price {
        margin: 14px 0 5px;
        font-size: 10px;
        line-height: inherit;
      }

      .wrapper-set-price {
        margin-bottom: 36px;

        input {
          height: 32px;
          padding: 10px 36px 10px 14px;
          font-size: 9px;
          border-radius: 6px;
          padding-right: unset;
        }

        .input-start-price {
          padding-right: unset;
        }
      }

      .btn-detail-nft {
        height: 40px;
        font-size: 14px;
        margin: 0 auto 26px;
      }
    }

    .list-nft-modal {
      width: 338px;
      height: fit-content;
      border-radius: 12px;
      padding: 46px 20px 20px;

      .title-nft {
        font-size: 22px;
        line-height: 26px;
      }

      .wrapper-bidding-period {
        font-size: 11px;
        line-height: 9px;
        margin: 0 0 16px 0;
      }

      .title-set-price {
        margin: 10px 0 5px;
        font-size: 10px;
      }

      .wrapper-set-price {
        margin-bottom: 0.25rem;

        input {
          height: 32px;
          padding: 10px 36px 10px 14px;
          font-size: 9px;
          border-radius: 6px;
          padding-right: unset;
        }

        .input-start-price {
          padding-right: unset;
        }
      }

      .btn-detail-nft {
        height: 40px;
        font-size: 14px;
        margin: 0 auto 26px;
      }
    }
  }

  //otp code modal
  .otp-code-modal {
    position: fixed;
    inset: 0;
    margin: auto;
    outline: none;
    transition: all 0.5s;
    width: 540px;
    height: 276px;
    background: var(--background-modal);
    color: var(--font-color);
    border-radius: 30px;

    .close-button {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 30px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header-otp {
      text-align: center;
      padding-top: 24px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: var(--font-color-08);
    }

    .body-otp {
      padding: 24px 24px 32px 24px;

      &-claim {
        padding: 24px 51px 36px 49px;
      }

      .title-input-otp {
        color: var(--font-color-08);
        font-weight: 600;
        font-size: 14px;
      }

      .transfer-wklay {
        margin-top: 2px;
        width: 501px;
        height: 48px;
        border-radius: 10px;
        margin-left: -2px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.1);
      }

      .wrapper-input-otp {
        padding: 12px 0 56px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        font-size: 14px;
        color: var(--font-color);

        input {
          background-color: #ffffff1a;
          height: 48px;
          width: 100%;
          padding: 10px 16px 10px 16px;
          border-radius: 8px;
          border: var(--boder-search-history);
          color: var(--font-color);
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

          &:focus {
            outline: none;
          }
        }

        .btn-paste-otp {
          height: 48px;
          width: 112px;
          border-radius: 10px;
          background-color: var(--bg-color-modal-otp-02);
          font-weight: 400;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          color: #ffffff;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .wrapper-total-claim {
        border-radius: 10px;
        background-color: #ffffff1a;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: var(--boder-search-history);
        color: var(--font-color);
        font-size: 12px;
        height: 48px;
        width: 100%;
        padding: 10px 16px 10px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        &-custom {
          font-size: 24px;
          justify-content: center;
          border-radius: unset;
          background-color: unset;
          box-shadow: unset;
          border: unset;
          height: unset;
          padding: unset;
        }
      }

      .note-claim-rewards {
        color: var(--font-color-03);
        font-size: 12px;
        line-height: 15px;
        display: flex;
        gap: 10px;
        align-items: center;
        padding-bottom: 10px;
      }
    }

    &-custom {
      height: 432px;
    }

    &-custom-talken-bridge {
      height: 780px;
    }

    &-cust {
      height: 630px;
    }

    &-cust-2 {
      height: 750px;
    }

    &-custom-claim-rewards {
      width: 600px;
      height: fit-content;
    }

    &-custom-claim-success {
      height: fit-content;
    }
  }

  @media (max-width: 639px) {
    .otp-code-modal {
      width: 338px;
      height: 220px;
      border-radius: 12px;

      .header-otp {
        padding-top: 20px;
        font-size: 16px;
      }

      .body-otp {
        padding: 24px 20px 30px;

        .title-input-otp {
          font-size: 12px;
        }

        .wrapper-input-otp {
          padding: 10px 0 36px;
          font-size: 10px;

          input {
            height: 32px;
            padding: 10px 14px;
            border-radius: 6px;
          }

          .btn-paste-otp {
            width: 74px;
            height: 32px;
            border-radius: 6px;
          }
        }

        .btn-detail-nft {
          height: 40px;
          font-size: 14px;
        }

        .wrapper-total-claim {
          height: 32px;
          gap: 8px;
        }

        .note-claim-rewards {
          padding-bottom: 6px;
        }
      }

      &-custom {
        height: 355px;
      }

      &-custom-talken-bridge {
        height: 590px;
      }

      &-cust {
        height: 460px;
      }

      &-cust-2 {
        height: 570px;
      }

      &-custom-claim-rewards {
        height: fit-content;
      }

      &-custom-claim-success {
        height: fit-content;
      }
    }

    .wallet-detail-modal {
      width: auto;
      height: auto;
    }
  }

  .wallet-detail-modal {
    position: fixed;
    inset: 0;
    margin: auto;
    outline: none;
    transition: all 0.5s;
    max-width: 430px;
    max-height: 309px;
    background: var(--background-modal);
    color: var(--font-color);
    border-radius: 12px;

    .close-button {
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 30px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .header-wallet {
      color: var(--font-color-08);
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    .line-my-wallet {
      border-bottom: 1px solid var(--border-line-modal);
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .text-detail {
      color: var(--text-detail);
      font-weight: 500;
    }

    @media (max-width: 768px) {
      max-width: 338px;
      max-height: fit-content;

      .wrapper-wallet-detail {
        padding: 20px 20px 24px 20px;

        .header-wallet {
          font-size: 16px;
          line-height: 24px;
        }

        .line-my-wallet {
          margin-top: 12px;
        }
      }
    }
  }

  .model-wallet-atc {
    .otp-code-modal {
      @extend .otp-code-modal;
      width: 430px;
      height: 450px;

      .logo-wallet-act {
        width: 60px;
        height: 60px;
        position: absolute;
        top: -28px;
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 100%;
        }
      }

      .title-wallet-act {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        color: var(--font-color);
        text-transform: capitalize;
        padding-top: 40px;
        padding-bottom: 8px;
        text-align: center;
        font-style: normal;
        line-height: normal;
      }

      .title-intro {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        color: var(--font-color-06);
        text-transform: capitalize;
        text-align: center;
        font-style: normal;
        line-height: 17.5px;
        padding: 0 45px;
      }

      .txt-token {
        padding-right: 15px;
        color: var(--font-color);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .qr-code {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 147px;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .btn-circle {
        width: 20px;
        height: 20px;
        text-align: center;
        background: var(--background-box-wallet);

        svg {
          width: 9px;
          height: 9px;
        }
      }

      .agree-background {
        padding: 10px 20px;
        margin: 25px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.1);

        .agree-all {
          display: flex;
          gap: 10px;
          font-weight: 600;
          font-size: 14px;
          padding-bottom: 2px;

          .icon-check-all {
            width: 24px;
            cursor: pointer;

            img {
              width: 100%;
            }
          }
        }

        .wrapper-agree {
          display: flex;
          flex-direction: column;
          gap: 10px;
          // padding-left: 2px;
          font-weight: 400;
          font-size: 12px;
          color: var(--font-color-02);

          .agree-item {
            display: flex;
            align-items: start;
            gap: 10px;

            .icon-check-agree {
              width: 24px;
              cursor: pointer;
              display: flex;

              img {
                min-width: 100%;
              }
            }

            .wrapper-agree-left {
              display: flex;
              padding-top: 1.5%;
              flex-wrap: wrap;

              span {
                color: #ff4d4f;
              }

              .text-terms {
                text-decoration: revert;
                color: var(--font-kakao-agree);
                padding-left: 4px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 639px) {
      .otp-code-modal {
        width: 338px;
      }
    }
  }

  .model-wallet-code {
    @extend .model-wallet-atc;

    .otp-code-modal {
      height: 551px;

      .btn-auth {
        height: 38px;
        font-size: 12px;
        background-color: #2f8af5;
        border-radius: 8px;
        font-weight: 500;
      }
    }
  }

  .model-connect-wallet {
    .otp-code-modal {
      @extend .otp-code-modal;
      height: 320px;
      width: 400px;
    }
  }

  // edit auction sale modal
  // toggle
  /* The switch - the box around the slider */
  .switch-edit-auction-sale {
    // font-size: 17px;
    position: relative;
    display: inline-block;
    height: 18px;
    width: 30px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked+.slider-edit-auction-sale {
      background-color: $purple-color-primary;
      border: 1px solid #39396c;
    }

    input:focus+.slider-edit-auction-sale {
      box-shadow: 0 0 1px $purple-color-primary;
    }

    input:checked+.slider-edit-auction-sale:before {
      transform: translateX(12px);
      background-color: #fff;
    }

    .slider-edit-auction-sale {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #02022e;
      border: 1px solid #39396c;
      transition: 0.4s;
      border-radius: 30px;
    }

    .slider-edit-auction-sale:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #39396c;
      transition: 0.4s;
    }

    @media (max-width: 639px) {
      height: 13px;
      width: 22px;

      input:checked+.slider-edit-auction-sale:before {
        transform: translateX(8px);
        background-color: #fff;
      }

      .slider-edit-auction-sale:before {
        height: 9px;
        width: 9px;
        left: 1px;
        bottom: 1px;
      }
    }
  }
}

.list-external-wallet {
  background: var(--background-box-wallet);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  margin-left: 25px;
  height: 150px;
  width: 350px;

  .box-item-wallet {
    width: 90%;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    padding: 10px 20px;
    align-items: center;
    cursor: pointer;
    height: -webkit-fill-available;
    max-height: 100%;

    .name-wallet {
      color: #1f1f1f;
      font-weight: 400;
      font-size: 14px;
    }

    .icon-wallet {
      width: 30px;
      height: 30px;

      img {
        width: 100%;
      }
    }

    &:hover {
      border-radius: 4px;
      border: 2px solid var(--blue-point, #2f8af5);
      background: var(--select-blue, #bae7ff);
    }
  }
}