:root {
  --font-color: #1f1f1f;
  --font-color-02: #595959;
  --color-hover: #000000;
  --font-kakao-agree: #595959;
  --background-input-kakao: #f0f5ff;
  --background-color: #ffffff;
  --link-color: cornflowerblue;
  --font-color-title: #2b2a5c;
  --border-box-card: 1px solid #f0f0f0;
  --background-bottom-box-card: #f0f5ff;
  --background-modal: #ffffff;
  --background-box-wallet: #f0f5ff;
  --background-line: #d9d9d9;
  --background-line-profile: #d9d9d9;
  --background-line-other: #d9d9d9;
  --border-avatar: #1f1f1f;
  --color-content-featured: #494949;
  --color-username-card: #595959;
  --line-bottom-boxcard: #d9d9d9;
  --border-box-collection: 1px solid #d9d9d9;
  --border-color-table: #d9d9d9;
  --color-or: #8c8c8c;
  --color-or-02: #8c8c8c;
  --bg-box-mainnet: #ffffff;
  --hover-box-mainner: #2f8af5;
  --background-box-baobab: #f0f5ff;
  --bg-line-footer: #d9d9d9;
  --bg-accordion-details: #fbfdff;
  --bg-accordion-properties: #fbfdff;
  --font-color-btn: #262626;
  --color-line: #d9d9d9;
  --bg-toggle: #1f1f1f;
  --bg-skeleton: #ddd;
  --bg-select-infor: #ffffff;
  --color-recomand-size: #8c8c8c;
  --bg-input-profile: #fff;
  --bg-nickname: #f0f5ff;
  --line-my-profile: #d9d9d9;
  --color-email-account: #595959;
  --color-title-chain: #494949;
  --bg-box-sns: #d6e4ff;
  --font-color-03: #1f1f1f;
  --font-color-04: #1f1f1f;
  --font-color-05: #1f1f1f;
  --font-color-06: #1f1f1f;
  --font-color-07: #1f1f1f;
  --font-color-08: #1f1f1f;
  --bg-search-input: rgba(255, 255, 255, 0.1);
  --color-search-ipnut: #000000;
  --bg-select: #fff;
  --color-select: rgba(0, 0, 0, 0.4);
  --color-border-filter: #fff;
  --font-color-describle: #4d4d4d;
  --bg-button-sale: #2f80ed;
  --color-border-1: #f0f0f0;
  --bg-accordion-body: #f0f5ff;
  --text-detail: rgba(0, 0, 0, 0.7);
  --bg-circle-button: 47, 128, 237;
  --bg-button-profile: #2f80ed;
  --background-accordion-header: #2f8af533;
  --boder-search-history: 1px solid #d9d9d9 !important;
  --bg-color-tooltip: #2f8af5;
  --bd-color-calendar: 1px solid #2f8af5 !important;
  --bd-color-calendar-text: #2f8af5 !important;
  --bg-color-modal-otp: #2f80ed !important;
  --bg-color-modal-otp-02: #2f80ed !important;
  --bg-color-clicked-item-filter: linear-gradient(0deg, #dee2e6, #dee2e6);
  --bg-button-disabled: rgba(0, 0, 0, 0.1);
  --bg-button-max: #2f80ed;
  --circle-box-icon: #2f80ed;
  --bg-input-modal: #f0f5ff;
  --btn-offer-table: #2f8af5;
  --bg-link-icon: #f0f5ff;
  --bg-button-coin: rgba(0, 0, 0, 0.05) !important;
  --color-circle-stt: rgba(0, 0, 0, 0.7);
  --color-tele-bot:  rgba(0, 0, 0, 1);
  --color-label-coin: rgba(0, 0, 0, 0.5);
  --bg-button-coin-clicked: rgba(0, 0, 0, 0.05) !important;
  --bg-navbar: linear-gradient(
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 255)
  );
  --color-line-reward: #8d8d8d;
  --bg-item-properties: rgba(47, 138, 245, 0.2);
  --bg-accordion-header: #2f8af533;
  --border-checkbox: 1px solid;
  --border-line-modal: #d9d9d9;
  --bg-top-board: #f0f5ff;
}

[data-theme='dark'] {
  --font-color: #ffffff;
  --font-color-02: #bfbfbf;
  --color-hover: #ffffff;
  --font-kakao-agree: #ffffff;
  --background-input-kakao: #ffffff1a;
  --background-color: #02021e;
  --link-color: lightblue;
  --font-color-title: #ffffff;
  --border-box-card: 1px solid #444444;
  --background-bottom-box-card: #1b1b34;
  --background-modal: #23235c;
  --background-box-wallet: #ffffff1a;
  --background-line: #ffffff4d;
  --background-line-profile: #2d2d4f;
  --background-line-other: #ffffff99;
  --border-avatar: #ffffff;
  --color-content-featured: #aaaaaa;
  --color-username-card: #ffffff;
  --line-bottom-boxcard: #ffffff4d;
  --border-box-collection: 1px solid #40406c;
  --border-color-table: #40406c;
  --color-or: #ffffff99;
  --color-or-02: #4f4f7d;
  --bg-box-mainnet: #ffffff4d;
  --hover-box-mainner: #ffffff;
  --background-box-baobab: #ffffff1a;
  --bg-line-footer: #444444;
  --bg-accordion-details: #fbfdff0d;
  --bg-accordion-properties: #ffffff1a;
  --font-color-btn: #ffffff;
  --color-line: #444444;
  --bg-toggle: #ffffff4d;
  --bg-skeleton: #2b2b2b;
  --bg-select-infor: #ffffff1a;
  --color-recomand-size: #8d8d8d;
  --bg-input-profile: #1b1b34;
  --bg-nickname: #1b1b34;
  --line-my-profile: #ffffff1a;
  --color-email-account: #d9d9d9;
  --color-title-chain: #777777;
  --bg-box-sns: #ffffff33;
  --bg-search-input: rgba(255, 255, 255, 0.1);
  --color-search-ipnut: #525298;
  --bg-select: #444;
  --color-select: rgba(255, 255, 255, 0.4);
  --color-border-filter: #444;
  --font-color-03: #ffffffb2;
  --font-color-04: #7777777;
  --font-color-05: #2f8af5;
  --font-color-06: #8d8d8d;
  --font-color-07: #525298;
  --font-color-08: #8787d6;
  --font-color-describle: #aaaaaa;
  --bg-button-sale: #32324b;
  --color-border-1: #444444;
  --bg-accordion-body: rgba(255, 255, 255, 0.05);
  --text-detail: rgba(255, 255, 255, 0.7);
  --bg-circle-button: 33, 37, 41;
  --bg-button-profile: #ffffff1a;
  --background-accordion-header: #0e0f29;
  --boder-search-history: 0 !important;
  --bg-color-tooltip: #222;
  --bd-color-calendar: 1px solid #2f8af5 !important;
  --bd-color-calendar-text: #2f8af5 !important;
  --bg-color-modal-otp: #ffffff4d !important;
  --bg-color-modal-otp-02: #2f8af5 !important;
  --bg-color-clicked-item-filter: #2f8af5;
  --bg-button-disabled: rgba(255, 255, 255, 0.1);
  --bg-button-max: #ffffff4d;
  --circle-box-icon: #58588c;
  --bg-input-modal: #ffffff1a;
  --btn-offer-table: #ffffff1a;
  --bg-link-icon: #58588c;
  --bg-button-coin: rgba(255, 255, 255, 0.05) !important;
  --color-circle-stt: rgba(255, 255, 255, 0.7);
  --color-tele-bot: #d9d9d9;
  --color-label-coin: rgba(255, 255, 255, 0.5);
  --bg-button-coin-clicked: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%),
    rgba(9, 4, 255, 0.1) !important;
  --bg-navbar: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  --color-line-reward: #2d2d4f;
  --bg-item-properties: rgba(255, 255, 255, 0.1);
  --bg-accordion-header: rgba(255, 255, 255, 0.1);
  --border-checkbox: 1px solid #525298;
  --border-line-modal: #4f4f7d;
  --bg-top-board: #0e0f29;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

body:has(.reward-page) {
  background-color: #fff;
  color: #000;
}

body:has(.main-app) {
  background-color: #02021E;
  color: #000;
}

a {
  color: var(--link-color);
}

/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* gap: 4px; */
}

.toggle-theme-wrapper span {
  font-size: 28px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 21px;
  width: 40px;
}

.toggle-theme input {
  display: none;
}

.slider-mode {
  background-color: #1f1f1f;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider-mode:before {
  background-color: #fff;
  bottom: 3px;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  align-items: center;
  transform: translate(-2px, 2px);
}

input:checked + .slider-mode:before {
  transform: translate(17px, 2px);
  background: #02021e;
}

input:checked + .slider-mode {
  background-color: #ffffff4d;
}

.slider-mode.round {
  border-radius: 34px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slider-mode.round:before {
  border-radius: 50%;
  background: #ffffff;
}

.icon-thememode {
  width: 13px;
  height: 13px;
  z-index: 10;
}
