.list-boards {
  display: flex;
  justify-content: space-between;
  gap: 51px;
  padding-top: 80px;
  width: min(100%, 1280px);
  margin: auto;
  position: relative;
  .wrapper-top-board {
    flex: 1;
  }
  .tabs-top-board {
    display: flex;
    width: 200px;
    height: 30px;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--bg-top-board);
    .item-tab-board {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      .tab {
        display: flex;
        width: 62px;
        height: 26px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        font-family: Pretendard;
        font-size: 12px;
        line-height: 20px;
      }
      .tab-selected {
        background: #2f80ed;
        font-weight: 700;
      }
      &:hover {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
  .top-board {
    border-collapse: separate;
    border-spacing: 0 2px;
    margin-top: -2px;
    margin-bottom: -2px;
    font-weight: 700;

    thead tr th {
      line-height: 16.41px;
      padding-right: 16px;
      padding-bottom: 8px;
      white-space: nowrap;
      border-bottom: 1px solid #40406c;
    }

    thead tr th:first-child {
      text-align: center;
    }

    thead tr th:last-child {
      padding-right: 0;
    }

    thead tr th:nth-child(3) {
      text-align: end;
    }

    thead tr th:nth-child(4) {
      text-align: end;
    }

    tbody:before {
      content: '@';
      display: block;
      line-height: 4px;
      text-indent: -99999px;
    }

    tbody tr {
      height: 58px;
    }

    tbody tr td {
      padding: 8px 16px 8px 0px;
      line-height: 19.09px;
    }

    tbody tr td:last-child {
      padding: 8px 0px 8px 0px;
    }

    td {
      border: solid 0px #40406c;
      border-style: solid none;
    }

    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    td:last-child {
      border-right-style: solid;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    tbody tr td:nth-child(3) {
      text-align: end;
    }

    tbody tr td:nth-child(4) {
      text-align: end;
    }

    @media (max-width: 768px) {
      border-spacing: 0 4px;
      font-size: 12px;
      line-height: 14.32px;

      .top-board thead tr th {
        font-size: 10px;
        line-height: 11.72px;
      }

      thead tr th {
        line-height: 16.41px;
        padding-right: 8px;
      }

      thead tr th:last-child {
        padding-right: 0;
      }

      tbody:before {
        line-height: 0px;
      }

      tbody tr {
        height: 100%;
      }

      tbody tr td {
        padding: 4px 8px 4px 0px;
        line-height: 14.32px;
      }

      tbody tr td:last-child {
        padding: 4px 0 4px 0px;
      }
    }
  }
  .wrapper-more {
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 2, 30, 0) 0%, #02021e 65%);
    width: 100%;
    height: 160px;
    bottom: -70px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    font-weight: 700;
    line-height: 24px;
    color: #8787d6;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .wrapper-hide {
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 2, 30, 0) 0%, #02021e 65%);
    width: 100%;
    height: 50px;
    bottom: -50px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    font-weight: 700;
    line-height: 24px;
    color: #8787d6;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    .icon-hide-board {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 54px;
    padding: 40px 20px 0 0;
    .tabs-top-board {
      width: 150px;
      .item-tab-board {
        .tab {
          width: 42px;
          font-size: 10px;
        }
      }
    }
    .wrapper-top-board {
      position: relative;
      .wrapper-more-mb {
        position: absolute;
        bottom: -30px;
        background: linear-gradient(180deg, rgba(2, 2, 30, 0) 0%, #02021e 65%);
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
        gap: 8px;
        font-size: 12px;
        line-height: 18px;
        color: #8787d6;
      }
      .wrapper-hide-mb {
        position: absolute;
        bottom: -30px;
        background: linear-gradient(180deg, rgba(2, 2, 30, 0) 0%, #02021e 65%);
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        line-height: 18px;
        color: #8787d6;
        .icon-hide-board {
          transform: rotate(180deg);
        }
      }
    }
    .leader-board {
      position: relative;
    }
  }
}
