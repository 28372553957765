//kakao page
.kakao-page {
  width: 100%;
  margin: 0 auto;
  height: 120vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% center;

  .logo-talken {
    padding: 50px 0 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      width: 146px;
      height: 37px;
    }
  }

  .form-kakao {
    width: min(388px, 100%);
    background: var(--background-modal);
    box-shadow: 0px 4px 49px rgba(20, 161, 255, 0.1);
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    padding: 40px 24px 32px;

    .logo-kakao {
      width: 60px;
      height: 60px;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 100%;
      }
    }

    .title-kakao {
      line-height: 17px;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: var(--font-color);
      text-transform: capitalize;
    }

    .wrapper-nickname {
      padding: 16px 0;
      width: 100%;

      .title-nickname {
        color: var(--font-color-08);
        display: flex;
        gap: 2px;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 8px;
        line-height: 16px;

        span {
          color: #ff4d4f;
        }

        .star-red {
          width: 8px;

          img {
            width: 100%;
          }
        }
      }

      .input-nickname {
        color: var(--font-color);
        height: 40px;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        border: unset;
        background: var(--background-input-kakao);
        outline: none;
      }

      .nickname-check {
        background-color: #2f8af5;
        height: 40px;
        width: 25%;
        border-radius: 8px;
        border: unset;
        font-size: 14px;
        color: #ffffff;

        &:disabled {
          opacity: 30%;
        }
      }
    }

    .wrapper-email {
      padding: 0 0 8px 0;
      width: 100%;

      .title-email {
        color: var(--font-color-08);
        display: flex;
        gap: 2px;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 8px;
        line-height: 16px;

        span {
          color: #ff4d4f;
        }

        .star-red {
          width: 8px;

          img {
            width: 100%;
          }
        }
      }

      .input-email {
        color: var(--font-color);
        height: 40px;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        border: unset;
        background: var(--background-input-kakao);
        outline: none;
      }
    }

    .wrapper-invitation-code {
      padding: 8px 0;
      width: 100%;

      .title-invitation-code {
        color: var(--font-color-08);
        display: flex;
        gap: 2px;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 8px;
        line-height: 16px;

        span {
          color: #ff4d4f;
        }

        .star-red {
          width: 8px;

          img {
            width: 100%;
          }
        }
      }

      .input-invitation-code {
        color: var(--font-color);
        height: 40px;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        border: unset;
        background: var(--background-input-kakao);
        outline: none;
      }

      .invitation-code-check {
        background-color: #2f8af5;
        height: 40px;
        width: 25%;
        border-radius: 8px;
        border: unset;
        font-size: 14px;
        color: #ffffff;

        &:disabled {
          opacity: 30%;
        }
      }
    }

    .agree-background {
      padding: 8px;
      margin: 8px 0px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);

      .agree-all {
        line-height: 20px;
        display: flex;
        gap: 10px;
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 8px;

        .icon-check-all {
          width: 20px;
          height: 20px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }

      .wrapper-agree {
        display: flex;
        flex-direction: column;
        gap: 4px;
        // padding-left: 2px;
        font-weight: 400;
        font-size: 12px;
        color: var(--font-color-02);

        .agree-item {
          display: flex;
          align-items: start;
          gap: 10px;

          .icon-check-agree {
            width: 20px;
            cursor: pointer;
            display: flex;

            img {
              min-width: 100%;
            }
          }

          .wrapper-agree-left {
            line-height: 20px;
            display: flex;
            // padding-top: 1.5%;
            flex-wrap: wrap;

            span {
              color: #ff4d4f;
            }

            .text-terms {
              text-decoration: revert;
              color: var(--font-kakao-agree);
              padding-left: 4px;
            }
          }
        }
      }
    }

    .signup-kakao {
      font-weight: 700;
      height: 48px;
      background-color: #2f8af5;
      width: 100%;
      border-radius: 8px;
      margin-top: 8px;
      color: #ffffff;

      &:disabled {
        opacity: 0.3;
      }
    }

    .duplicate-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      //text-align: right;
      //padding: 0px 15px;
      color: red;
      margin-top: -10px;
      margin-bottom: 7px;

      .duplicate-info-icon {
        font-size: 16px;
        margin-right: 2px;
      }

      .duplicate-info {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;


    .logo-talken {
      padding: 24px 0 49px;

      img {
        width: 95px;
        height: 24px;
      }
    }

    .form-kakao {
      width: min(335px, 100%);
      padding: 32px 24px 16px;

      .logo-kakao {
        width: 50px;
        height: 50px;
        top: -25px;
      }


      .wrapper-nickname {
        .input-nickname {
          font-size: 12px;
        }
      }

      .wrapper-invitation-code {
        .input-invitation-code {
          font-size: 12px;
        }
      }

      .agree-background {
        .agree-all {
          line-height: 18px;

          .icon-check-all {
            width: 15px;
            height: 15px;
          }
        }

        .wrapper-agree {
          font-size: 12px;

          .agree-item {
            .icon-check-agree {
              width: 15px;
              height: 15px;
            }

            .wrapper-agree-left {
              line-height: 18px;
            }
          }
        }
      }

    }

  }
}