//footer
.footer {

  // padding: 50px 200px;
  // margin: 0px auto;
  // min-height: 215px;
  // background-color: #02021e;
  .line-first {
    margin-bottom: 36px;
    background: var(--bg-line-footer);
  }

  .wrapper-footer {
    display: flex;
    justify-content: space-between;
    margin: auto;
    color: #ffffff;
    max-width: $page-content-width;
    gap: 64px;
    margin: 0px auto;
    padding: 0 40px;

    .navbar-footer {
      grid-area: navbar-footer;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 120px;
      // margin-right: 64px;

      .list-nav {
        display: flex;
        flex-direction: column;

        a {
          text-decoration: none;
          color: var(--font-color-title);
        }

        .nav-item-name {
          margin-top: 0;
          font-size: 16px;
        }

        .nav-item {
          text-decoration: none;
          color: var(--color-content-featured);
          margin-bottom: 12px;
          font-size: 14px;
          text-align: left;
          transition: color 0.15s ease 0s;

          &:hover {
            color: var(--color-hover);
          }
        }
      }
    }

    .logo-footer {
      grid-area: logo-footer;
      // width: 180px;

      img {
        // width: 100%;
      }
    }

    .list-page {
      grid-area: list-page;

      .content-head {
        font-size: 14px;
        color: var(--color-content-featured);
        padding: 10px 0;

        .content {
          padding-bottom: 2px;
        }

        .content-01 {
          font-weight: 600;
        }
      }

      // .social-networks {
      //   display: flex;
      //   // justify-content: space-between;
      //   gap: 20px;

      //   .social-item {
      //     width: 30px;
      //     opacity: 0.6;
      //     transition: color 0.15s ease 0s;
      //     &:hover {
      //       opacity: 1;
      //     }

      //     img {
      //       width: 100%;
      //     }
      //   }
      // }
      .footLink {
        // padding: 10px 0 0 0;
        font-size: 0;

        a {
          display: inline-block;
          width: 46px;
          height: 46px;
          background: url('../../../assets/icon/footerlink.png') no-repeat 0 10px;
          background-size: auto 23px;
          text-decoration: none;
          transition: color 0.15s ease 0s, background-color 0.15s ease 0s;
        }

        .footLink3 {
          background-position-x: -86px;
        }

        .footLink2 {
          background-position-x: -40px;
        }

        .footLink5 {
          background-position-x: -127px;
        }

        .footLink6 {
          background-position-x: -174px;
        }

        .footLink1 {
          width: 45px;
          background-position-x: 5px;
        }

        .footLink7 {
          background-position-x: -224px;
        }
      }
    }
  }

  .line-second {
    height: 1px;
    max-width: 1200px;
    margin: 36px auto 22px;
    background: var(--bg-line-footer);
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-content-featured);
    max-width: $page-content-width;
    margin: auto;
    font-size: 14px;
    // padding-bottom: 28px;
    padding: 0 40px 168px;

    .footer-bottom-left {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .contact-us {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      transition: color 0.15s ease 0s;

      .icon-contact {
        width: 22px;
        height: 22px;
        opacity: 0.6;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }

        // &:hover {
        //   opacity: 1;
        // }
      }

      &:hover {
        // color: #ffffff;
        color: var(--color-hover);

        .icon-contact {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .wrapper-footer {
      .navbar-footer {
        grid-gap: 64px;
        display: grid;
        // grid-template-columns: 1fr 1fr;
        margin-right: 32px;
      }
    }
  }

  @media (max-width: 1000px) {

    // padding: 0 40px;
    .wrapper-footer {
      flex-direction: column-reverse;
      gap: 60px;

      .navbar-footer {
        grid-gap: 32px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-right: 0;
      }

      .footer-right {
        .list-page {
          .social-networks {
            gap: 20px;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  @media (max-width: 540px) {
    padding: 0 20px;

    .wrapper-footer {
      padding: 0;

      .navbar-footer {
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px 16px;
      }

      .footer-right {
        .content-head {
          font-size: 14px;
        }
      }
    }

    .footer-bottom {
      flex-direction: column-reverse;
      padding: 0;
      padding-bottom: 156px;
      align-items: flex-start;

      .footer-bottom-left {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 16px;
      }

      .contact-us {
        margin-bottom: 16px;
        justify-content: flex-start;
      }
    }
  }

  @media (max-width: 330px) {
    .wrapper-footer {
      .footer-right {
        .list-page {
          .social-networks {
            gap: 18px;
          }
        }
      }
    }
  }
}