$page-content-width: min(100%, 1280px);
$page-content-width-tablet: min(100vw, 728px);
$page-content-width-mobile: min(100vw, 385px);
$blue-color-primary: #2f8af5;
$white-color-primary: #fff;
$pink-color-primary: #e364be;
$purple-color-primary: #944fff;
$dark-color-primary: #444444;
$dark-color-39: #393939;
$dark-color-70: #707070;
$font-dark-color-15: #ffffff26;
$dark-color-green: #00b861;
$blue-color-primary: #2f8af5;
$blue-hover-primary: #0461cf;
$black-color-primary: #000000;
$color-8787d6: #8787d6;
$header-mobile-height: 2.125rem;
$header-height: 3.125rem;
$directions: (
  'l': 'left',
  't': 'top',
  'r': 'right',
  'b': 'bottom',
);
