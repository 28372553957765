//dialog wallets
.dialog-wallets {
  position: fixed;
  inset: 0px;
  z-index: 21;
  width: 360px;
  height: 360px;
  margin: 0px 0px 5% auto;
  border-radius: 0px 0px 0px 10px;
  background-color: rgb(45, 46, 51);
  box-shadow: black -5px 5px 20px;
  outline: none;
  padding: 0.1px;
  color: #ffffff;
  .wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: rgb(33, 34, 38);
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      img {
        margin-right: 10px;
        width: 26px;
        height: 26px;
      }
    }
    .close-button {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .choose-connect {
    font-size: 15px;
    font-weight: 500;
    padding: 0px 22px;
    margin-bottom: 10px;
  }
  .wrapper-accounts {
    padding: 10px 20px;
    .account-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-radius: 10px;
      width: 100%;
      color: white;
      padding: 10px 20px;
      margin-bottom: 15px;
      background-color: rgb(33, 34, 38);
      cursor: pointer;
      font-weight: 500;
      .wrapper-left {
        display: flex;
        align-items: center;
        .logo-item {
          margin-right: 20px;
          width: 32px;
          height: 32px;
          img {
            width: 100%;
          }
        }
        // .logo-talken {
        //   width: 48px;
        //   img {
        //     width: 100%;
        //   }
        // }
      }
      .wrapper-right {
        display: flex;
        align-items: center;
        width: 100px;
        font-size: 14px;
        color: rgb(148, 149, 151);
        .text {
          margin-left: 5px;
          font-weight: 600;
        }
      }
    }
  }
  @include Tablet() {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  @include Mobile() {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
//dialog wallets mobile
.dialog-wallets-mb {
  position: fixed;
  inset: 0px;
  z-index: 21;
  width: 100%;
  height: 100%;
  margin: 0px 0px 5% auto;
  // border-radius: 0px 0px 0px 10px;
  background-color: rgb(45, 46, 51);
  box-shadow: black -5px 5px 20px;
  outline: none;
  padding: 0.1px;
  color: #ffffff;
  .wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: rgb(33, 34, 38);
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      img {
        margin-right: 10px;
        width: 26px;
        height: 26px;
      }
    }
    .close-button {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .choose-connect {
    font-size: 15px;
    font-weight: 500;
    padding: 0px 22px;
    margin-bottom: 10px;
  }
  .wrapper-accounts {
    padding: 10px 20px;
    .account-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-radius: 10px;
      width: 100%;
      color: white;
      padding: 10px 20px;
      margin-bottom: 15px;
      background-color: rgb(33, 34, 38);
      cursor: pointer;
      font-weight: 500;
      .wrapper-left {
        display: flex;
        align-items: center;
        .logo-item {
          margin-right: 20px;
          width: 32px;
          height: 32px;
          img {
            width: 100%;
          }
        }
        // .logo-talken {
        //   width: 48px;
        //   img {
        //     width: 100%;
        //   }
        // }
      }
      .wrapper-right {
        display: flex;
        align-items: center;
        width: 100px;
        font-size: 14px;
        color: rgb(148, 149, 151);
        .text {
          margin-left: 5px;
          font-weight: 600;
        }
      }
    }
  }
}
// .ReactModal__Body--open {
//   overflow: hidden !important;
// }
#root {
  isolation: isolate;
}
.dialog-wallets-overlay {
  position: fixed;
  inset: 0;
  background: transparent;
}
