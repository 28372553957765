//navbar PC view
.nav-bar {
  position: fixed;
  width: 100%;
  z-index: 51;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  // border-bottom: 1px solid #d9d9d9;
  .nav-home {
    position: absolute;
    width: 100%;
    height: 66px;
    top: 0px;
    // background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    opacity: 0.8;
  }
  .bg-scroll {
    background-image: var(--bg-navbar);
  }

  .nav-other-page {
    position: absolute;
    width: 100%;
    // height: 100px;
    height: 66px;
    top: 0px;
    opacity: 0.8;
    background-image: var(--bg-navbar);
  }

  .main-header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $page-content-width;
    width: 100%;
    height: 66px;
    position: relative;
    z-index: 10;
    margin: auto;
    gap: 15px;

    .logo-header {
      margin-left: 32px;
      //padding-right: 15px;
      display: flex;
      align-items: center;
      .custom-logo-header {
        width: 100px;
        height: 22px;
        margin-top: 3px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .header-drops-creator {
        display: flex;
        gap: 24px;
        line-height: 0px;
        // margin: 0 36px;
        // border-left: 1px solid #7380a2;
        padding: 12px 36px 0 36px;
        a {
          font-size: 16px;
          font-weight: 600;
          color: var(--font-color-03);
          &:hover {
            color: #2f80ed;
          }
        }

        .active-header {
          color: #2f80ed;
        }
      }
    }
    .header-div-line {
      margin: 0 15px;
    }
    .header-sub-div {
      display: flex;
      gap: 20px;
      line-height: 0px;
      margin: 0 15px;
      border-left: 1px solid var(--font-color);
      padding: 15px;
      a {
        font-size: 14px;
        font-weight: 600;
        color: var(--font-color);
      }
    }

    // .search-bar {
    //   display: flex;
    //   -webkit-box-align: center;
    //   align-items: center;
    //   height: calc(40px - 5px);
    //   background-color: #ffffff1a;
    //   border-radius: 5px;
    //   position: relative;
    //   flex: 1 1 0%;

    //   .search-icon {
    //     margin: 0px 5px 0px 20px;
    //     line-height: 50px;
    //     width: 18px;
    //     height: 18px;
    //   }

    //   .input-search {
    //     width: calc(100% - 60px);
    //     display: block;
    //     margin: 0px -15px 0px 0px;
    //     padding: 0px;
    //     height: 100%;
    //     color: white;
    //     font-size: 90%;
    //     background-color: transparent;
    //     border: 0px;
    //     overflow: visible;
    //     outline: none;
    //   }
    // }
    .nav-right {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 12px;
      .contact-header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        width: 200px;
        a {
          text-decoration: none;
          cursor: pointer;
          font: inherit;
        }
        .color-darkmode {
          color: #ffffff;
        }
        .color-lightmode {
          color: #1f1f1f;
        }
      }

      .fanpage-icons {
        // border-left: 1px solid var(--font-color);
        height: 24px;
        display: flex;
        justify-content: space-evenly;
        width: 150px;
        flex-shrink: 0;
        padding-top: 2px;
        a {
          display: inline-block;
          height: 100%;
          cursor: pointer;
          width: 32px;

          img {
            width: 80%;
          }
        }
      }
      // before login PC view
      .btn-login {
        .custom-btn {
          width: 142px;
          height: 35px;
          background: #2f80ed;
          border-radius: 5px;
          margin-right: 40px;
          display: none;
          .custom-text {
            font-weight: 700;
            color: white;
          }
        }
      }
      // before login Tablet, Mobile view
      .icon-nav {
        margin-right: 40px;
        display: none;
      }
      //after login
      .btn-wallets {
        height: 44px;
        display: flex;
        gap: 30px;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        padding-top: 12px;
        .btn-log-in:hover {
          color: #2f80ed;
        }
        .custom-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 110px;
          height: 44px;
          top: 30px;
          background: #2f80ed;
          border-radius: 5px;
          color: white;
          .wrapper-btn {
            display: flex;
            align-items: center;
            gap: 5px;

            img {
              color: #ffffff;
            }
            .wallets {
              white-space: nowrap;
            }
          }
          .btn-mobile {
            margin-top: 8px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .wallet-connected {
        height: 45px;
        display: flex;
        justify-content: space-around;
        //background-color: #113577;
        padding: 0 2.5px;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        //border:1px solid red;
        // opacity: 0.8;
        .item-wallet {
          margin: 0px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 20px;
          // height: 20px;
          border-radius: 50%;
          background-color: #ffffff;
          opacity: 0.8;
          padding: 2px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .account {
          margin: 0 5px;
          font-size: 14px;
        }
      }
      .wrapper-user {
        display: flex;
        align-items: center;
        background-color: transparent;
        position: relative;
        align-self: center;
        gap: 10px;
        opacity: 1;
        // display: none;
        cursor: pointer;

        .avatar-user {
          width: 26px;
          height: 26px;

          img {
            width: 26px;
            height: 26px;
            object-fit: cover;
            border-radius: 50%;
            background-color: #ffffff;
          }
        }

        .user-name {
          font-weight: 700;
          font-size: 14px;
          line-height: 35px;
          order: 1;
          white-space: nowrap;
        }
      }

      .username-dropdown {
        background-color: transparent;
        margin-right: 25px;
        margin-top: 6px;
        padding: 0;
        // display: none;
      }
    }

    //modal wallets box
    .modal-wallets {
      position: fixed;
      inset: 0px;
      z-index: 21;
      background-color: #113577;
      outline: none;
      padding: 0.1px;
      color: #ffffff;
      width: 360px;
      margin: 0px 0px 5% auto;
      border-radius: 0px 0px 0px 10px;
      box-shadow: #113577 -5px 5px 20px;
    }
  }
  // @media (max-width: 1024px) {
  //   .main-header-box {
  //     gap: 10px;
  //     .logo-header {
  //       width: 120px;
  //     }
  //   }
  // }
  @include Tablet() {
    z-index: 400;
    padding-bottom: 0;
    .main-header-box {
      // .search-bar {
      //   .search-icon {
      //     margin: 0px 5px 0px 20px;
      //     line-height: 50px;
      //     width: 18px;
      //     height: 18px;
      //   }
      // }
      .nav-right {
        .contact-header {
          display: none;
        }
        .fanpage-icons {
          display: none;
        }
        .btn-login {
          display: none;
        }
        // .btn-wallets {
        //   margin-right: 0;
        // }
        // .wallet-connected {
        //   display: none;
        // }
        .icon-nav {
          display: block;
          margin-right: 40px;
          .button {
            padding: 0;
          }
        }
      }

      // .wrapper-user {
      //   display: none;
      // }
      // .username-dropdown {
      //   display: none;
      // }
    }
  }
  @media (max-width: 640px) {
    z-index: 400;
    .nav-other-page {
      // height: 80px;
    }
    .hidden-mobile {
      display: none !important;
    }
    .main-header-box {
      .logo-header {
        // width: 100px;
        align-items: flex-end;
        margin-left: 20px;
        .custom-logo-header {
          width: 60px;
          height: 15px;
          margin-top: 3px;
        }
        .header-drops-creator {
          gap: 8px;
          // margin: 0 0 0 8px;
          padding: 8px 8px 2px 20px;
          // border-left: 1px solid #484848;

          a {
            font-size: 13px;
          }

          .active-header {
            color: #2f80ed;
          }
        }
      }
      .search-bar {
        height: $header-mobile-height;
        .search-icon {
          margin: 0px 5px 0px 20px;
          line-height: 50px;
          width: 18px;
          height: 18px;
        }
      }
      .nav-right {
        margin-top: 10px;
        .contact-header {
          display: none;
        }
        .fanpage-icons {
          display: none;
        }
        .btn-login {
          display: none;
        }
        .btn-wallets {
          height: 24px;
          font-size: 10px;
          font-weight: 500;
          gap: 14px;
          padding-top: 0;
          .custom-btn {
            height: 24px;
            width: 95px;
            white-space: nowrap;
            border-radius: 4px;
            &-login {
              width: 28px;
              background-color: transparent;
            }
          }
        }
        .wrapper-user {
          display: none;
        }
        .username-dropdown {
          display: none;
        }
        .icon-nav {
          display: block;
          margin-right: 20px;
          // margin-top: 2px;
          width: 24px;
          height: 24px;
          .button {
            padding: 0;
            width: 100%;
            height: 100%;
          }
        }
        .wrapper-btn {
          margin-top: 7px;
        }
      }
    }
  }
  @media (max-width: 390px) {
    .main-header-box {
      .logo-header {
        margin-left: 20px;
        .custom-logo-header {
          // width: 85px;
          // height: 23px;
          // margin-top: 3px;
        }
        .header-drops-creator {
          gap: 8px;
          padding: 8px 8px 2px 20px;
          a {
            font-size: 13px;
          }
        }
      }
      .nav-right {
        margin-top: 10px;
        .btn-wallets {
          height: 24px;
          font-size: 10px;
          font-weight: 500;
          gap: 14px;
          padding-top: 0;
          .custom-btn {
            height: 24px;
            width: 95px;
            white-space: nowrap;
            border-radius: 4px;
            &-login {
              width: 30px;
            }
          }
        }
        .icon-nav {
          display: block;
          margin-right: 20px;
          // margin-top: 2px;
          width: 18px;
          height: 16px;
          .button {
            padding: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  @include Mobile() {
    z-index: 400;
    .nav-other-page {
      // height: 80px;
    }
    .main-header-box {
      .logo-header {
        // width: 100px;
        margin-left: 20px;
        img {
          width: 100%;
        }
      }
      .search-bar {
        height: $header-mobile-height;
        .search-icon {
          margin: 0px 5px 0px 20px;
          line-height: 50px;
          width: 18px;
          height: 18px;
        }
      }
      .contact-header {
        display: none;
      }
      .fanpage-icons {
        display: none;
      }
      .btn-login {
        display: none;
      }
      // .btn-wallets {
      //   display: none;
      // }
      .wrapper-user {
        display: none;
      }
      .username-dropdown {
        display: none;
      }
      .icon-nav {
        display: block;
        margin-right: 20px;
        .button {
          padding: 0;
        }
      }
    }
  }
}

.scroll-top {
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
}

//navbar tablet and mobile
// .navbar-mb {
//   //navbar tablet
//   z-index: 400;
//   padding-top: 20px;
//   color: #ffffff;
//   position: relative;
//   padding-bottom: 0;

//   .nav-home {
//     position: absolute;
//     width: 100%;
//     height: 150px;
//     top: 0px;
//     background-image: linear-gradient(
//       rgb(0, 0, 0),
//       rgb(0, 0, 0),
//       rgba(0, 0, 0, 0.75),
//       rgba(0, 0, 0, 0)
//     );
//     opacity: 0.4;
//   }

//   .nav-other-page {
//     position: absolute;
//     width: 100%;
//     height: 100px;
//     top: 0px;
//     background: #003577;
//     opacity: 1;
//   }

//   .main-header-box {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     width: 100%;
//     position: relative;
//     z-index: 10;
//     margin: auto;

//     .logo-header {
//       width: 110px;
//       margin-left: 20px;

//       img {
//         width: 100%;
//       }
//     }

//     .search-bar {
//       display: flex;
//       -webkit-box-align: center;
//       align-items: center;
//       margin: 0px 30px 0px 15px;
//       height: calc(50px - 5px);
//       background-color: #ffffff1a;
//       border-radius: 50vh;
//       position: relative;
//       flex: 1 1 0%;
//       margin: 0px 9px;
//       height: $header-mobile-height;

//       .search-icon {
//         margin: 0px 5px 0px 20px;
//         width: 14px;
//         height: 14px;
//       }

//       .input-search {
//         width: calc(100% - 60px);
//         display: block;
//         margin: 0px -15px 0px 0px;
//         padding: 0px;
//         height: 100%;
//         color: white;
//         font-size: 100%;
//         background-color: transparent;
//         border: 0px;
//         overflow: visible;
//         outline: none;
//       }
//     }

//     .icon-nav {
//       margin-right: 20px;
//       display: none;
//     }

//     //after login
//     .wrapper-user {
//       display: flex;
//       // display: none;
//       align-items: center;
//       background-color: transparent;
//       position: relative;
//       align-self: center;

//       .avatar-user {
//         width: 26px;
//         height: 26px;

//         img {
//           width: 100%;
//         }
//       }

//       .user-name {
//         // display: none;
//         padding-left: 10px;
//         font-weight: 500;
//         line-height: 36px;
//         order: 1;
//       }
//     }

//     .username-dropdown {
//       background-color: transparent;
//       margin-left: 15px;
//       margin-right: 20px;
//       // display: none;
//     }
//   }

//   //navbar mobile
//   @include Mobile() {
//     padding-top: 30px;

//     .main-header-box {
//       .logo-header {
//         width: 70px;
//         margin-left: 15px;

//         img {
//           width: 100%;
//         }
//       }

//       .icon-nav {
//         display: block;
//         margin-right: 15px;
//       }

//       .wrapper-user {
//         display: none;
//       }

//       .username-dropdown {
//         display: none;
//       }
//     }
//   }
// }
