//Connect Page
:root {
  .signup-page {
    background-image: url('../../../assets/img/bg_connect_page_light.png');
    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_lightmb.png');
    }
  }
  .kakao-page {
    background-image: url('../../../assets/img/bg_connect_page_light.png');
    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_lightmb.png');
    }
  }
}
[data-theme='dark'] {
  .signup-page {
    background-image: url('../../../assets/img/bg_connect_page.png');
    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_mb.png');
    }
  }
  .kakao-page {
    background-image: url('../../../assets/img/bg_connect_page.png');
    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_mb.png');
    }
  }
}
.walletconnect-modal__header {
  color: #1f1f1f;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.walletconnect-modal__mobile__toggle {
  color: #1f1f1f;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.walletconnect-modal__footer {
  color: rgba(60, 66, 82, 0.6);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.signup-page {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% center;
  .logo-talken {
    padding: 50px 0 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      width: 146px;
      height: 37px;
    }
  }
  .form-connect {
    width: min(600px, 100%);
    background: var(--background-modal);
    box-shadow: 0px 4px 49px rgba(20, 161, 255, 0.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-weight: 700;
    font-size: 24px;
    flex-direction: column;
    // height: min(325px, 100%);
    .line {
      display: initial;
      background-color: #444;
    }
    .title-head {
      color: var(--font-color);
      padding: 26px 0;
    }
    .line-connect {
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
    .wrapper-term-conditions {
      background: var(--bg-box-sns);
      border-radius: 10px;
      padding: 20px 28px 20px;
      margin: 34px 30px 40px;
    }
    .wrapper-content-connect {
      padding: 0px 30px 38px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      width: 100%;
      height: -webkit-fill-available;
      .title-content {
        color: var(--text-detail);
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 16.286px;
      }
      .list-sns-connect {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 94px;
        padding: 0 122px;
        background: var(--bg-box-sns);
        border-radius: 10px;
        align-items: center;
        .border-icon-sns {
          width: 56px;
          height: 56px;
          background: #ffffff;
          border-radius: 30px;
          display: grid;
          place-items: center;
          .icon-sns {
            width: 30px;
            height: 30px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
        }
      }
      .line-or {
        width: 100%;
        display: flex;
        padding: 20px 0 6px;
        &::before {
          content: '';
          border-bottom: 1px solid var(--color-or);
          flex: 1 0 auto;
          height: 10px;
          margin: 0;
        }
        .title-line-or {
          text-align: center;
          flex: 0.3 0 auto;
          margin: 0;
          font-weight: 400;
          font-size: 20px;
          line-height: 16.286px;
          color: var(--color-or);
        }
        &::after {
          content: '';
          border-bottom: 1px solid var(--color-or);
          flex: 1 0 auto;
          height: 10px;
          margin: 0;
        }
      }
      .list-mainnet {
        height: 86px;
        width: 100%;
        padding: 0 20px;
        background: var(--background-box-wallet);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .box-mainnet {
          width: 54px;
          height: 54px;
          background: var(--bg-box-mainnet);
          border-radius: 4px;
          cursor: pointer;
          display: grid;
          place-items: center;
          .icon-mainnet {
            width: 36px;
            height: 36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &:hover {
            // background: var(--hover-box-mainner);
          }
        }
        .activate {
          background: #2f8af5;
        }
      }
      .list-wallet {
        background: var(--background-box-wallet);
        border-radius: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: initial;
        gap: 8px;
        padding: 16px 0;
        height: min(93px, 100%);
        .box-item-wallet {
          width: 50%;
          background: #ffffff;
          border-radius: 4px;
          display: flex;
          justify-content: flex-start;
          gap: 8px;
          padding: 10px 20px;
          align-items: center;
          cursor: pointer;
          height: -webkit-fill-available;
          max-height: 100%;
          .name-wallet {
            color: #1f1f1f;
            font-weight: 400;
            font-size: 14px;
          }
          .icon-wallet {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
            }
          }
          &:nth-child(1) {
            margin-left: 16px;
          }
          &:nth-child(2) {
            margin-right: 16px;
          }
          &:hover {
            border-radius: 4px;
            border: 2px solid var(--blue-point, #2f8af5);
            background: var(--select-blue, #bae7ff);
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    padding: 0 20px;
    .logo-talken {
      padding: 40px 0;
      img {
        width: 93px;
        height: 28px;
      }
    }
    .form-connect {
      .wrapper-content-connect {
        padding: 0px 20px 32px;
        .list-sns-connect {
          padding: 0 46px;
          margin-top: 6px;
        }
        .line-or {
          padding: 16px 0 0 0;
        }
      }
    }
  }
  @media (max-width: 360px) {
    .form-connect {
      .wrapper-content-connect {
        .list-sns-connect {
          padding: 0 30px;
          .border-icon-sns {
            width: 44px;
            height: 44px;
            .icon-sns {
              width: 28px;
              height: 28px;
            }
          }
        }
        .list-mainnet {
          .box-mainnet {
            width: 44px;
            height: 44px;
            .icon-mainnet {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    .form-connect {
      max-width: 338px;
      // max-height: 210px;
      flex-shrink: 0;
      border-radius: 12px;
      font-size: 16px;
      .title-head {
        font-size: 16px;
        padding: 20px 0 16px;
      }
      .wrapper-term-conditions {
        padding: 20px 16px 20px;
        margin: 34px 20px 30px;
      }
      .wrapper-content-connect {
        gap: 10px;
        .title-content {
          font-size: 12px;
          line-height: 9.548px;
          font-weight: 600;
        }
        .line-or {
          font-size: 12px;
          line-height: 16.286px;
          .title-line-or {
            font-size: 12px;
            font-weight: 600;
          }
        }
        .list-sns-connect {
          max-width: 298px;
          max-height: 59px;
          .border-icon-sns {
            width: 41px;
            height: 41px;
            .icon-sns {
              width: 22.58px;
              height: 22.58px;
            }
          }
        }
        .list-wallet {
          max-width: 299px;
          max-height: 63px;
          .box-item-wallet {
            padding: 15px 15px;
            min-width: auto;
            .name-wallet {
              color: #1f1f1f;
              font-weight: 400;
              font-size: 12px;
            }
            .icon-wallet {
              width: 20px;
              height: 20px;
              img {
                width: auto;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 280px) {
    padding: 0 5px;
    .form-connect {
      .wrapper-content-connect {
        padding: 18px 6px 32px;
        .list-wallet {
          .box-item-wallet {
            padding: 8px 6px;
            &:nth-child(1) {
              margin-left: 8px;
            }
            &:nth-child(2) {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
