:root {
  .accordion-button {
    &::after {
      background-image: url('../../../assets/svg/icon_arrow_dropdown_light.svg');
    }
  }
}
[data-theme='dark'] {
  .accordion-button {
    &::after {
      background-image: url('../../../assets/svg/icon_arrow_dropdown.svg');
    }
  }
}
.collectibles-item-details-container {
  padding: 65px 0 100px;
  margin: auto;
  max-width: 100vw;
  background: var(--background-color);
  color: var(--font-color);
  // a,
  // button {
  //   color: inherit;
  //   font: inherit;
  //   text-decoration: none;
  //   border: none;
  //   background: transparent;
  //   cursor: pointer;
  // }

  .collectibles-details-wp {
    width: 100%;
    max-width: $page-content-width;
    margin: 0px auto;
    padding: 10px 40px;
    .back-button {
      z-index: 10;
      height: 40px;
      width: 100px;
      padding: 0px 15px;
      border-radius: 8px;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: var(--font-color);
      background: var(--background-bottom-box-card);
      border: none;
      font-weight: 600;
      &:hover {
        opacity: 0.8;
        transition: all 0.3s ease-in-out 0s;
        img {
          transform: translateX(-5px);
          transition: all 0.3s ease-in-out 0s;
        }
      }
    }
    .product-details {
      display: flex;
      justify-content: space-around;
      padding-bottom: 40px;
      margin-top: 24px;
      padding-top: 40px;
      gap: 20px;
      // border: 1px solid #444444;
      border: var(--border-box-collection);
      border-radius: 15px;
      .wrapper-detail-box-mobile {
        display: none;
      }
      .wrapper-left {
        padding: 0 30px 20px;
        // border-right: 1px solid #444444;
        // border-right: var(--border-box-collection);
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .showcase-box {
          position: relative;
          max-width: 500px;
          max-height: 500px;
          width: 100%;
          height: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          .bg-linear {
            position: absolute;
            inset: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(2, 2, 30, 0) 75%, #02021e 100%);
          }
          .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
          video {
            width: 100%;
            max-height: 500px;
            border-radius: 8px;
          }
          .zoom-image {
            border-radius: 16px;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 35px;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .accordion {
          max-width: 502px;
          width: 100%;
          height: 100%;
          margin: auto;
          --bs-accordion-border-color: unset;
          .accordion-item {
            background-color: transparent;
            border: 1px solid var(--border-color-table);
            color: var(--font-color);
            .accordion-header {
              background: var(--bg-accordion-header);
              font-size: 16px;
              font-weight: 700;
              .accordion-button {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                padding: 20px 16px;
                background-color: transparent;
                color: #ffffff;
                .content-left {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  font-weight: 700;
                  color: var(--font-color);
                }
                &::after {
                  // background-image: url('../../../assets/svg/icon_arrow_dropdown.svg');
                  background-size: 24px;
                }
                &:not(.collapsed) {
                  color: #ffffff;
                  background-color: transparent;
                  box-shadow: unset;
                  border-bottom: 1px solid var(--border-color-table);
                }
                &:not(.collapsed)::after {
                  transition: 500ms;
                  transform: rotateX(180deg);
                }
              }
            }
            &:first-of-type {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom: unset;
            }
            .accordion-properties {
              // background: var(--bg-accordion-properties);
              padding: 16px;
              .padding-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 12px;
                .item-properties {
                  // border: 1px solid #2f8af5;
                  background-color: var(--bg-item-properties);
                  border-radius: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  min-height: 84px;
                  text-align: center;
                  justify-content: center;
                  color: var(--font-color);
                  padding: 10px;
                  .content-01 {
                    color: #2f8af5;
                    font-size: 11px;
                    font-weight: 400;
                    text-transform: uppercase;
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                  }
                  .content-02 {
                    font-size: 14px;
                    word-break: break-all;
                    font-weight: 400;
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                  }
                  .content-03 {
                    color: var(--font-color-02);
                    font-size: 12px;
                  }
                }
              }
            }
            .accordion-details {
              // background: var(--bg-accordion-details);
              padding: 16px;
              font-size: 14px;
              line-height: 28px;
              .padding-content {
                .item-details {
                  display: flex;
                  justify-content: space-between;
                  .info-name {
                    font-weight: 600;
                    img {
                      width: 14px;
                      margin: 0 0 -2px 4px;
                    }
                  }
                  &:first-child {
                    .info-name {
                      color: #2f8af5;
                      font-weight: 600;
                      cursor: pointer;
                      img {
                        width: 14px;
                        margin: 0 0 -2px 4px;
                      }
                    }
                  }
                  &:nth-child(2) {
                    .info-name {
                      color: #2f8af5;
                      font-weight: 600;
                      cursor: pointer;
                      img {
                        width: 14px;
                        margin: 0 0 -2px 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .details-box-mobile {
        display: none;
      }
      .details-box {
        width: 50%;
        margin: 0px auto;
        text-align: center;
        margin-right: 20px;
        .banner-dropdown {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: space-between;
          position: relative;
          // cursor: pointer;
          border-bottom: var(--border-box-collection);
          padding-bottom: 16px;

          .logo {
            display: flex;
            > img {
              border-radius: 100%;
              background-color: white;
              width: 32px;
              height: 32px;
            }
            .logo-info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 10px;
              gap: 4px;
              .creator {
                font-size: 12px;
                text-decoration: none;
                width: max-content;
              }
              .name {
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                text-align: left;
                max-width: 400px;
              }
            }
          }
        }
        .line-banner {
          border-bottom: 1px solid var(--border-line-modal);
          margin: 16px 0 24px;
        }
        .name-product {
          padding-top: 24px;
          font-weight: 900;
          font-size: 24px;
          line-height: 42px;
          text-align: center;
        }
        .sub-product {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--color-content-featured);
          margin: 24px 0;
          text-align: center;
          white-space: pre-wrap;
        }
        .label-name {
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          font-size: 30px;
          padding-right: 15px;
          margin-bottom: 20px;
          font-weight: bold;
          .rarity-label {
            font-size: 18px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(227, 100, 190);
            img {
              margin-right: 5px;
            }
          }
        }
        .description-label {
          font-size: 14px;
          margin-bottom: 20px;
          text-align: left;
        }
        .authenticity-button {
          font-size: 12px;
          color: #2f8af5;
          margin-bottom: 0px;
          display: flex;
          padding-bottom: 24px;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .list-item {
          display: grid;
          // padding: 10px 0px;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          gap: 12px;
          margin-bottom: 20px;
          .item {
            padding: 20px;
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            text-transform: capitalize;
            background: var(--background-box-baobab);
            border-radius: 10px;
            justify-content: space-between;
            .label {
              font-size: 12px;
              color: $purple-color-primary;
              padding-bottom: 5px;
              text-transform: none;
            }
            .value {
              // color: #fff;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }

        .wrapper-user-details {
          width: 100%;
          background: var(--background-box-baobab);
          border-radius: 10px;
          padding: 16px;
          margin-bottom: 20px;
          .title-details {
            font-weight: 700;
            font-size: 16px;
            border-bottom: 1px solid var(--border-color-table);
            text-align: left;
            padding-bottom: 16px;
          }
          .wrapper-content-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            margin: 16px 0;
            .wrapper-user {
              display: flex;
              gap: 8px;
              align-items: center;
              font-size: 14px;
              font-weight: 700;
              .avt-user {
                min-width: 48px;
                width: 48px;
                height: 48px;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  object-fit: cover;
                  background: #ffffff;
                }
              }
              .user-name {
                text-align: left;
              }
            }
            .wrapper-see-collection {
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              color: #2f8af5;
              .image-see-collection {
                width: 16px;
                img {
                  margin-top: 5px;
                  width: 100%;
                }
              }
              .title {
                white-space: nowrap;
              }
            }
          }
          .details-info {
            font-size: 14px;
            line-height: 24px;
            color: var(--color-content-featured);
            text-align: justify;
          }
        }
        .wrapper-other-items {
          padding: 16px;
          background: var(--background-box-baobab);
          border-radius: 10px;
          width: 100%;
          position: relative;
          .wrapper-head {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--border-color-table);
            padding-bottom: 16px;
            .title-items {
              font-weight: 700;
              font-size: 16px;
            }
            .seemore-otheritems {
              font-size: 14px;
              font-weight: 400;
              color: #2f8af5;
              cursor: pointer;
            }
          }
          .carousel-other-items {
            padding-top: 16px;
            text-align: left;
            .item-other {
              width: 80px;
              display: flex;
              flex-direction: column;
              margin: auto;
              gap: 6px;
              .item-image {
                width: 80px;
                height: 80px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  background: #ffffff;
                  border-radius: 8px;
                }
              }
              .info-item-detail {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .id-card {
                  font-size: 12px;
                  color: var(--color-content-featured);
                }
                .price-item {
                  font-weight: 700;
                  font-size: 14px;
                }
              }
            }
          }
          &:hover {
            .arrow-item-details {
              .arrow-one {
                opacity: 1;
              }
              .arrow-two {
                opacity: 1;
              }
            }
          }
          .dot-other-items {
            bottom: -10px;
            .react-multi-carousel-dot--active button {
              background: #2f8af5;
            }
          }
        }
        .list-trade {
          margin-top: 35px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          margin-bottom: 20px;

          .btn-trade.disabled {
            opacity: 0.4;
            cursor: default;
          }
          .btn-trade {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            width: 100%;
            //max-width: 350px;
            height: 64px;
            border-radius: 10px;
            padding: 20px;
            text-transform: none;
            font-size: 16px;
            color: white;
            background: #2f8af5;
            img {
              margin-right: 10px;
            }
          }
          .noti-cannot {
            display: none;
            max-width: 160px;
            text-align: center;
            border-radius: 12px;
            color: #fff;
            background-color: #23235c;
            font-size: 16px;
            border: 1px solid transparent;
            padding: 0px 21px;
            pointer-events: none;
            transition: opacity 0.3s ease-out;
          }
          .btn-trade:hover + .noti-cannot {
            position: absolute;
            bottom: 100%;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9999;
            margin-bottom: 10px;
            max-width: 160px;
            width: max-content;
          }
          .btn-trade:hover + .down {
            position: absolute;
            top: 100%;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9999;
            margin-bottom: 10px;
            max-width: 160px;
            width: max-content;
          }
        }
        .btn-trade {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          position: relative;
          width: 100%;
          //max-width: 350px;
          height: 64px;
          border-radius: 10px;
          padding: 20px;
          text-transform: none;
          font-size: 16px;
          color: white;
          background: #2f8af5;
          img {
            margin-right: 10px;
          }
        }
      }
    }
    .price-history {
      margin-bottom: 90px;
      margin-top: 80px;
      .price-history-label {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-weight: bold;
        font-weight: 900;
        font-size: 28px;
        line-height: 42px;
        img {
          width: 32px;
          height: 32px;
          margin-right: 14px;
        }
      }
      .list-price-history {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
      }
    }
    .my-revealed-items {
      margin-bottom: 50px;
      text-align: center;
      font-weight: 900;
      font-size: 28px;
    }
    .marketplace-items {
      margin: 0px;
      width: 100%;
      position: relative;
      display: grid;
      gap: 20px;
      place-items: center;
      max-width: 1200px;
      grid-template-columns: repeat(5, 1fr);

      a {
        background: #02021e;
        color: #fff;
        border-radius: 15px;
        // box-shadow: 0 0 5px 5px #f0ebfc;
        border: 1px solid #444444;
        &:hover {
          transform: scale(1.05);
          transition: 0.5s;
          border: 1px solid #2f8af5;
        }
      }

      .item_product {
        width: 220px;
        height: 390px;
        display: flex;
        flex-flow: row wrap;
        border-radius: 15px;
        overflow: hidden;
        transition: transform 0.15s ease-in-out 0s;
        cursor: pointer;
        margin: 0px;
        color: var(--font-color);
        .item_product_detail {
          width: 100%;
          padding: 0px 12px;
          border-right: none;

          .card-image {
            // padding: 15px 0px 0px;
            // text-align: center;
            // width: 100%;
            // margin-bottom: 10px;
            text-align: center;
            width: 158px;
            margin-bottom: 10px;
            height: 158px;
            margin: auto;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100%;
              max-height: 158px;
            }
          }

          .owner_product {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-top: auto;
            font-size: 18px;
            font-size: 12px;
            font-weight: 700;

            a {
              box-shadow: none;
              background: none;
              border: none;
            }

            .owner_product_box {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              font-size: 12px;
              letter-spacing: -1px;
              gap: 4px;
              color: var(--color-username-card);

              .owner_product_avatar {
                min-width: 24px;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                overflow: hidden;

                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background-color: #ffffff;
                }
              }
            }

            // .owner_product_box::after {
            //   background-color: transparent;
            //   background-position: 50% center;
            //   background-repeat: no-repeat;
            //   background-size: cover;
            //   content: '';
            //   display: block;
            //   margin: 0px 0px 0.3125rem 0.2rem;
            //   width: 0.55rem;
            //   height: 0.55rem;
            //   background-image: url('./../../../assets/img/verify-icon.png');
            // }

            .status {
              color: $purple-color-primary;
              text-transform: uppercase;
              white-space: nowrap;
            }
          }

          .product_name {
            margin: 12px 0 10px;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 700;
          }

          .box-price {
            border-right: 1px solid var(--line-bottom-boxcard);

            .price {
              font-size: 10px;
            }

            .currency {
              font-size: 14px;
              text-transform: uppercase;
              font-weight: 700;
            }
          }

          .remaining {
            font-size: 10px;
          }

          .remaining-total {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        .item_product_detail:nth-child(1) {
          width: auto;
          font-size: 12px;
          margin: 10px 0px 0px;
          padding: 0px 0px 0px 12px;
          flex: 1 1 0%;
        }

        .item_product_detail:nth-child(2) {
          width: auto;
          text-align: right;
          margin: 10px 0px 0px;
          color: var(--color-title-chain);
          font-size: 12px;
          padding-left: 0px;
          text-transform: uppercase;
        }
        .item_product_detail:nth-child(3) {
          padding: 32px 12px;
        }
        .item_product_detail:nth-child(5) {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          text-align: center;
        }

        .item_product_detail:nth-last-child(1),
        .item_product_detail:nth-last-child(2) {
          text-align: center;
          // background-color: rgba(255, 255, 255, 0.1);
          background-color: var(--background-bottom-box-card);
          padding: 15px 0px;
          width: 50%;
          font-size: 0.6875rem;
        }
      }
    }
    .my-product {
      //background-color: red;
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      margin-left: 5px;

      .product {
        width: 300px;
        margin-left: 5px;

        img {
          width: 100%;
          //object-fit: cover;
        }
      }
    }
  }
}
@media (max-width: 1170px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .product-details {
        .wrapper-left {
          .showcase-box {
            min-width: unset;
            min-height: unset;
            img {
              width: 100%;
              height: 100%;
              border-radius: 0px;
            }
            video {
              width: 100%;
              max-height: 500px;
            }
          }
        }
      }
    }
  }
}

@include Tablet_Mobile() {
  .collectibles-item-details-container {
    padding: 65px 0 0;
    .collectibles-details-wp {
      .product-details {
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;
        // overflow: hidden;
        .wrapper-left {
          width: 100%;
          border-right: unset;
          padding: 0 20px 30px;
          .showcase-box {
            width: 100%;
            // height: 500px;
            border-right: none;
            padding-top: 0px;
            .back-button {
              margin-left: 25px;
            }
            .thumbnail {
              width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
            }
            video {
              width: 100%;
              max-height: 500px;
            }
          }
        }
        .details-box {
          margin: 0 auto;
          width: 90%;
          .banner-dropdown {
            display: none;
          }
          .label-name {
            font-size: 25px;
          }
          .list-trade {
            .btn-trade.disabled {
              position: relative;
            }
            .btn-trade {
              font-size: 14px;
            }
            .noti-cannot {
              display: none;
              max-width: 160px;
              text-align: center;
              border-radius: 12px;
              color: #fff;
              background-color: #23235c;
              font-size: 16px;
              border: 1px solid transparent;
              padding: 0px 21px;
              pointer-events: none;
              transition: opacity 0.3s ease-out;
            }
          }
          .traits-and-detail-mb {
            margin-top: 16px;
            .accordion {
              max-width: 502px;
              width: 100%;
              height: 100%;
              margin: auto;
              --bs-accordion-border-color: unset;
              .accordion-item {
                background-color: transparent;
                border: 1px solid var(--border-color-table);
                color: var(--font-color-06);
                .accordion-header {
                  background: var(--bg-accordion-header);
                  font-size: 10px;
                  font-weight: 700;
                  .accordion-button {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    padding: 12px 16px;
                    background-color: transparent;
                    color: #ffffff;
                    .content-left {
                      display: flex;
                      gap: 10px;
                      align-items: center;
                      font-size: 10px;
                      font-weight: 700;
                      color: var(--font-color);
                      .image-properties {
                        img {
                          width: 18px;
                          height: 18px;
                        }
                      }
                    }
                    &::after {
                      // background-image: url('../../../assets/svg/icon_arrow_dropdown.svg');
                      background-size: 24px;
                    }
                    &:not(.collapsed) {
                      color: #ffffff;
                      background-color: transparent;
                      box-shadow: unset;
                      border-bottom: 1px solid var(--border-color-table);
                    }
                    &:not(.collapsed)::after {
                      transition: 500ms;
                      transform: rotateX(180deg);
                    }
                  }
                }
                &:first-of-type {
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  border-bottom: unset;
                }
                .accordion-properties {
                  // background: var(--bg-accordion-properties);
                  padding: 12px;
                  .padding-content {
                    display: grid;
                    //grid-template-columns: repeat(3, 1fr);
                    gap: 8px;
                    .item-properties {
                      // border: 1px solid #2f8af5;
                      background-color: var(--bg-item-properties);
                      border-radius: 6.2px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      height: 100%;
                      text-align: center;
                      justify-content: center;
                      color: var(--font-color);
                      padding: 12px 10px;
                      .content-01 {
                        color: #2f8af5;
                        font-size: 9px;
                        line-height: 8px;
                        font-weight: 400;
                        text-transform: uppercase;
                        word-break: break-all;
                        width: 100%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                      }
                      .content-02 {
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 11px;
                        padding-top: 4px;
                        word-break: break-all;
                        width: 100%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                      }
                      .content-03 {
                        color: var(--font-color-02);
                        font-size: 12px;
                      }
                    }
                  }
                }
                .accordion-details {
                  // background: var(--bg-accordion-details);
                  padding: 16px;
                  font-size: 12px;
                  line-height: 12px;
                  font-weight: 400;
                  .padding-content {
                    .item-details {
                      display: flex;
                      justify-content: space-between;
                      padding-bottom: 8px;

                      .info-name {
                        color: var(--font-color);
                        font-weight: 600;
                        img {
                          width: 10px;
                          margin: 0 0 -2px 4px;
                        }
                      }
                      &:first-child {
                        .info-name {
                          color: #2f8af5;
                          font-weight: 600;
                          cursor: pointer;
                          img {
                            width: 10px;
                            margin: 0 0 -2px 4px;
                          }
                        }
                      }
                      &:nth-child(2) {
                        .info-name {
                          color: #2f8af5;
                          font-weight: 600;
                          cursor: pointer;
                          img {
                            width: 10px;
                            margin: 0 0 -2px 4px;
                          }
                        }
                      }
                      &:last-child {
                        padding-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .details-box-mobile {
          display: block;
          width: 90%;
          margin: 0px auto;
          .label-name {
            padding: 0px;
            margin-bottom: 20px;
            font-size: 26px;
            flex-direction: column;
            text-align: center;
          }
          .rarity-label {
            margin: 0px auto;
            font-size: 14px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .list-item {
            gap: 5px;
            .item {
              max-width: 30vw;
              padding: 20px 5px;
              font-size: 12px;
            }
          }
          .list-trade {
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
          }
          .wrapper-user-details {
            .wrapper-content-details {
              .wrapper-user {
                font-size: 12px;
              }
            }
            .details-info {
              font-size: 14px;
            }
          }
          .banner-dropdown {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            position: relative;
            cursor: pointer;

            .logo {
              display: flex;
              > img {
                border-radius: 100%;
                background-color: white;
                width: 40px;
                height: 40px;
              }
              .logo-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                gap: 4px;
                .creator {
                  font-size: 12px;
                  text-decoration: none;
                  width: max-content;
                }
                .name {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: 700;
                  text-align: left;
                }
              }
            }
          }
          .line-banner {
            border-bottom: 1px solid var(--border-line-modal);
            margin: 16px 0 24px;
          }
        }
      }
    }
  }
}
@media (max-width: 1279px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .marketplace-items {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
@media (max-width: 1029px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .marketplace-items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
@media (max-width: 789px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      padding: 10px 20px 40px;
      .marketplace-items {
        grid-template-columns: repeat(2, 1fr);
      }
      .product-details {
        .details-box {
          .wrapper-other-items {
            .carousel-other-items {
              .item-other {
                .item-image {
                  width: 104px;
                  height: 104px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 639px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .back-button {
        font-size: 12px;
        width: 72px;
        height: 30px;
        padding: 8px 10px;
        gap: 10px;
      }
      .marketplace-items {
        grid-template-columns: repeat(1, 1fr);
        a {
          width: 90%;
        }
        .item_product {
          height: auto;
          width: 100%;
          .item_product_detail {
            .card-image {
              width: 80%;
              height: 250px;

              video {
                max-height: 250px;
              }
            }
            .product_name {
              white-space: unset;
            }
          }
          .item_product_detail:nth-child(3) {
            padding: 15px 12px 10px;
          }
        }
      }
      .product-details {
        .details-box {
          .name-product {
            font-size: 20px;
            line-height: 24px;
          }
          .sub-product {
            font-size: 12px;
            line-height: 16px;
            margin: 16px 0;
          }
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      // padding: 10px 20px;
      .price-history {
        margin-top: 60px;
        margin-bottom: 60px;
        .price-history-label {
          font-size: 26px;
        }
      }
      .my-revealed-items {
        font-size: 26px;
        margin-bottom: 30px;
      }
      .marketplace-items {
        grid-template-columns: repeat(1, 1fr);
      }
      .product-details {
        .wrapper-left {
          .accordion {
            .accordion-item {
              .accordion-properties {
                .padding-content {
                  grid-template-columns: repeat(2, 1fr);
                }
              }
            }
          }
        }
      }
    }
  }
}
@include Mobile() {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .product-details {
        .wrapper-detail-box-mobile {
          padding: 10px 0px 30px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
          .accordion {
            max-width: 500px;
            width: 100%;
            height: 100%;
            margin: auto;
            --bs-accordion-border-color: unset;
            .accordion-item {
              background-color: transparent;
              border: 1px solid var(--border-color-table);
              color: var(--font-color);
              .accordion-header {
                background: var(--bg-accordion-header);
                font-size: 16px;
                font-weight: 700;
                .accordion-button {
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  padding: 12px 16px;
                  background-color: transparent;
                  color: #ffffff;
                  .content-left {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-weight: 700;
                    color: var(--font-color);
                    font-size: 10px;
                    .image-properties {
                      img {
                        width: 18px;
                        height: 18px;
                      }
                    }
                    .image-details {
                      img {
                        width: 18px;
                        height: 18px;
                      }
                    }
                  }
                  &::after {
                    // background-image: url('../../../assets/svg/icon_arrow_dropdown.svg');
                    background-size: 24px;
                  }
                  &:not(.collapsed) {
                    color: #ffffff;
                    background-color: transparent;
                    box-shadow: unset;
                    border-bottom: 1px solid var(--border-color-table);
                  }
                  &:not(.collapsed)::after {
                    transition: 500ms;
                    transform: rotateX(180deg);
                  }
                }
              }
              &:first-of-type {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                border-bottom: unset;
              }
              .accordion-properties {
                // background: rgba(255, 255, 255, 0.1);
                padding: 12px;
                .padding-content {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 8px;
                  .item-properties {
                    // border: 1px solid #2f8af5;
                    background-color: var(--bg-item-properties);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // height: 84px;
                    text-align: center;
                    justify-content: center;
                    color: var(--font-color);
                    padding: 10px;
                    .content-01 {
                      color: #2f8af5;
                      font-size: 11px;
                      text-transform: uppercase;
                    }
                    .content-02 {
                      font-weight: 400;
                      font-size: 10px;
                      width: 100%;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      text-overflow: ellipsis;
                    }
                    .content-03 {
                      color: var(--font-color-02);
                      font-size: 10px;
                    }
                  }
                }
              }
              .accordion-details {
                // background: rgba(251, 253, 255, 0.05);
                padding: 16px;
                font-size: 12px;
                line-height: 28px;
                .padding-content {
                  .item-details {
                    display: flex;
                    justify-content: space-between;
                    .info-name {
                      font-weight: 600;
                      img {
                        width: 14px;
                        margin: 0 0 -2px 4px;
                      }
                    }
                    &:first-child {
                      .info-name {
                        color: #2f8af5;
                        font-weight: 600;
                        cursor: pointer;
                        img {
                          width: 14px;
                          margin: 0 0 -2px 4px;
                        }
                      }
                    }
                    &:nth-child(2) {
                      .info-name {
                        color: #2f8af5;
                        font-weight: 600;
                        cursor: pointer;
                        img {
                          width: 14px;
                          margin: 0 0 -2px 4px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .wrapper-left {
          height: 100%;
          .showcase-box {
            // width: 90%;
            height: 100%;
            margin-bottom: 0px;
            .back-button {
              margin-left: 0px;
            }
            .thumbnail {
              height: auto;
            }
            img {
              width: 100%;
              height: 100%;
            }
            video {
              width: 100%;
              max-height: 500px;
            }
          }
          .accordion {
            display: none;
          }
        }

        .details-box-mobile {
          display: block;
          width: 90%;
          margin: 0px auto;
          .label-name {
            padding: 0px;
            margin-bottom: 20px;
            font-size: 26px;
            flex-direction: column;
            text-align: center;
          }
          .rarity-label {
            margin: 0px auto;
            font-size: 14px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .list-item {
            gap: 5px;
            .item {
              max-width: 30vw;
              padding: 20px 5px;
              font-size: 12px;
            }
          }
          .list-trade {
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
          }
          .wrapper-user-details {
            .wrapper-content-details {
              .wrapper-user {
                font-size: 12px;
              }
            }
            .details-info {
              font-size: 14px;
            }
          }
          .banner-dropdown {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            position: relative;
            cursor: pointer;

            .logo {
              display: flex;
              > img {
                border-radius: 100%;
                background-color: white;
                width: 32px;
                height: 32px;
              }
              .logo-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                gap: 4px;
                .creator {
                  font-size: 10px;
                  text-decoration: none;
                  width: max-content;
                }
                .name {
                  font-size: 14px;
                  line-height: 14px;
                  font-weight: 700;
                  text-align: left;
                  max-width: 195px;
                }
              }
            }
          }
          .line-banner {
            border-bottom: 1px solid var(--border-line-modal);
            margin: 16px 0 24px;
          }
        }

        .details-box {
          .traits-and-detail-mb {
            margin-bottom: 20px;
          }
          .banner-dropdown {
            display: none;
          }
          margin: -15px auto;
          .label-name {
            padding: 0px;
            margin-bottom: 20px;
            font-size: 26px;
            flex-direction: column;
            text-align: center;
          }
          .rarity-label {
            margin: 0px auto;
            font-size: 14px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .list-item {
            gap: 5px;
            .item {
              max-width: 30vw;
              padding: 20px 5px;
              font-size: 12px;
            }
          }
          .list-trade {
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
          }
          .wrapper-user-details {
            .wrapper-content-details {
              .wrapper-user {
                font-size: 12px;
                .avt-user {
                  min-width: 48px;
                  width: 80px;
                  height: 80px;
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    object-fit: cover;
                    background: #ffffff;
                  }
                }
              }
              .wrapper-see-collection {
                font-size: 12px;
              }
            }
            .details-info {
              font-size: 10px;
              line-height: 16px;
            }
            .title-details {
              font-size: 10px;
              padding-bottom: 12px;
            }
          }
          .wrapper-other-items {
            padding: 12px 16px 16px;
            .wrapper-head {
              padding-bottom: 12px;
              .title-items {
                font-size: 12px;
              }
              .seemore-otheritems {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .product-details {
        .details-box {
          .wrapper-user-details {
            .title-details {
              text-align: center;
            }
            .wrapper-content-details {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .wrapper-user {
                display: flex;
                flex-direction: column;
                gap: 16px;
              }
            }
            .details-info {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .collectibles-item-details-container {
    .collectibles-details-wp {
      .product-details {
        .wrapper-left {
          .accordion {
            .accordion-item {
              .accordion-properties {
                .padding-content {
                  .item-properties {
                    .content-01 {
                      font-size: 10px;
                    }
                    .content-02 {
                      font-size: 10px;
                      width: 100%;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      text-overflow: ellipsis;
                    }
                    .content-03 {
                      font-size: 10px;
                    }
                  }
                }
              }
              .accordion-details {
                font-size: 12px;
              }
            }
          }
        }
        .details-box {
          margin: -15px auto;
          .list-item {
            gap: 5px;
            .item {
              max-width: 30vw;
              padding: 20px 5px;
              font-size: 12px;
              .label {
                font-size: 10px;
              }
              .value {
                font-size: 12px;
              }
            }
          }
          .banner-dropdown {
            cursor: pointer;
            .logo {
              .logo-info {
                .name {
                  font-size: 14px;
                }
              }
            }
            .collection-info-right {
              gap: 0;
            }
          }
        }
      }
    }
  }
}
.noti-cannot {
  max-width: 200px;
  text-align: center;
  border-radius: 12px;
  color: #fff;
  background-color: #23235c;
  font-size: 14px;
  border: 1px solid transparent;
  padding: 0px 21px;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}
