//arrow carousel featured collections
.arrow-featured-collections {
  position: relative;
  bottom: 158px;
  .arrow-one {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    // bottom: 108px;
    left: 6px;
    transform: translateX(-40%);
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    //
    // .arrow-left {
    //   img {
    //     padding: 9px 0 0 9px;
    //   }
    // }
  }
  .remove-arrow {
    display: none;
  }
  .arrow-two {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    // bottom: 108px;
    right: -9px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    //
    // .arrow-right {
    //   img {
    //     padding: 9px 0 0 9px;
    //   }
    // }
  }
  @media (max-width: 1024px) {
    .arrow-one {
      left: 15px;
    }
    .arrow-two {
      right: 0;
    }
  }
  @media (max-width: 639px) {
    bottom: 118px;
    .arrow-one {
      // bottom: 118px;
      left: 5px;
    }
    .arrow-two {
      // bottom: 118px;
      right: -10px;
    }
  }
}

// arrow carousel hot collectibles and free drops

.custom-arrow-carousel {
  position: relative;
  bottom: 240px;
  .arrow-one {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    // bottom: 190px;
    left: 6px;
    transform: translateX(-40%);
    cursor: pointer;
    //
    img {
      width: 100%;
      height: 100%;
    }
    .arrow-left {
      img {
        padding: 9px 0 0 9px;
      }
    }
  }
  .remove-arrow {
    display: none;
  }
  .arrow-two {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    // bottom: 190px;
    right: -9px;
    cursor: pointer;
    //
    img {
      width: 100%;
      height: 100%;
    }
    .arrow-right {
      img {
        padding: 9px 0 0 9px;
      }
    }
  }
  @media (max-width: 1024px) {
    .arrow-one {
      left: 15px;
    }
    .arrow-two {
      right: 0;
    }
  }
  @media (max-width: 639px) {
    bottom: 220px;
    .arrow-one {
      // bottom: 220px;
      left: 5px;
    }
    .arrow-two {
      // bottom: 220px;
      right: -10px;
    }
  }
}
//arrow carousel banner main page
.banner-main-carousel {
  $temp: calc((100% - min(calc(100% - 80px), 1187px)) / 2);
  .arrow-one {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    left: $temp;
    transform: translateX(-40%);
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    //
    // .arrow-left {
    //   img {
    //     padding: 9px 0 0 9px;
    //   }
    // }
  }
  .arrow-two {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    opacity: 0.2;
    //border-radius: 100%;
    position: absolute;
    right: $temp;
    transform: translateX(40%);
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    //
    // .arrow-right {
    //   img {
    //     padding: 9px 0 0 9px;
    //   }
    // }
  }
  @media (max-width: 1024px) {
    .arrow-one {
      left: 55px;
    }
    .arrow-two {
      right: 40px;
      transform: unset;
    }
  }
  @media (max-width: 639px) {
    .arrow-one {
      left: 25px;
    }
    .arrow-two {
      right: 10px;
    }
  }
  &:hover {
    .arrow-one {
      // background: #ffffff;
      opacity: 1;
    }
    .arrow-two {
      // background: #ffffff;
      opacity: 1;
    }
  }
}

//arrow carousel item details
.arrow-item-details {
  position: absolute;
  margin: auto;
  z-index: 19;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  bottom: 35%;
  .arrow-one {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    // border-radius: 100%;
    opacity: 0.2;
    position: relative;
    left: -3.2%;
    cursor: pointer;
    //
    img {
      width: 100%;
      height: 100%;
    }
  }
  .remove-arrow {
    display: none;
  }
  .arrow-two {
    width: 40px;
    height: 40px;
    // background: rgba($color: #ffffff, $alpha: 0.2);
    // border-radius: 100%;
    opacity: 0.2;
    position: relative;
    right: -3.2%;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 768px) {
    .arrow-one {
      left: 0;
    }
    .arrow-two {
      right: 0;
    }
  }
  @media (max-width: 350px) {
    .arrow-one {
      left: -2%;
    }
    .arrow-two {
      right: -2%;
    }
  }
}
