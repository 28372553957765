.my-wallet-container {
  max-width: 100vw;
  padding: 110px 40px 50px;
  margin: 0px auto;
  max-width: 100vw;
  width: min(100%, $page-content-width);
  display: flex;
  justify-content: space-between;
  color: var(--font-color-08);
  .wallet-wp {
    // width: max-content;
    width: 894px;
    // margin: auto;

    .banner {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      height: 56px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      .collectibles {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        .banner-title {
          font-weight: 700;
          font-size: 18px;
          padding-left: 16px;
          // color: var(--font-color);
        }
      }
    }
    .wallet-sort {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      justify-content: space-between;
      margin: 0 0 18px;
      padding-top: 0px;
      // border-top: var(--border-box-card);
      .form-select-short {
        width: 120px;
        height: 47px;
        font-size: 14px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        -webkit-appearance: none;
      }
      .form-select-short:focus {
        width: 120px;
        -webkit-appearance: none;
      }
      @media (max-width: 767px) {
        .form-select-short {
          width: 120px;
          -webkit-appearance: none;
        }
      }
      @media (max-width: 767px) {
        .form-select-short:focus {
          width: 120px;
          -webkit-appearance: none;
        }
      }
      .count-label {
        color: rgb(153, 153, 153);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
      }
      .sort-box {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        height: 40px;
        width: 225px;
        justify-content: space-between;
        .sort-selected {
          color: var(--font-color-07);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          background-color: transparent;
          position: relative;
          align-self: center;
          .name-sort-selected {
            //font-weight: bold;
            line-height: 36px;
            order: 1;
          }
          .not-selected {
            display: none;
          }
        }
        .sort-dropdown-box {
          height: auto;
          border-radius: 8px;
          position: absolute;
          right: 0px;
          top: 60%;
          flex-direction: column;
          padding: 5px 20px;
          box-shadow: rgb(18 18 18 / 5%) 5px 5px 10px 10px, rgb(18 18 18 / 5%) -5px -5px 10px 10px;
          z-index: 9;
          width: 100px;
          background-color: var(--background-modal);
          .name-sort-dropdown-box {
            display: flex;
            flex-direction: column;
            // padding: 0 20px;
            .sort-button {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              padding: 10px 0px;
              border: none;
              background-color: var(--background-modal);
              color: var(--font-color);
            }
            .sort-button:hover {
              // color: rgb(227 100 190);
              color: #2f8af5;
            }
          }
        }
        .sort-dropdown-button {
          background-color: transparent;
          margin-left: auto;
          order: 1;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          border: none;
          cursor: pointer;
          // width: 24px;
          // height: 24px;
          // img {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }
    }
    .page-grid {
      margin: 0px;
      position: relative;
      display: grid;
      gap: 16px;
      place-items: center;
      // max-width: 894px;
      grid-template-columns: repeat(4, calc(25% - 12px));
      // a {
      //   color: #ffffff;
      //   text-decoration: none;
      //   border: none;
      //   cursor: pointer;
      //   // background: #02021e;
      //   border-radius: 15px;
      //   border: var(--border-box-card);
      //   width: 100%;
      //   height: auto;
      //   .item-product {
      //     display: flex;
      //     flex-flow: row wrap;
      //     border-radius: 15px;
      //     overflow: hidden;
      //     transition: transform 0.15s ease-in-out 0s;
      //     cursor: pointer;
      //     margin: 0px;
      //     padding: 15px;
      //     gap: 16px;
      //     color: var(--font-color);
      //     box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
      //     .item-product-detail {
      //       width: 100%;
      //       // padding: 0px 15px;
      //       border-right: none;
      //       .card-image {
      //         text-align: center;
      //         width: 100%;
      //         height: 180px;
      //         display: flex;
      //         align-items: center;
      //         img {
      //           width: 100%;
      //           height: 100%;
      //           object-fit: cover;
      //           border-radius: 8px;
      //         }
      //         video {
      //           max-height: 180px;
      //           width: 100%;
      //         }
      //       }
      //       .box-info {
      //         display: flex;
      //         align-items: center;
      //         justify-content: space-between;
      //         margin-top: auto;
      //         font-size: 18px;
      //         .box-product-name {
      //           display: block;
      //           // margin-top: auto;
      //           // margin-bottom: 0.5rem;
      //           font-size: 18px;
      //           width: 85%;
      //           font-weight: 700;
      //           .wrapper-product-type {
      //             display: flex;
      //             justify-content: flex-start;
      //             gap: 7px;
      //             align-items: center;
      //             padding-bottom: 10px;
      //             .avatar {
      //               min-width: 24px;
      //               width: 24px;
      //               height: 24px;
      //               border: 1px solid var(--font-color);
      //               border-radius: 100%;
      //               img {
      //                 border-radius: 100%;
      //                 width: 100%;
      //                 height: 100%;
      //                 margin: 0;
      //                 background-color: #ffffff;
      //               }
      //             }

      //             .product-type {
      //               // color: #aeaecb;
      //               font-size: 14px;
      //               overflow: hidden;
      //               width: 100%;
      //               white-space: nowrap;
      //               text-overflow: ellipsis;
      //             }
      //           }

      //           .product-name {
      //             font-size: 16px;
      //             text-align: left;
      //             overflow: hidden;
      //             width: 100%;
      //             white-space: nowrap;
      //             text-overflow: ellipsis;
      //           }
      //         }
      //         img {
      //           background-color: transparent;
      //           background-position: 50% center;
      //           background-repeat: no-repeat;
      //           background-size: cover;
      //           content: '';
      //           display: block;
      //           margin: 0px 0px;
      //           width: 18px;
      //           height: 18px;
      //         }
      //       }
      //     }
      //   }
      // }
      // a:hover {
      //   transform: scale(1.05);
      //   transition: 0.5s;
      //   border: 1px solid #2f8af5;
      // }
      .my-collectibles {
        color: #ffffff;
        text-decoration: none;
        border: none;
        cursor: pointer;
        // background: #02021e;
        border-radius: 15px;
        border: var(--border-box-card);
        width: 212px;
        height: 318px;
        box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
        .item-product {
          display: flex;
          flex-flow: row wrap;
          border-radius: 15px;
          overflow: hidden;
          transition: transform 0.15s ease-in-out 0s;
          cursor: pointer;
          margin: 0px;
          padding: 16px;
          gap: 12px;
          color: var(--font-color);
          width: 212px;
          height: 318px;
          .item-product-detail {
            width: 100%;
            // padding: 0px 15px;
            border-right: none;
            .card-image {
              text-align: center;
              width: 100%;
              height: 180px;
              display: flex;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
              }
              video {
                // max-height: 180px;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: cover;
              }
            }
            .box-info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: auto;
              font-size: 18px;
              .box-product-name {
                display: block;
                // margin-top: auto;
                // margin-bottom: 0.5rem;
                font-size: 18px;
                width: 100%;
                font-weight: 700;
                .wrapper-product-type {
                  display: flex;
                  justify-content: flex-start;
                  gap: 7px;
                  align-items: center;
                  padding-bottom: 10px;
                  .avatar {
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    border: 1px solid var(--font-color);
                    border-radius: 100%;
                    img {
                      border-radius: 100%;
                      width: 100%;
                      height: 100%;
                      margin: 0;
                      background-color: #ffffff;
                    }
                  }

                  .product-type {
                    // color: #aeaecb;
                    font-size: 14px;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }

                .product-name {
                  font-size: 14px;
                  text-align: left;
                  overflow: hidden;
                  width: 100%;
                  white-space: unset;
                  text-overflow: ellipsis;
                  padding-left: 16px;
                }
              }
              img {
                background-color: transparent;
                background-position: 50% center;
                background-repeat: no-repeat;
                background-size: cover;
                content: '';
                display: block;
                margin: 0px 0px;
                width: 16px;
                height: 16px;
              }
            }
            .blockchain-symbol {
              width: 20px;
              height: 20px;
              min-width: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .my-collectibles:hover {
        transform: scale(1.05);
        transition: 0.5s;
        border: 1px solid #2f8af5;
      }
      .reveal-button {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 10px;
        border-radius: 9px;
        display: block;
        padding: 3px 0px;
        color: #fff;
        background: #2f8af5;
        text-align: center;
      }
    }
  }
  @media (max-width: 1366px) {
    .wallet-wp {
      .page-grid {
        // grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 40px;
    .wallet-wp {
      width: 100%;
      min-width: unset;
      .page-grid {
        // grid-template-columns: repeat(5, 1fr);
      }
      // .banner {
      //   .collectibles {
      //     .banner-title {
      //       padding-left: 20px;
      //     }
      //   }
      // }
    }
  }
  @media (max-width: 1199px) {
    .wallet-wp {
      .page-grid {
        // grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  @media (max-width: 970px) {
    .wallet-wp {
      .page-grid {
        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(3, calc(33% - 8px));
      }
    }
  }
  @media (max-width: 750px) {
    .wallet-wp {
      .page-grid {
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: repeat(2, calc(50% - 8px));
        .my-collectibles {
          width: 100%;
          height: 100%;

          .item-product {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 670px) {
    .wallet-wp {
      .page-grid {
        // grid-template-columns: repeat(1, 1fr);
        // grid-template-columns: repeat(2, calc(50% - 8px));
        // a {
        //   // width: 100%;
        //   // min-height: 336px;
        //   .item-product {
        //     width: 100%;
        //     height: auto;
        //     gap: 8px;
        //     padding: 8px 8px 16px 8px;
        //     .item-product-detail {
        //       .card-image {
        //         // height: 250px;
        //         width: 100%;
        //       }
        //       .box-info {
        //         // min-height: 36px;
        //         .box-product-name {
        //           width: 80%;
        //           .product-name {
        //             white-space: unset;
        //             font-size: 12px;
        //             display: -webkit-box;
        //             -webkit-box-orient: vertical;
        //             -webkit-line-clamp: 2;
        //           }
        //           .product-type {
        //             font-size: 10px;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
        .my-collectibles {
          .item-product {
            width: 100%;
            height: auto;
            gap: 8px;
            padding: 8px 8px 16px 8px;
            .item-product-detail {
              .card-image {
                width: 100%;
                height: 146px;
              }
              .box-info {
                .box-product-name {
                  .product-name {
                    white-space: unset;
                    font-size: 12px;
                  }
                  .product-type {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    padding: 110px 20px 50px;
    .wallet-wp {
      width: 100%;
      min-width: unset;
      // padding: 0 20px 100px;
    }
  }
  .border-dark {
    border: 1px solid #40406c !important;
  }
}
