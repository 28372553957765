//list sns icon
.collection-info-right-sns {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 5px;
  .collection-info-left-details {
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 24px;
    .dropdown {
      .dropdown-button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .info-item-sns {
      background: transparent;
      width: 40px;
      height: 40px;
      // padding: 12px;
      display: grid;
      place-content: center;
      border: none;
      border-radius: 50%;
      box-shadow: rgb(145 145 145) 0px 0px 10px -10px;
      transition: all 0.5s;
      &:hover {
        // transition: all 0.2s ease 0s;
        box-shadow: rgb(145 145 145) 0px 0px 10px 0px;
      }
      .image-item {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .line-icon {
    border-right: 1px solid var(--line-bottom-boxcard);
  }
  .collection-info-right-details {
    text-align: center;

    .value {
      font-size: 26px;
      margin-bottom: 5px;
      font-weight: 600;
    }

    .label {
      color: #2f8af5;
      font-size: 18px;
      font-family: BrandonLight, sans-serif;
    }
  }

  .collection-info-right-details:last-child {
    padding-right: 0px;
    border-right: none;
  }
}
