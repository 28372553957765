.collection-container {
  margin: 0px auto;
  max-width: 100vw;
  color: var(--font-color);
  // padding-bottom: 30px;
  padding-top: 66px;
  a,
  button {
    color: inherit;
    font: inherit;
    text-decoration: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    background: transparent;
  }
  .back-button-wrapper {
    width: 100%;
    max-width: $page-content-width;
    margin: 0px auto;
    padding: 0px 40px;
    .back-button {
      z-index: 10;
      height: 40px;
      width: 100px;
      padding: 0px 15px;
      border-radius: 8px;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: var(--font-color);
      background: var(--background-bottom-box-card);
      border: none;
      font-weight: 600;
      &:hover {
        opacity: 0.8;
        transition: all 0.3s ease-in-out 0s;
        img {
          transform: translateX(-5px);
          transition: all 0.3s ease-in-out 0s;
        }
      }
    }
  }
  .collection-banner-image {
    height: 300px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
  }
}

.box-collection {
  max-width: $page-content-width;
  margin: 0 auto;
  padding: 0 40px;

  .collection-details-box {
    max-width: 1200px;
    margin: 0px auto 10px;

    .collection-info {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 20px;
      -webkit-box-pack: justify;
      justify-content: space-between;

      .collection-info-left {
        display: flex;

        img {
          height: 150px;
          width: 150px;
          border-radius: 50%;
          border: 2px solid var(--border-avatar);
          margin-top: -62px;
          background: #ffffff;
          object-fit: cover;
        }

        .name {
          padding: 0px 30px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;

          .fullname {
            text-transform: capitalize;
            font-size: 24px;
            margin-bottom: 5px;
            font-weight: 600;
          }

          .username {
            font-size: 18px;
            font-weight: 600;
            color: #2f8af5;
          }
        }
      }
      .collection-info-right {
        display: flex;
        -webkit-box-pack: center;
        justify-content: flex-end;
        gap: 5px;
        .collection-info-left-details {
          display: flex;
          justify-content: center;
          align-items: center;
          // gap: 24px;
          .dropdown {
            .dropdown-button {
              cursor: pointer;
              padding-right: 10px;
            }
            .dropdown-box {
              width: 200px;
              height: auto;
              border-radius: 12px;
              background-color: var(--background-modal);
              position: absolute;
              right: 0px;
              flex-direction: column;
              padding: 20px 15px;
              margin: 0;
              z-index: 20;
              list-style: none;
              top: 100%;
              font-weight: 500;
              text-align: left;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              .list-dropdown-item {
                font-weight: 500;
                padding: 16px 0px;
                .dropdown-item-nft {
                  &:hover {
                    color: #2f8af5;
                    svg {
                      path {
                        fill: #2f8af5;
                      }
                    }
                  }
                  .custom-link-sns {
                    display: flex;
                    gap: 12px;
                    color: var(--font-color);
                    align-items: center;

                    .image-sns {
                      width: 20px;
                      height: 20px;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    &:hover {
                      color: #2f8af5;
                    }
                  }
                }
                &:not(:last-child) {
                  border-bottom: var(--border-box-collection);
                }
              }
            }
          }
          .info-item {
            background: transparent;
            width: 48px;
            height: 48px;
            padding: 12px;
            border: none;
            border-radius: 50%;
            box-shadow: rgb(145 145 145) 0px 0px 10px -10px;
            transition: all 0.5s;
            &:hover {
              // transition: all 0.2s ease 0s;
              box-shadow: rgb(145 145 145) 0px 0px 10px 0px;
            }
            .image-item {
              width: 24px;
              height: 24px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .line-icon {
          // border: 1px solid #595959;
          border-right: 1px solid var(--line-bottom-boxcard);
        }
        .collection-info-right-details {
          // padding: 0px 30px;
          text-align: center;

          .value {
            font-size: 26px;
            margin-bottom: 5px;
            font-weight: 600;
          }

          .label {
            color: #2f8af5;
            font-size: 18px;
            font-family: BrandonLight, sans-serif;
          }
        }

        .collection-info-right-details:last-child {
          padding-right: 0px;
          border-right: none;
        }
      }
    }
    .collection-info-content {
      color: var(--color-content-featured);
      font-size: 14px;
      text-align: justify;
      white-space: pre-wrap;
    }
  }

  .marketplace {
    display: block;
    position: relative;
    width: 100%;
    gap: 20px;
    place-items: center;
    max-width: 1200px;
    grid-template-columns: repeat(4, 1fr);

    .marketplace-collection-tittle {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 24px;
      letter-spacing: -1px;
      font-weight: bold;
      margin: 50px 0 25px;
    }

    .marketplace-items {
      margin: 0px;
      width: 100%;
      position: relative;
      display: grid;
      gap: 20px;
      place-items: center;
      max-width: 1200px;
      grid-template-columns: repeat(4, 1fr);
      a {
        color: #fff;
        border-radius: 15px;
        box-shadow: rgb(99 99 99 / 30%) 0px 2px 8px 0px;
        border: var(--border-box-card);
        &:hover {
          transform: scale(1.05);
          transition: 0.5s;
          border: 1px solid #2f8af5;
          box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);

          .img-token {
            .price-and-remaining {
              backdrop-filter: blur(5px);
              opacity: 1 !important;
            }
            .wrapper-price-type {
              backdrop-filter: blur(5px);
              opacity: 1 !important;
              .price-type-home {
                background-color: #2f8af5;
              }
            }
          }
        }
      }

      .item_product {
        // width: 220px;
        // height: 390px;
        display: flex;
        flex-flow: row wrap;
        border-radius: 15px;
        overflow: hidden;
        transition: transform 0.15s ease-in-out 0s;
        cursor: pointer;
        margin: 0px;
        color: var(--font-color);
        position: relative;
        padding: 16px 24px;
        width: 282px;
        .item_product_detail {
          width: 100%;
          // padding: 0px 12px;
          border-right: none;

          // .card-image {
          //   text-align: center;
          //   width: 158px;
          //   margin-bottom: 10px;
          //   height: 158px;
          //   margin: auto;

          //   img {
          //     width: 100%;
          //     height: 100%;
          //     object-fit: contain;
          //   }
          //   video {
          //     width: 100%;
          //     max-height: 158px;
          //   }
          // }

          // .owner_product {
          //   display: flex;
          //   -webkit-box-align: center;
          //   align-items: center;
          //   -webkit-box-pack: justify;
          //   justify-content: space-between;
          //   margin-top: auto;
          //   font-size: 18px;
          //   font-size: 12px;
          //   font-weight: 700;
          //   // min-height: 52px;

          //   a {
          //     box-shadow: none;
          //     background: none;
          //     border: none;
          //   }

          //   .owner_product_box {
          //     display: flex;
          //     -webkit-box-align: center;
          //     align-items: center;
          //     font-size: 12px;
          //     letter-spacing: -1px;
          //     gap: 4px;
          //     color: var(--color-username-card);
          //     .owner_product_avatar {
          //       min-width: 24px;
          //       width: 24px;
          //       height: 24px;
          //       border-radius: 100%;
          //       box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
          //       overflow: hidden;

          //       img {
          //         display: block;
          //         width: 100%;
          //         height: 100%;
          //         background-color: #ffffff;
          //       }
          //     }
          //   }

          //   .status {
          //     color: $purple-color-primary;
          //     text-transform: uppercase;
          //     white-space: nowrap;
          //   }
          // }

          // .product_name {
          //   margin: 12px 0 10px;
          //   font-size: 14px;
          //   text-align: left;
          //   overflow: hidden;
          //   width: 100%;
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          //   font-weight: 700;
          // }

          // .box-price {
          //   border-right: 1px solid var(--line-bottom-boxcard);

          //   .price {
          //     font-size: 10px;
          //   }

          //   .currency {
          //     font-size: 14px;
          //     text-transform: uppercase;
          //     font-weight: 700;
          //   }
          // }

          // .remaining {
          //   font-size: 10px;
          // }

          // .remaining-total {
          //   font-size: 14px;
          //   font-weight: 700;
          //   text-transform: uppercase;
          // }
        }

        .item_product_detail:nth-child(1) {
          width: 100%;
          font-size: 12px;
          // margin: 10px 0px 0px;
          // padding: 0px 0px 0px 12px;
          // flex: 1 1 0%;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .chain-name {
            font-weight: 400;
            font-size: 12px;
            color: #777777;
            text-transform: capitalize;
          }
        }

        // .item_product_detail:nth-child(2) {
        //   width: auto;
        //   text-align: right;
        //   // margin: 10px 0px 0px;
        //   color: var(--color-title-chain);
        //   font-size: 12px;
        //   padding-left: 0px;
        //   text-transform: uppercase;
        // }
        .item_product_detail:nth-child(2) {
          padding: 8px 0 12px;
          display: flex;
          justify-content: center;
          .img-token {
            width: 234px;
            height: 234px;
            position: relative;
            // margin: 32px auto;
            img,
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              touch-action: none;
              border-radius: 8px;
            }
          }
        }
        .item_product_detail:nth-child(3) {
          padding-bottom: 8px;
          .wrapper-item {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            // margin: 0 12px;
            font-size: 18px;
            font-weight: 700;
            font-size: 12px;
            // min-height: 52px;
            .content-left {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              gap: 4px;
              color: var(--color-title-chain);

              .avatar {
                min-width: 24px;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  background-color: #ffffff;
                }
              }
            }
            .content-right {
              color: $purple-color-primary;
              font-size: 12px;
              text-transform: uppercase;
              white-space: nowrap;
            }
          }
        }
        .item_product_detail:nth-child(4) {
          // display: flex;
          // -webkit-box-pack: center;
          // justify-content: center;
          // text-align: center;
          .product_name {
            font-size: 16px;
            text-align: left;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 700;
            // margin: 12px;
          }
        }
        .price-and-remaining {
          position: absolute;
          background: rgba(27, 27, 53, 0.5);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 9px;
          gap: 8px;
          left: 8px;
          bottom: 8px;
          width: 218px;
          border-radius: 8px;
          opacity: 0.7;
          .wrapper-price {
            width: 50%;
          }
          .wrapper-remaining {
            width: 50%;
          }
        }
        .wrapper-price-type {
          position: absolute;
          background: rgba(27, 27, 53, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 8px;
          gap: 7px;
          left: 8px;
          bottom: 8px;
          width: 218px;
          border-radius: 8px;
          opacity: 0.7;
          .price-type-home {
            width: 90px;
            height: 22px;
            border-radius: 20px;
            font-size: 12px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.price-collection-view-page {
  width: 100%;
  max-width: $page-content-width;
  margin: 0px auto;
  padding: 20px 40px;

  .price-collection-box {
    display: flex;
    justify-content: space-around;
    border: var(--border-box-collection);
    padding: 40px 0;
    margin-bottom: 20px;
    border-radius: 24px;

    .token-showcase-box {
      position: relative;
      width: 500px;
      height: 500px;
      border-radius: 8px;

      // border-right: 1px solid $dark-color-primary;
      .bg-linear {
        position: absolute;
        inset: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(2, 2, 30, 0) 75%, #02021e 100%);
      }
      .zoom-image {
        // background-color: #2f8af5;
        border-radius: 16px;
        width: 40px;
        height: 40px;
        position: absolute;
        //
        bottom: 35px;
        left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          fill: #ffffff;
          width: 100%;
          height: 100%;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        // margin: auto;
        // padding-top: 50px;
        object-fit: contain;
        border-radius: 8px;
        // border: 1px solid;
      }
      video {
        display: block;
        width: 100%;
        max-height: 500px;
        margin: auto;
        border-radius: 8px;
        // padding-top: 50px;
      }
    }
    .straight-line {
      border-right: var(--border-box-card);
    }
    .token-details-box {
      text-align: center;
      width: 500px;
      margin: 0px 30px;
      .wrapper-head-token {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: var(--border-box-collection);
        padding-bottom: 10px;
        .box-owner-product {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: white;
          }
          .btn-avatar-owner-product {
            padding: 0;
          }
          .name-owner-product {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 5px;
            .creator-title {
              font-size: 12px;
            }
            .btn-name-owner-product {
              display: flex;
              font-weight: 700;
              padding: 0;
              font-size: 16px;
              text-align: left;
            }
          }
        }
      }

      .btn-buy-now {
        font-size: 12px;
        color: $purple-color-primary;
        opacity: 1;
        text-transform: uppercase;
        font-weight: 700;
      }

      .box-name-collection {
        margin: 15px 0px;

        .name-collection {
          font-size: 24px;
          text-transform: uppercase;
        }
      }
      .content-collection {
        color: #aaaaaa;
        font-size: 14px;
        white-space: pre-wrap;
      }

      .authenticity-button {
        font-size: 14px;
        color: #2f8af5;
        margin-bottom: 20px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
      }

      .box-price-collection {
        display: flex;
        flex-wrap: wrap;

        .box-price-detail-collection {
          -webkit-box-flex: 1;
          flex-grow: 1;
          width: 33px;
        }

        .box-price-detail-collection:not(:last-child) {
          border-right: var(--border-box-collection);
        }

        .lable-top {
          font-size: 14px;
        }

        .lable-bottom {
          font-size: 24px;
        }
      }

      .box-purchase-price {
        position: relative;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        height: 80px;
        border: var(--border-box-collection);
        border-radius: 8px;
        margin: 40px 0px;

        .lable-top {
          font-size: 12px;
          color: #2f8af5;
          margin-top: 6px;
        }

        .lable-bottom {
          font-size: 24px;
          text-transform: uppercase;
        }
      }

      .btn-sale-collection {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 18px;
        border-radius: 8px;
        display: block;
        width: 100%;
        padding: 20px;
        color: $white-color-primary;
        background: #2f8af5;
        &:disabled {
          // background: #565656;
          background: var(--bg-line-footer);
          color: var(--font-color-btn);
          opacity: 0.5;
        }
      }
    }

    .token-details-box-mobile {
      display: none;
    }

    .token-details-box {
      .airdrop-condition {
        margin-top: 30px;
        span {
          color: #f5222d;
        }
        li {
          list-style-type: none;
        }
      }
      //list nft
      .grid-list-nft {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        .grid-item-nft {
          height: 174px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 16px;
          align-items: center;
          .image-nft {
            width: 116px;
            height: 116px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: contain;
            }
          }
          .title-nft {
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
      .see-more {
        display: flex;
        color: #2f8af5;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
        justify-content: center;
        .icon-see-more {
          width: 16px;
          height: 16px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon-close-seemore {
          width: 16px;
          height: 16px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .token-details-box > div {
      margin-bottom: 24px;
    }
  }

  .title-sale-by-Collectors {
    text-align: center;
    font-size: 28px;
    letter-spacing: -1.2px;
    padding-top: 60px;
  }

  .sub-title-sale-by-Collectors {
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.6px;
    color: #444444;
  }

  .puzzle-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;

    .puzzle {
      max-width: 1100px;
    }
  }

  .userSales {
    position: relative;
    margin: 120px auto 60px;
    gap: 20px;
    display: block;
    margin-top: 80px;
    width: 100%;
    place-items: center;
    max-width: 1380px;
    grid-template-columns: repeat(5, 1fr);

    .filter-box {
      margin-bottom: 10px;

      .search-box {
        flex: 1 1 0%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin: 0px 0px 20px;
        height: calc($header-height - 5px);
        background: rgba($color: #ffffff, $alpha: 0.1);
        border-radius: 5px;
        position: relative;
        max-width: 510px;
        height: 44px;
        input {
          width: calc(100% - 60px);
          display: block;
          margin: 0px -15px 0px 0px;
          padding: 0px;
          height: 100%;
          color: #8c8c8c;
          background: transparent;
          border: 0px;
          overflow: visible;
          outline: none;
          font-size: 16px;
        }
      }

      .type-filter-box {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        padding-bottom: 30px;

        .type-filter-box-left {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-weight: 900;
          .type-filter-item {
            margin-right: 30px;
            padding: 0px;
            font-size: 20px;
          }

          .active {
            border-bottom: 3px solid #2f8af5;
          }
        }

        .type-filter-box-right {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
        }
      }
    }

    .marketplace-items {
      position: relative;
      width: 100%;
      gap: 20px;
      place-items: center;
      max-width: 1200px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .image-not-found {
          height: 210px;
          margin-top: 30px;
          width: 280px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .token-not-found {
          margin-top: 10px;
          font-size: 18px;
          text-align: center;
        }
      }
      .list-carousel {
        gap: 20px;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        flex-wrap: wrap;
      }

      .slide-item {
        flex: 0 0 auto;
        width: 282px;
        position: relative;
        border-radius: 8px;
        color: var(--font-color);

        .hot-ollectibles-wrapper {
          position: relative;
          padding: 16px 24px;
          .hot-ollectibles-item:nth-child(1) {
            width: 100%;
            font-size: 12px;
            // margin: 10px 0px 0px;
            // padding: 0px 0px 0px 12px;
            // flex: 1 1 0%;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .chain-name {
              font-weight: 400;
              font-size: 12px;
              color: #777777;
              text-transform: capitalize;
            }
          }
          // .hot-ollectibles-item:nth-child(2) {
          //   width: auto;
          //   text-align: right;
          //   // margin: 10px 12px 0 0;
          //   color: var(--color-title-chain);
          //   font-size: 12px;
          //   padding-left: 0px;
          //   text-transform: uppercase;
          // }
          .hot-ollectibles-item:nth-child(2) {
            padding: 8px 0 12px;
            display: flex;
            justify-content: center;
            width: fit-content;
            .img-token {
              width: 234px;
              height: 234px;
              position: relative;
              // margin: 32px auto;
              img,
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                touch-action: none;
                border-radius: 8px;
              }
            }
          }
          .hot-ollectibles-item:nth-child(3) {
            padding-bottom: 8px;
            .wrapper-item {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              justify-content: space-between;
              // margin: 0 12px;
              font-size: 18px;
              font-weight: 700;
              font-size: 12px;
              // min-height: 52px;
              .content-left {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                gap: 4px;
                color: var(--color-title-chain);

                .avatar {
                  min-width: 24px;
                  width: 24px;
                  height: 24px;
                  border-radius: 100%;
                  box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff;
                  }
                }
              }
              // .content-left::after {
              //   background-color: transparent;
              //   background-position: 50% center;
              //   background-repeat: no-repeat;
              //   background-size: cover;
              //   content: '';
              //   display: block;
              //   margin: 0px 0px 0.3125rem 0.2rem;
              //   width: 0.55rem;
              //   height: 0.55rem;
              //   background-image: url('../../../assets/img/verify-icon.png');
              // }
              .content-right {
                color: $purple-color-primary;
                font-size: 12px;
                // font-family: BrandonBlack, sans-serif;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }
          }
          .hot-ollectibles-item:nth-child(4) {
            // display: flex;
            // justify-content: center;
            // text-align: center;
            .product-name {
              font-size: 16px;
              text-align: left;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 700;
              // margin: 12px;
            }
          }
          .hot-items-editions {
            // position: absolute;
            // top: 30px;
            // left: 10px;
            border-radius: 100%;
            background-color: #777777;
            width: 26px;
            height: 26px;
            display: grid;
            place-items: center;
            .icon-eternal {
              width: 24px;
              border-radius: 100%;
            }
          }
          .price-and-remaining {
            position: absolute;
            background: rgba(27, 27, 53, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px;
            gap: 8px;
            left: 8px;
            bottom: 8px;
            width: 218px;
            border-radius: 8px;
            opacity: 0.7;
            .wrapper-price {
              width: 50%;
            }
            .wrapper-remaining {
              width: 50%;
            }
          }
          .wrapper-price-type {
            position: absolute;
            background: rgba(27, 27, 53, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 8px;
            gap: 7px;
            left: 8px;
            bottom: 8px;
            width: 218px;
            border-radius: 8px;
            opacity: 0.7;
            height: 57px;
            .price-type-home {
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1299px) {
  .box-collection {
    .marketplace {
      .marketplace-items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  .price-collection-view-page {
    .userSales {
      .marketplace-items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
@media (max-width: 1260px) {
  .price-collection-view-page {
    .price-collection-box {
      padding: 40px 20px;
    }
  }
}

@media (max-width: $page-content-width) {
  .back-button-wrapper {
    padding: 0 40px !important;
  }
}

@media (max-width: 999px) {
  .box-collection {
    .marketplace {
      .marketplace-items {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .price-collection-view-page {
    .userSales {
      .marketplace-items {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .price-collection-view-page {
    .price-collection-box {
      .token-showcase-box {
        position: relative;
        margin: 0 40px;
        img {
          // border: none;
          margin-left: 0;
        }
        video {
          border: none;
          margin-left: 0;
        }
      }

      .token-details-box > div {
        margin-bottom: 24px;
      }
    }
  }
}
@media (max-width: 900px) {
  .box-collection {
    .marketplace {
      .marketplace-items {
        margin: 0px;
        width: 100%;
        position: relative;
        display: grid;
        // gap: 20px;
        place-items: center;
        max-width: $page-content-width;
        // grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .price-collection-view-page {
    .userSales {
      .marketplace-items {
        // grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .price-collection-view-page {
    .price-collection-box {
      .token-showcase-box {
        position: relative;
        width: 100%;
        img {
          // border: none;
          // padding: 50px 20px 50px;
        }

        video {
          padding: 0;
          padding-bottom: 50px;
          transform: scale(0.8);
          width: 100%;
          max-height: 500px;
          height: auto;
        }
      }
    }

    .userSales {
      margin: auto;
      .filter-box {
        border-bottom: none;
      }

      .marketplace-items {
        position: relative;
        width: 100%;
        gap: 20px;
        place-items: center;
        max-width: 1200px;
        // grid-template-columns: repeat(1, 1fr);

        .list-carousel {
          gap: 20px;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          position: relative;
          flex-wrap: wrap;
        }
        .not-found {
          padding-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .box-collection {
    .collection-details-box {
      .collection-info {
        margin: 0;
        padding: 15px 0;
        .collection-info-left {
          display: flex;

          img {
            min-width: 100px;
            height: 100px;
            width: 100px;
          }
          .name {
            padding: 0px 10px;
            width: 170px;
          }
        }
        .collection-info-right {
          width: 100%;
          margin: 25px 0px;
        }
      }
    }
  }
  .price-collection-view-page {
    .price-collection-box {
      flex-direction: column;
      padding: 60px 20px 40px;
      .token-showcase-box {
        margin: auto;
        position: relative;
        width: 100%;
        .zoom-image {
          // right: 15px;
          // top: -5px;
          bottom: 60px;
          left: 75px;
        }
        img {
          // border: 1px solid;
          border-radius: 8px;
          -webkit-transform: unset;
          // padding-top: 0;
          // padding-bottom: 50px;
        }

        video {
          padding: 0;
          padding-bottom: 50px;
          transform: scale(0.8);
          width: 100%;
          height: auto;
        }
      }

      .token-details-box-mobile {
        text-align: center;
        width: 500px;
        margin: 0px 30px;
        .wrapper-head-token {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: var(--line-bottom-boxcard);
          padding-bottom: 16px;
          .box-owner-product {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            img {
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background-color: white;
            }
            .btn-avatar-owner-product {
              padding: 0;
            }
            .name-owner-product {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 5px;
              .creator-title {
                font-size: 10px;
                font-weight: 400;
              }
              .btn-name-owner-product {
                display: flex;
                font-weight: 700;
                padding: 0;
                font-size: 12px;
                text-align: left;
              }
            }
          }
        }

        .btn-buy-now {
          font-size: 12px;
          color: $purple-color-primary;
          opacity: 1;
          text-transform: uppercase;
          font-weight: 700;
        }

        .box-name-collection {
          margin: 15px 0px;

          .name-collection {
            font-size: 28px;
            text-transform: uppercase;
          }
        }
        .content-collection {
          color: #aaaaaa;
        }

        .authenticity-button {
          font-size: 14px;
          color: #2f8af5;
          margin-bottom: 20px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
        }

        .box-price-collection {
          display: flex;
          flex-wrap: wrap;

          .box-price-detail-collection {
            -webkit-box-flex: 1;
            flex-grow: 1;
            width: 33px;
          }

          .box-price-detail-collection:not(:last-child) {
            border-right: 1px solid #444444;
          }

          .lable-top {
            font-size: 14px;
          }

          .lable-bottom {
            font-size: 24px;
          }
        }

        .box-purchase-price {
          position: relative;
          display: flex;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          height: 120px;
          border: 1px solid $dark-color-primary;
          border-radius: 60px;
          margin: 40px 0px;

          .lable-top {
            font-size: 12px;
            color: #2f8af5;
            margin-top: 10px;
          }

          .lable-bottom {
            font-size: 24px;
            text-transform: uppercase;
          }
        }

        .btn-sale-collection {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 18px;
          border-radius: 16px;
          display: block;
          width: 100%;
          padding: 20px;
          color: $white-color-primary;
          background: #2f8af5;
        }
        .btn-sale-collection:disabled {
          background: #565656;
        }
      }

      .token-details-box-mobile {
        width: 100%;
        margin: 0 auto;
        display: block;
      }

      .token-details-box-mobile > div {
        // margin-bottom: 20px;
        padding: 0 0 10px 10px;
      }

      .token-details-box {
        width: 90%;
        margin: 0 auto;
        .wrapper-head-token {
          display: none;
        }
        .bottom-line {
          border-bottom: 1px solid #444444;
        }
        .box-purchase-price {
          .lable-top {
            margin-top: 4px;
          }
        }
        .box-name-collection {
          .name-collection {
            font-size: 20px;
          }
          .content-collection {
            font-size: 12px;
          }
        }
        .box-price-collection {
          .box-price-detail-collection {
            .lable-top {
              font-size: 12px;
            }

            .lable-bottom {
              font-size: 18px;
            }
          }
        }
      }

      .token-details-box > div {
        margin-bottom: 24px;
        .airdrop-condition {
          margin-top: 0;
        }
      }
    }

    .userSales {
      // margin: 0px 20px;
      // width: 90%;
      margin: auto;
      // grid-template-columns: repeat(1, 1fr);

      .filter-box {
        border-bottom: none;
      }

      .marketplace-items {
        position: relative;
        width: 100%;
        gap: 20px;
        place-items: center;
        max-width: 1200px;
        // grid-template-columns: repeat(1, 1fr);

        .list-carousel {
          gap: 20px;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          position: relative;
          flex-wrap: wrap;
        }
        .not-found {
          padding-bottom: 50px;
        }

        .slide-item {
          // width: 100%;

          .hot-ollectibles-wrapper {
            // width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 639px) {
  .box-collection {
    padding: 0 20px;
    .marketplace {
      padding-bottom: 30px;
      .marketplace-items {
        grid-template-columns: repeat(2, calc(100% / 2 - 10px)) !important;
        gap: 16px;
        a {
          width: 100%;
        }
        // .item_product {
        //   height: auto;
        //   width: 100%;
        //   .item_product_detail {
        //     .card-image {
        //       width: 80%;
        //       height: 250px;
        //       video {
        //         max-height: 250px;
        //       }
        //     }
        //     .product_name {
        //       white-space: unset;
        //     }
        //   }
        //   .item_product_detail:nth-child(3) {
        //     padding: 15px 12px 10px;
        //   }
        // }
        .item_product {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 8px 8px 12px 8px;
          .item_product_detail:nth-child(1) {
            font-size: 10px;
            .chain-name {
              font-size: 10px;
            }
          }
          // .item_product_detail:nth-child(2) {
          //   width: auto;
          //   text-align: right;
          //   color: var(--color-title-chain);
          //   font-size: 10px;
          //   text-transform: uppercase;
          // }
          .item_product_detail:nth-child(2) {
            padding: 4px 0 46px;
            width: 100%;
            .img-token {
              width: 100%;
              height: 146px;
              position: relative;
              img,
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                touch-action: none;
                border-radius: 8px;
              }
            }
          }
          .item_product_detail:nth-child(3) {
            padding-bottom: 0;
            .wrapper-item {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              justify-content: space-between;
              font-size: 12px;
              .content-left {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                gap: 4px;
                color: var(--color-title-chain);
                .avatar {
                  min-width: 18px;
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff;
                  }
                }
              }
              .content-right {
                color: $purple-color-primary;
                font-size: 10px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }
          }
          .item_product_detail:nth-child(4) {
            display: flex;
            justify-content: center;
            text-align: center;
            .product_name {
              font-size: 14px;
              text-align: left;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 700;
              // margin: 8px 10px;
              padding-top: 6px;
            }
          }
          .price-and-remaining {
            position: absolute;
            background: rgba(27, 27, 53, 0.5);
            backdrop-filter: blur(5px);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 4px;
            gap: 8px;
            left: 5%;
            bottom: -40px;
            width: 90%;
            height: 86px;
            border-radius: 8px;
            opacity: 1;
            flex-direction: column-reverse;
            .wrapper-price {
              width: 100%;
            }
            .wrapper-remaining {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .price-collection-view-page {
    padding: 10px 20px 0;
    .userSales {
      .marketplace-items {
        grid-template-columns: repeat(2, calc(100% / 2 - 10px)) !important;
        gap: 16px;
        a {
          width: 100%;
        }
        .slide-item {
          width: 100%;
          .hot-ollectibles-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 8px 8px 12px 8px;
            .hot-ollectibles-item:nth-child(1) {
              width: 100%;
              font-size: 10px;
              .chain-name {
                font-size: 10px;
              }
            }
            // .hot-ollectibles-item:nth-child(2) {
            //   width: auto;
            //   text-align: right;
            //   // margin: 10px 8px 0 0;
            //   color: var(--color-title-chain);
            //   // opacity: 0.8;
            //   font-size: 10px;
            //   // padding-left: 0px;
            //   text-transform: uppercase;
            // }
            .hot-ollectibles-item:nth-child(2) {
              padding: 4px 0 46px;
              width: 100%;
              .img-token {
                width: 100%;
                height: 146px;
                position: relative;
                // margin: 24px auto 14px;
                img,
                video {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  touch-action: none;
                  border-radius: 8px;
                }
              }
            }
            .hot-ollectibles-item:nth-child(3) {
              padding-bottom: 0;
              .wrapper-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                // margin: 0 10px;
                // font-weight: 700;
                font-size: 12px;
                .content-left {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  gap: 4px;
                  color: var(--color-title-chain);
                  .avatar {
                    min-width: 18px;
                    width: 18px;
                    height: 18px;
                    border-radius: 100%;
                    box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      background-color: #ffffff;
                    }
                  }
                }
                .content-right {
                  color: $purple-color-primary;
                  font-size: 10px;
                  text-transform: uppercase;
                  white-space: nowrap;
                }
              }
            }
            .hot-ollectibles-item:nth-child(4) {
              display: flex;
              justify-content: center;
              text-align: center;
              .product-name {
                font-size: 14px;
                text-align: left;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 700;
                // margin: 8px 10px;
                padding-top: 6px;
              }
            }
            .hot-items-editions {
              // position: absolute;
              // top: 28px;
              // left: 10px;
              border-radius: 100%;
              background-color: #777777;
              width: 14px;
              height: 14px;
              display: grid;
              place-items: center;
              .icon-eternal {
                width: 12px;
                border-radius: 100%;
              }
            }
            .price-and-remaining {
              position: absolute;
              background: rgba(27, 27, 53, 0.5);
              backdrop-filter: blur(5px);
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 8px 4px;
              gap: 8px;
              left: 5%;
              bottom: -40px;
              width: 90%;
              height: 86px;
              border-radius: 8px;
              opacity: 1;
              flex-direction: column-reverse;
              .wrapper-price {
                width: 100%;
              }
              .wrapper-remaining {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .price-collection-box {
      .token-details-box {
        .grid-list-nft {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .back-button-wrapper {
    padding: 0 20px !important;
  }
  .collection-container {
    .back-button-wrapper {
      .back-button {
        width: 64px;
        height: 29px;
        font-size: 12px;
        padding: 0 6px;
      }
    }
  }
  .box-collection {
    .collection-details-box {
      .collection-info-content {
        font-size: 12px;
      }
      .collection-info {
        .collection-info-left {
          .name {
            .fullname {
              font-size: 20px;
            }
          }
        }
      }
    }
    .collection-container {
      .collection-banner-image {
        height: 220px;
      }
    }
    .marketplace {
      .marketplace-collection-tittle {
        margin: 32px 0 16px;
      }
      .marketplace-items {
        margin: 0px;
        width: 100%;
        position: relative;
        display: grid;
        // gap: 20px;
        place-items: center;
        max-width: $page-content-width;
      }
    }
  }
  .price-collection-view-page {
    // padding: 10px 20px 0;
    .price-collection-box {
      padding: 16px 16px 24px;
      // border: unset;
      .token-showcase-box {
        height: 302px;
        width: 302px;
        img {
          // padding: 20px 0 50px;
          border-radius: 8px;
          // border: 1px solid;
        }
        video {
          padding-top: 10px;
          padding-bottom: 15px;
        }
        .zoom-image {
          display: none;
        }
      }
      .token-details-box {
        width: 100%;
        .box-owner-product {
          .btn-avatar-owner-product {
            img {
              width: 45px;
              height: 45px;
            }
          }
          .name-owner-product {
            .btn-name-owner-product {
              padding: 10px 0;
              font-size: 14px;
              text-transform: capitalize;
            }
          }
        }
        .btn-buy-now {
          font-weight: 900;
        }
        .btn-sale-collection {
          font-size: 12px;
          height: 40px;
          padding: 10px;
        }
        .box-purchase-price {
          margin: 24px 0;
        }
      }
    }
    .title-sale-by-Collectors {
      padding: 20px 20px 0;
      font-size: 26px;
    }
    .sub-title-sale-by-Collectors {
      padding: 0 20px 20px 20px;
      font-size: 14px;
    }
    .userSales {
      .filter-box {
        .type-filter-box {
          .type-filter-box-left {
            .type-filter-item {
              margin-right: 25px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 414px) {
  .price-collection-view-page {
    .price-collection-box {
      .token-details-box {
        .box-name-collection {
          .name-collection {
            font-size: 20px;
          }
        }
        .content-collection {
          font-size: 12px;
        }
        .box-price-collection {
          .box-price-detail-collection {
            .lable-bottom {
              font-size: 18px;
            }
          }
        }
      }
    }
    .title-sale-by-Collectors {
      padding: 20px 20px 0;
      font-size: 26px;
    }
    .sub-title-sale-by-Collectors {
      padding: 0 20px 20px 20px;
      font-size: 14px;
    }
    .userSales {
      .filter-box {
        .type-filter-box {
          .type-filter-box-left {
            .type-filter-item {
              margin-right: 25px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 410px) {
  .box-collection {
    .collection-details-box {
      .collection-info {
        .collection-info-left {
          display: flex;
          img {
            height: 100px;
            width: 100px;
            // margin-left: 40px;
          }
          .name {
            padding: 0px 10px;
            width: 170px;
          }
        }
      }
    }
  }
  .price-collection-view-page {
    .token-details-box {
      .box-price-collection {
        .box-price-detail-collection {
          .lable-top {
            // font-size: 12px;
          }
          .lable-bottom {
            // font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 390px) {
  .box-collection {
    .collection-details-box {
      .collection-info {
        .collection-info-left {
          display: flex;
          img {
            height: 100px;
            width: 100px;
          }
          .name {
            padding: 0px 10px;
            width: 170px;
            .fullname {
              font-size: 18px;
            }
          }
        }
        .collection-info-right {
          width: 100%;
          .collection-info-right-details {
            padding: 0;
          }
          // .line-icon {
          //   padding: 0;
          //   width: 100%;
          //   height: 1px;
          //   background: rgba(255, 255, 255, 0.25);
          //   border: unset;
          // }
        }
      }
    }

    .marketplace {
      width: 100%;
    }
  }
}
@media (max-width: 360px) {
  .box-collection {
    .collection-details-box {
      .collection-info {
        .collection-info-left {
          img {
            min-width: 80px;
            height: 80px;
            width: 80px;
            // margin-top: 0;
          }

          .name {
            padding: 0px;
            width: 150px;
            margin-top: 0;
            .username {
              font-size: 16px;
            }
          }
        }

        .collection-info-right {
          width: 100%;
          .collection-info-right-details {
            padding: 0;
          }
          // .line-icon {
          //   width: 100%;
          //   height: 1px;
          //   background: rgba(255, 255, 255, 0.25);
          //   border: unset;
          // }
        }
      }
    }

    .marketplace {
      width: 100%;
    }
  }
  .price-collection-view-page {
    .price-collection-box {
      .token-details-box {
        .box-price-collection {
          .box-price-detail-collection {
            .lable-bottom {
              // font-size: 18px;
            }
          }
        }
        .grid-list-nft {
          .grid-item-nft {
            .image-nft {
              width: 100px;
              height: 100px;
            }
          }
        }
      }
      .token-details-box-mobile {
        .wrapper-head-token {
          .box-owner-product {
            .name-owner-product {
              .btn-name-owner-product {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .price-collection-view-page {
    .price-collection-box {
      .token-details-box {
        .btn-sale-collection {
          font-size: 18px;
        }
      }
    }
  }
}
