.purchase-container {
  padding: 110px 40px 50px;
  margin: 0px auto;
  max-width: 100vw;
  width: min(100%, $page-content-width);
  display: flex;
  justify-content: space-between;

  .purchase-history-page {
    // width: 1200px;
    width: 894px;
    max-width: 100%;

    // margin: 0 auto;
    // padding-bottom: 50px;
    .search {
      display: flex;
      padding: 10px 24px;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: var(--bg-search-input);
      //box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
      justify-content: space-between;

      .search-icon {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
      }

      .search-input {
        width: calc(100% - 60px);
        display: block;
        margin: 0px -15px 0px 0px;
        padding: 0px;
        height: 100%;
        color: var(--color-search-ipnut);
        background-color: transparent;
        border: 0px;
        overflow: visible;
        outline: none;
        // font-size: 16px;
        font-weight: 400;

        &::placeholder {
          color: var(--color-search-ipnut);
        }
      }
    }

    .purchase-history-header {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      // padding-top: 80px;
      // padding-bottom: 25px;
      // border-bottom: var(--border-box-collection);
      font-size: 18px;
      font-weight: 700;
      color: var(--font-color-08);
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding-left: 20px;
    }

    .purchase-history-body {
      display: flex;
      flex-direction: column;
      overflow: auto;
      border-radius: 8px;
    }

    .purchase-history-content {
      display: table;
      flex-direction: column;
      font-size: 14px;
      width: 100%;
      min-width: 894px;
      // max-width: 1200px;
      // min-width: 1200px;
      padding: 0 16px;
      margin: 0px auto;
      line-height: 1;
      overflow: auto;
      background: var(--background-box-wallet);

      // border-radius: 8px;
      // margin-bottom: 8px;
      .table-row {
        display: table-row;
        // padding: 5px 0px;
        width: 100%;

        .value {
          // border-bottom: 1px solid white;
          display: table-cell;
          box-sizing: border-box;
          // padding: 15px 15px 0px 0px;
          // padding: 14px 0;
          padding: 8px 20px 8px 0;
          vertical-align: middle;
        }

        .purchase_date {
          width: 110px;
          font-weight: 400;

          .column-date {
            display: flex;
            justify-content: flex-start;

            .arrow-date {
              width: 24px;
              height: 24px;
              display: inline-block;
              margin-left: 7px;
              margin-top: -5px;
              transform: rotateX(0deg);
              transform-style: preserve-3d;
              transition: transform 0.5s ease-out 0s;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .asc {
              transform: rotateX(180deg);
            }
          }
        }

        .payment_type {
          width: 90px;
        }

        // .name {
        //   width: 200px;
        // }
        .blockchain {
          width: 100px;
        }

        .amount {
          width: 120px;
          font-weight: 700;
        }

        .explorer_url {
          width: 100px;
          cursor: pointer;
          padding: 18px 8px 18px 0 !important;

          @media (max-width: 768px) {
            width: 80px;
            padding: 18px 0px 18px 0 !important;
          }
        }

        .explorer_url-header {
          width: 100px;
          padding: 18px 8px 18px 0;

          @media (max-width: 768px) {
            width: 80px;
          }
        }

        .name {
          padding: auto;
          cursor: pointer;
        }

        .name-header {
          padding: auto;
        }

        .nft {

          // padding: 13px 15px 20px 0px;
          // width: 200px;
          .campaign_name {
            display: block;
            // margin-bottom: 3px;
            color: rgb(155, 154, 154);

            &:hover {
              color: var(--font-color);
              cursor: pointer;
            }
          }

          .name {
            line-height: 19px;
            font-weight: 700;
          }

          >a {
            color: inherit;
            font: inherit;
            text-decoration: none;
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }

        .icon {
          width: 25px;
          padding-right: 0px;
          text-align: right;
          cursor: pointer;

          .arrow-up-right {
            width: 20px;

            img {
              width: 80%;
              margin-bottom: -3px;
            }
          }
        }

        .icon-header {
          width: 25px;
          padding-right: 0px;
          text-align: right;

          .arrow-up-right {
            width: 20px;

            img {
              width: 80%;
              margin-bottom: -3px;
            }
          }
        }
      }

      .heading-row {
        display: table-row;

        .title {
          //cursor: pointer;
          color: var(--font-color-08);
          padding: 16px 0px 8px;
          display: table-cell;
          box-sizing: border-box;
          vertical-align: top;
          font-weight: 500;
        }
      }
    }

    .custom-place-holder::placeholder {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }

    .purchase-history-content:first-child {
      // background: unset;
      // border-radius: unset;
      color: var(--font-color-08);
      border-bottom: 1px solid #35354b;
      font-size: 14px;
    }

    .purchase-history-content:nth-child(2) {
      padding-top: 8px;
    }

    .purchase-history-content:last-child {
      padding-bottom: 16px;
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 40px;

    .purchase-history-page {
      width: 100%;
    }
  }
}

.purchase-detail-container {
  position: relative;
  // z-index: 15;
  max-width: 100vw;
  width: 100%;
  padding: 50px 0;

  .wrapper-purchase-detail {
    max-width: $page-content-width;
    width: 100%;
    margin: auto;
    padding: 0 40px;

    .back-purchase-list {
      z-index: 10;
      height: 40px;
      width: 100px;
      padding: 0px 15px;
      border-radius: 8px;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: var(--font-color-title);
      background: var(--background-box-wallet);
      border: none;
      font-weight: 600;

      >svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }

    .collectible {
      max-width: 788px;
      margin: auto;

      .collectible-image-container {
        height: 300px;
        // min-height: 430px;
        width: 300px;
        margin: auto;
        margin-top: 32px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .campaignBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        transition: all 0.3s ease-in-out;
        padding: 33px 0 24px;
        gap: 4px;

        >img {
          height: 30;
          border-radius: 50%;
          margin-right: 5px;
        }

        .campaign_name {
          margin-right: 4px;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .line-purchase {
        max-width: 300px;
        width: 100%;
        margin: auto;
      }

      h1 {
        font-weight: 900;
        text-align: center;
        margin: 24px 0;
        font-size: 24px;
      }

      .collectibleDescription {
        font-size: 0.9rem;
        margin-left: 2.75rem;
        margin-right: 2.75rem;

        .description_text {
          text-align: center;
          color: var(--color-content-featured);
        }

        >button {
          background: transparent;
          border: none;
          margin: 24px auto;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;

          span {
            font-size: 12px;
            color: #2f8af5;
            font-weight: 700;
          }
        }
      }

      .current_owner {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;
        margin-bottom: 25px;
        font-weight: 700;
        font-size: 12px;

        img {
          border-radius: 50%;
        }

        .current_owner_title {
          font-weight: 400;
        }
      }

      .infoContainer {
        .collectible-info-boxes {
          margin-bottom: 24px;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          align-items: stretch;

          .info {
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: var(--background-box-wallet);
            border-radius: 10px;
            width: 148px;
            height: 64px;
            align-items: center;
            gap: 10px;

            .title {
              font-size: 12px;
              text-transform: uppercase;
              text-align: center;
              margin: 0 0 0 0;
              color: $purple-color-primary;
            }

            .value {
              font-weight: 700;
              font-size: 14px;
              text-align: center;
              margin: 0 0 0 0;
            }
          }

          :last-child {
            padding-right: 0px;
            border-right: none;
          }
        }
      }

      .marketPlaceBtn {
        margin-top: 15px;

        a {
          text-decoration: none;
        }

        .marketplace-container {
          height: 59px;
          width: 314px;
          box-sizing: border-box;
          border: none;
          border-radius: 11px;
          outline: none;
          color: white;
          padding: 0 20px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #2f8af5;

          .marketplace-icon {
            height: 30px;
            margin-right: 10px;
            border-style: none;
          }

          .marketplace-text {
            font-weight: 900;
          }
        }
      }
    }
  }
}

@include Tablet_Mobile() {
  .purchase-container {
    .purchase-history-page {
      width: 100%;

      .purchase-history-content {
        font-size: 12px;
      }
    }

    .purchase-history-header {
      // padding: 20px 0 15px 10px;
      // font-size: 28px;
    }

    // .purchase-history-body {
    //   padding: 0 25px;
    // }
  }
}

@include Tablet_Mobile() {
  .purchase-detail-container {
    .collectible {
      .infoContainer {
        .collectible-info-boxes {
          flex-wrap: wrap;
          justify-content: center;

          .info {
            padding: 0 5px;
          }
        }
      }
    }

    .back-purchase-list {
      top: 85px;
      left: -5px;
      z-index: 10;
      height: 40px;
    }
  }
}

@media (max-width: 900px) {
  .purchase-detail-container {
    .wrapper-purchase-detail {
      .collectible .infoContainer .collectible-info-boxes {
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }
    }
  }
}

@include Tablet() {
  .purchase-detail-container {
    .wrapper-purchase-detail {

      // padding: 0 20px;
      .collectible .infoContainer .collectible-info-boxes {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
      }
    }
  }
}

@media (max-width: 540px) {
  .purchase-container {
    padding: 110px 20px 50px;
  }

  .purchase-detail-container {
    .wrapper-purchase-detail {
      padding: 0 20px;

      .collectible {
        .infoContainer {
          .collectible-info-boxes {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            // .info {
            // 	padding: 0 5px;
            // }
          }
        }

        .marketPlaceBtn {
          .marketplace-container {
            width: 100%;
          }
        }
      }
    }
  }
}

.custom-placeholder::placeholder {
  font-size: 16px;
  opacity: 0.5;
  color: var(--font-color);
}

.react-tooltip {
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: var(--bg-color-tooltip) !important;
}