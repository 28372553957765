/* import font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.main-app {
    text-align: center;
    margin: 100px 50px 0 50px;
    font-family: 'Poppins';
}

.cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    cursor: pointer;
    box-shadow: 0 10px 20px 0 rgb(63 93 152 / 30%);
}
.apply-button {
    font-family: 'Spoqa Han Sans Neo';
    background: #0d3290;
}
.apply-button:disabled {
    font-family: 'Spoqa Han Sans Neo';
    background: rgb(202,202,202);
}
.apply-button-disabled {
    font-family: 'Spoqa Han Sans Neo';
    background: rgb(114, 147, 234);
}

.connect-wallet-button {
    background: #0d3290;
}

.connect-wallet-button:disabled {
    background: rgb(114, 147, 234);
}

.claim-button {
    background: linear-gradient(93deg,black,#0d3290);
}

.claim-button:disabled {
    background: rgb(114, 147, 234);
}

.receive-toggle-div {
    font-size: 15px;
    cursor: pointer;
}

.input-box {
    border-radius: 12px;
    width: 200px;
    height: 30px;
    font-size: 15px;
    margin: 10px 5px 15px 5px;
    padding: 0 15px;
}
.claim-input {
    border: white;
}

.claim-talk-container {
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 4px;
}

.apply-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Spoqa Han Sans Neo';
    margin-top: 35px;
    color: white;
}

.apply-container > h1 {
    font-size: 23px;
}

.apply-text-div {
    text-align: left;
    font-size: 15px;
}

.apply-text-div > ul {
    margin-left: -20px;
}

.apply-text-div > ul > li {
    margin-bottom: 15px;
}

.agree-text-wrap {
    display: flex;
    justify-content: center;
}

.agree-text-span {
    font-size: 13px;
}

.agree-text-wrap > span > img {
    width: 18px;
    height: 18px;
    margin: 5px 5px -3px 5px;
}

.content-box {
    border-radius: 12px;
    background: linear-gradient(93deg,#2161e2,#b07cfe);
    box-shadow: 10px 14px 5px 0 rgb(228 230 235 / 27%);
    padding: 40px;
    padding-top: 120px;
}

.content-box > h1 {
    font-weight: 700;
}

.button-div {
    margin: 10px;
}
.button-center {
    display: flex;
    justify-content: center;
}
.text-light {
    color: #000 !important;
}
.text-dark {
    color: #fff !important;
}
.logo-image {
    width: 60px;
    height: 60px;
    position: relative;
    top: 5px;
    margin-bottom: 20px;

}

.logo-box {
    background: white;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 70px;
    border-radius: 50%;
}

.available-text-div {
    font-weight: 500;
    font-size: 20px;   
}

.top-menu-wrapper {
    display: flex;
    justify-content: center;
}

.top-menu {
    display: flex;
    position: absolute;
    top: 10px;
    padding: 5px;
    width: 170px;
    justify-content: center;
    align-items: center;
    background-color: rebeccapurple;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
    border-radius: 10px;
}

.menu-button-active {
    background: linear-gradient(93deg,#2161e2,#b07cfe);
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
    border-radius: 10px;
    color: white;
    padding: 5px;
    width: 100%;
}

.menu-button-inactive {
    /* color: black; */
    padding: 5px;
    width: 100%;
}

.apply-button-div {
    margin-top: 30px;
}

.apply-balance-div {
    margin: 30px 0 30px 0;
    text-align: center;
    font-weight: 700;
}

.apply-date-notice-div {
    font-size: 15px;
    margin: 10px 0;
    font-family: 'Spoqa Han Sans Neo';
}

.apply-header {
    font-size: 15px;
    font-weight: 700;
    margin: 15px 0 5px 0;
}

.reward-buttons-div {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
}

.reward-button {
    display: flex;
    justify-content: space-between;
    grid-gap: 8px;
    align-items: center;
}

/* reward new  */
.reward-page {
    padding: 5px 20px 50px;
    font-family: 'Roboto';
    background: #f9faff;
    min-height: 100vh;
}
.title-claim-rewards {
    font-weight: 700;
    font-size: 20px;
    color: #2664e6;
    font-family: 'Poppins';
}
.banner-reward {
    background-image: url('./banner_reward.png');
    margin: 28px 0 24px;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 138px;
    border-radius: 12px;
    align-items: center;
}
.banner-reward img {
    width: 134px;
    height: 134px;
}
.text-banner-reward {
    height: 100%;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    text-shadow: 0px 0px 8px rgba(106, 201, 255, 0.5);
    padding: 28px 14px;
    flex: 1 1 0%;
}
.title-rewards {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.uid-text {
    font-size: 14px;
    font-weight: 500;
}
.wrapper-question {
    font-family: 'Roboto';
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin: 20px 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.content-wrapper-question {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
}
.content-wrapper-question .ic_question {
    width: 40px;
    height: 40px;
}
.content-wrapper-question .ic_question  img {
    width: 100%;
}
.content-right-question {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1 100%;
}
.content-right-question .title-question {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
.text-mission {
    font-weight: 400;
    font-size: 13px;
    color: #8c8c8c;
}
.content-question-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
}
.date-claim-start {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #f0f5ff;
    border-radius: 8px;
}
.counter-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.title-counter-quest {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
.time-counter-dhm {
    color: #000;
    margin-top: 3px;
    font-size: 10px;
}
.time-counter-quest {
    display: flex;
    align-items: center;
    gap: 2px;
}
.time-counter-quest div {
    display: grid;
    place-items: center;
    min-width: 28px;
    height: 28px;
    background: #2664e6;
    color: white;
    padding: 2px;
    border-radius: 8px;
    font-size: 13px;
}
.time-counter-quest span {
    color: #000;
}
.text-claim-start {
    font-weight: 700;
    font-size: 13px;
    color: #2f8af5;
}
.button-claim {
    background: #2664e6;
    width: 100%;
    height: 44px;
    border-radius: 10px;
    text-transform: uppercase;
}
.wrapper-question .btn:disabled {
    background: #2664e6;
    opacity: 0.5;
}
@media (max-width: 410px) {
    .content-question-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: start;
        gap: 6px;
    }
}