.rewards {
  padding: 110px 40px 50px;
  margin: 0px auto;
  max-width: 100vw;
  width: min(100%, $page-content-width);
  display: flex;
  justify-content: space-between;
  color: var(--font-color);

  .content-rewards {
    width: 894px;

    .title-rewards {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      margin: 0 auto;
      max-width: 100%;
      color: var(--font-color-08);
      height: 56px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding-left: 20px;
      font-family: Roboto;
    }

    .wrapper-content-rewards-top {
      margin-top: 24px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .wrapper-content-rewards {
      margin-top: 24px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding: 32px 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .grid-col-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .content-rewards {
        .button-edit {
          width: 100px;
          height: 38px;
          background: var(--bg-button-profile);
          color: white;
          border-radius: 8px;
          font-size: 12px;
          margin-bottom: auto;
          margin-top: auto;
          min-height: 40px;
        }

        .table {
          --bs-table-bg: unset !important;
          --bs-table-color: var(--font-color) !important;
          font-size: 14px;
          white-space: nowrap;

          tr {
            border-style: unset !important;
          }

          thead {
            border-color: #2c2c2c;
          }

          tr td {
            border: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .box-reward {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: #32324b;
          border: 1px solid #32324b;
          justify-content: center;
          cursor: pointer;
          height: 76px;

          &-clicked {
            border-color: #528cfb;
            background: #3c3c64;

            .title-box {
              color: #528cfb;
            }
          }

          .title-box {
            color: #888;

            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16.41px;

            &-clicked {
              color: #528cfb;
            }
          }

          .value-box {
            color: #d9d9d9;

            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 40px;

    .content-rewards {
      width: 100%;

      .wrapper-content-rewards {
        gap: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 110px 20px 50px;

    .content-rewards {
      .wrapper-content-rewards {
        padding: 24px 16px;

        .content-rewards {
          .box-reward {
            padding: 16px 8px;
            white-space: inherit;
            height: auto;

            .title-box {
              font-size: 10px;
              line-height: 11.72px;
            }
          }
        }
      }
    }
  }
}