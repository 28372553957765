//Connect Page
:root {
  .connect-page {
    background-image: url('../../../assets/img/bg_connect_page_light.png');

    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_lightmb.png');
    }
  }

  .kakao-page {
    background-image: url('../../../assets/img/bg_connect_page_light.png');

    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_lightmb.png');
    }
  }
}

[data-theme='dark'] {
  .connect-page {
    background-image: url('../../../assets/img/bg_connect_page.png');

    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_mb.png');
    }
  }

  .kakao-page {
    background-image: url('../../../assets/img/bg_connect_page.png');

    @media (max-width: 540px) {
      background-image: url('../../../assets/img/bg_connect_page_mb.png');
    }
  }
}

.walletconnect-modal__header {
  color: #1f1f1f;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.walletconnect-modal__mobile__toggle {
  color: #1f1f1f;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.walletconnect-modal__footer {
  color: rgba(60, 66, 82, 0.6);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.connect-page {
  width: 100%;
  margin: 0 auto;
  height: 115vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% center;

  .logo-talken {
    padding: 100px 0 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      width: 146px;
      height: 37px;
    }
  }

  .form-connect {
    width: min(560px, 100%);
    background: var(--background-modal);
    box-shadow: 0px 4px 49px rgba(20, 161, 255, 0.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-weight: 700;
    font-size: 24px;
    flex-direction: column;
    // height: 100%;
    max-height: 646px;

    //height: 71vh;
    .close-button-modal {
      top: 23px;
    }

    .line {
      display: initial;
      background-color: #4f4f7d;
    }

    .title-head {
      color: var(--font-color-08);
      padding: 24px 0 18px 0;
    }

    .line-connect {
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .wrapper-content-connect {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      width: 100%;
      height: -webkit-fill-available;

      .title-content {
        color: var(--font-color-08);
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 16.286px;
      }

      .list-sns-connect {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // height: 94px;
        padding: 10px 114px;
        // background: var(--bg-box-sns);
        border-radius: 10px;
        align-items: center;

        .border-icon-sns {
          width: 56px;
          height: 56px;
          background: #ffffff;
          border-radius: 30px;
          display: grid;
          place-items: center;

          .icon-sns {
            width: 30px;
            height: 30px;
            cursor: pointer;

            img {
              width: 100%;
            }
          }
        }
      }

      .line-or {
        width: 100%;
        display: flex;
        padding: 10px 0 6px;

        &::before {
          content: '';
          border-bottom: 1px dashed var(--color-or-02);
          flex: 1 0 auto;
          height: 10px;
          margin: 0;
        }

        .title-line-or {
          text-align: center;
          flex: 0.3 0 auto;
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 16.286px;
          color: var(--font-color-08);
        }

        &::after {
          content: '';
          border-bottom: 1px dashed var(--color-or-02);
          flex: 1 0 auto;
          height: 10px;
          margin: 0;
        }
      }

      .list-mainnet {
        height: 86px;
        width: 100%;
        padding: 0 20px;
        background: var(--background-box-wallet);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box-mainnet {
          width: 54px;
          height: 54px;
          background: var(--bg-box-mainnet);
          border-radius: 4px;
          cursor: pointer;
          display: grid;
          place-items: center;

          .icon-mainnet {
            width: 36px;
            height: 36px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:hover {
            // background: var(--hover-box-mainner);
          }
        }

        .activate {
          background: #2f8af5;
        }
      }

      .wallet-container {
        // background: var(--background-box-wallet);
        border-radius: 10px;
        // overflow: hidden;
        // height: 31vh;
        //height: inherit;
        width: 100%;
      }

      .list-wallet {
        //background: var(--background-box-wallet);
        //border-radius: 10px;
        flex-wrap: wrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: initial;
        gap: 8px;
        //padding: 16px;
        // height: min(60px, 100%);
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .box-item-wallet {
          // min-width: 135px;
          //width: 50%;
          // width: calc(50% - 8px);
          background: #39396c;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          gap: 8px;
          padding: 10px 20px;
          align-items: center;
          cursor: pointer;
          height: 60px;
          max-height: 100%;

          // min-height: 60px;
          .name-wallet {
            color: #ffffff;
            font-weight: 400;
            font-size: 14px;
          }

          .icon-wallet {
            width: 30px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          // &:nth-child(1) {
          //   margin-left: 16px;
          // }
          // &:nth-child(2) {
          //   margin-right: 16px;
          // }
          &:hover {
            border-radius: 8px;
            border: 1px solid #2f8af5;
            box-shadow: 0px 0px 5px 0px #ffffff80;
          }
        }
      }

      .list-wallet-clone {
        @extend .list-wallet;

        &.variant-1 {
          flex-direction: column;
          flex-wrap: nowrap;

          .box-item-wallet {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    .logo-talken {
      padding: 40px 0;

      img {
        width: 93px;
        height: 28px;
      }
    }

    .form-connect {
      max-width: 338px;
      max-height: 526px;
      flex-shrink: 0;
      border-radius: 12px;
      font-size: 16px;

      .close-button-modal {
        top: 33px;
      }

      .title-head {
        font-size: 16px;
        padding: 29px 0 24px 0;
      }

      .wrapper-content-connect {
        gap: 16px;

        .title-content {
          font-size: 12px;
          line-height: 9.548px;
          font-weight: 400;
        }

        .line-or {
          font-size: 12px;
          line-height: 16.286px;

          .title-line-or {
            font-size: 12px;
            font-weight: 400;
          }
        }

        .list-sns-connect {
          padding: 8px 32px;
          max-width: 298px;
          max-height: 59px;

          .border-icon-sns {
            width: 41px;
            height: 41px;

            .icon-sns {
              width: 22.58px;
              height: 22.58px;
            }
          }
        }

        .list-wallet {
          // max-width: 299px;
          // max-height: 63px;
          // padding: 10px 28px;
          // grid-template-columns: repeat(1, 1fr);

          .box-item-wallet {
            padding: 10px 12px;
            height: 40px;

            .name-wallet {
              color: #ffffff;
              font-weight: 400;
              font-size: 12px;
            }

            .icon-wallet {
              width: 20px;
              height: 20px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
