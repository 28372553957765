.profile-container {
  // padding: 15px 0 50px;
  margin: 0px auto;
  max-width: 100vw;
  .my-profile-page {
    // max-width: $page-content-width;
    padding: 110px 40px 50px;
    width: 894px;
    margin: auto;
    .my-profile-header {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
      // width: $page-content-width;
      margin: 0 auto;
      max-width: 100%;
      // padding: 65px 0 20px;
      // border-bottom: var(--border-box-collection);
      color: var(--font-color);
      height: 56px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding-left: 20px;
    }
    .my-profile-detail {
      display: flex;
      margin: 0 auto;
      width: 100%;
      padding-top: 24px;
      .my-profile-leftdetail {
        display: flex;
        flex-direction: column;
        flex: 4 1;
        .my-profile-detail-header {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          gap: 82px;
          .wrapper-profile {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            .avatar {
              height: 110px;
              width: 110px;
              border-radius: 100%;
              border: 2px solid white;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
            .my-profile-name {
              // padding: 0 30px;
              // font-weight: 700;
              .fullname {
                font-size: 18px;
                color: #2f8af5;
              }
              .username {
                font-size: 24px;
                font-weight: 700;
                overflow: hidden;
                position: relative;
                mix-blend-mode: lighten;
                display: inline-block;
              }
              .username::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                mix-blend-mode: multiply;
              }
            }
          }

          .myProfileSocials {
            display: flex;
            justify-content: space-around;
            border: var(--border-box-collection);
            border-radius: 8px;
            height: 80px;
            max-width: 356px;
            .Socialsdetail-collections {
              padding: 16px 24px;
              text-align: center;
              display: flex;
              flex-direction: column;
              // border-right: 1px solid #202020;
              position: relative;

              .value {
                font-size: 20px;
                // margin-bottom: 5px;
              }
              .label {
                color: #2f8af5;
                font-size: 13px;
              }
              &::after {
                content: '';
                height: 60%;
                width: 1px;
                position: absolute;
                right: 0;
                top: 15px;
                background-color: var(--color-line);
              }
            }
            :last-child {
              position: unset;
            }
            .Socialsdetail {
              padding: 16px 24px;
              text-align: center;
              display: flex;
              flex-direction: column;
              position: relative;

              .value {
                font-size: 20px;
                // margin-bottom: 5px;
              }
              .label {
                color: #2f8af5;
                font-size: 13px;
              }
              &::after {
                content: '';
                height: 60%;
                width: 1px;
                position: absolute;
                right: 0;
                top: 15px;
                // background-color: var(--color-line);
              }
            }
            :last-child {
              position: unset;
            }
          }
        }
        .bio {
          font-size: 18px;
          margin-bottom: 30px;
        }
        .my-profile-contact {
          display: grid;
          // grid-template-columns: repeat(4, 1fr);
          gap: 20px;
          max-width: 805px;
          .contact-detail {
            // padding-right: 40px;
            font-weight: 400;
            .title {
              font-size: 14px;
              margin-bottom: 12px;
              width: max-content;
              color: #2f8af5;
              button {
                display: inline-block;
                width: 110px;
                height: 35px;
                line-height: 19px;
                background-color: #2f8af5;
                margin-left: 20px;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;
                color: #fff;
                border: none;
                font-weight: 700;
              }
            }
            .content {
              font-size: 16px;
              width: max-content;
              font-weight: 700;
            }
          }
        }
      }
      .my-profile-rightdetail {
        // margin-left: 30px;
        .my-profile-rightdetail-item {
          margin-bottom: 20px;
          min-width: 220px;
          font-weight: 700;
          .item-label {
            font-size: 16px;
            margin-bottom: 8px;
            img {
              margin-right: 8px;
              vertical-align: middle;
              width: 22px;
            }
          }
          .item-value {
            font-size: 16px;
            color: #2f8af5;
            .custom-link-sns {
              color: #2f8af5;
              // &:hover {
              //   color: #2f8af5;
              // }
            }
          }
        }
        button {
          display: block;
          width: 180px;
          height: 44px;
          line-height: 19px;
          background-color: #2f8af5;
          margin-bottom: 20px;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          color: white;
          border: none;
          font-weight: 700;
        }
        .delete-account {
          color: #fc615a;
          background-color: transparent;
          padding: 0;
          height: auto;
          line-height: normal;
          margin-top: 30px;
          border: none;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
.modal-editprofile {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  .modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 450;
    overflow: auto;
    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      height: 750px;
      background: var(--background-modal);
      color: var(--font-color);
      border-radius: 30px;
      outline: none;
      // background-color: #212121;
      // padding: 0.1px;
      // width: 750px;
      // margin: 5% auto;
      // overflow: auto;
      // height: auto;
      .edit-profile-modal-box {
        // padding: 29.9px;
        .title {
          text-align: center;
          position: relative;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 25px;
          // color: white;
          display: flex;
          justify-content: space-between;
          padding-top: 24px;
          padding-left: 50%;
          padding-right: 30px;
          .custom-title {
            transform: translateX(-50%);
          }
          .close {
            position: unset;
            // right: 0px;
            // top: 0px;
            margin-bottom: 0;
          }
          button {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 24px;
            right: 30px;
            color: inherit;
            font: inherit;
            text-decoration: none;
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }
        .form-content {
          font-size: 16px;
          // display: flex;
          // -webkit-box-pack: justify;
          // justify-content: space-between;
          // flex-wrap: wrap;
          padding: 24px 80px 0;
          display: grid;
          grid-template-rows: repeat(4, 1fr);
          align-items: center;
          .photo {
            margin: auto;
          }
          .content-box-edit {
            // width: 48%;
            // margin-bottom: 20px;
            .content-title {
              margin-bottom: 16px;
            }
            .custom-input {
              font-size: 16px;
              width: -webkit-fill-available;
              padding: 10px 16px;
              outline: none;
              border: none;
              // color: ;
              height: 48px;
              background: var(--background-box-baobab);
              border-radius: 10px;
            }
            .wrapper-input {
              position: relative;

              .image-check-validate {
                position: absolute;
                top: 0;
                right: 15px;
                width: 14px;

                img {
                  width: 100%;
                }
              }
            }
            .duplicate-wrapper {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              //text-align: right;
              //padding: 0px 15px;
              color: red;
              .duplicate-info-icon {
                font-size: 16px;
                margin-right: 2px;
              }
              .duplicate-info {
                font-size: 16px;
              }
            }

            textarea {
              resize: vertical;
              border-radius: 10px;
              background-color: #363636;
              font-size: 16px;
              width: 100%;
              padding: 12px 10px;
              color: white;
            }
            .avatar {
              position: relative;
              width: 110px;
              height: 110px;
              // margin: 20px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid white;
                background-color: #ffffff;
              }
              input {
                opacity: 0;
                width: 110px;
                height: 110px;
                border-radius: 50%;
                position: absolute;
                top: 0px;
                left: 0px;
              }
              // svg {
              // pointer-events: none;
              // position: absolute;
              // bottom: 3px;
              // right: 3px;
              // width: 20px;
              // height: 20px;
              // padding: 5px;
              // vertical-align: middle;
              // background-color: #363636;
              // border-radius: 50%;
              // }
              .icon-edit {
                img {
                  width: 40px;
                  height: 40px;
                  pointer-events: none;
                  position: absolute;
                  bottom: 3px;
                  right: 3px;
                  border: unset;
                }
              }
              * {
                cursor: pointer;
              }
            }
          }
          .full {
            width: 100%;
          }
          .photo {
            width: auto;
            position: relative;
          }
          button {
            width: 100%;
            font-size: 16px;
            // height: 50px;
            // background: linear-gradient(
            //   to left,
            //   rgb(103, 129, 249),
            //   rgb(227, 100, 190)
            // );
            color: white;
            border: none;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: 20px;
            height: 60px;
            background: #2f8af5;
            border-radius: 10px;
          }
          .disabled-button {
            background: var(--bg-line-footer);
            color: var(--font-color-btn);
            opacity: 0.5;
          }
        }
      }
    }
  }
  .modal-content::-webkit-scrollbar {
    display: none;
  }
}
.modal-delete-account {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  .modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
    display: flex;
    overflow: auto;
    .modal-content {
      z-index: 20;
      color: var(--font-color);
      border: none;
      outline: none;
      width: 350px;
      height: max-content;
      margin: auto;
      border-radius: 20px;
      .modal-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        // align-items: center;
        flex-direction: column;
        padding: 30px;
        background: #23235c;
        border-radius: 30px;
        box-shadow: rgb(0 0 0 / 68%) -5px 5px 15px 5px;
        width: 400px;
        height: 288px;
        .content {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          margin-bottom: 28px;
          font-weight: 700;
          font-size: 21px;
          line-height: 26px;
          text-align: center;
          // h1 {
          //   font-size: 15px;
          //   text-align: center;
          //   font-weight: 100;
          // }
        }
        .action {
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          gap: 24px;
          button {
            width: 100%;
            // padding: 5px 20px;
            padding: 10px;
            border-radius: 10px;
            min-width: 65px;
            height: 60px;
            color: white;
            border: none;
            cursor: pointer;
            margin-bottom: 0;
          }
          .yes {
            background: rgba($color: #ffffff, $alpha: 0.1);
          }
          .no {
            // background: linear-gradient(
            //   to left,
            //   rgb(103, 129, 249),
            //   rgb(227, 100, 190)
            // );
            background: #2f8af5;
          }
        }
        @media (max-width: 420px) {
          width: 300px;
          padding: 10px 20px;
          height: 250px;
          .content {
            font-size: 16px;
          }
          .action {
            button {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
@include Tablet_Mobile() {
  .profile-container {
    .my-profile-page {
      .my-profile-detail {
        .my-profile-leftdetail {
          .my-profile-detail-header {
            flex-direction: column;
            align-items: baseline;
            gap: 40px;
            > div {
              width: 100%;
              text-align: left;
            }
            .myProfileSocials {
              .Socialsdetail-collections {
                > div {
                  display: inline;
                }
                .value {
                  font-size: 20px;
                  margin: 0px;
                }
                .label {
                  font-size: 16px;
                }
              }
              .Socialsdetail {
                > div {
                  display: inline;
                }
                .value {
                  font-size: 20px;
                  margin: 0px;
                }
                .label {
                  font-size: 16px;
                }
              }
            }
          }
          .my-profile-contact {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
          }
        }
      }
    }
  }
  .modal-editprofile .modal-overlay .modal-content {
    width: 100%;
    height: 100%;
    border-radius: unset;
    .edit-profile-modal-box {
      .form-content {
        padding: 24px 40px 0;
        @include Mobile {
          padding: 24px 20px 0;
        }
      }
      @media (max-width: 320px) {
        .title {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .profile-container {
    .my-profile-page {
      .my-profile-detail {
        flex-direction: column;
      }
    }
    .my-profile-rightdetail {
      // margin: 20px 0 0 5px !important;
    }
  }
}
@media (max-width: 1366px) {
  .profile-container {
    .my-profile-page {
      .my-profile-header {
        width: 100%;
        // padding: 15px 15px 0;
      }
      .my-profile-detail {
        width: 100%;
        gap: 40px;
        // padding: 15px 15px 0;
      }
    }
  }
}
@media (max-width: 540px) {
  .profile-container {
    .my-profile-page {
      padding: 0 20px;
      .my-profile-detail {
        .my-profile-leftdetail {
          .my-profile-detail-header {
            .myProfileSocials {
              .Socialsdetail-collections {
                > div {
                  display: inline;
                }
                .value {
                  font-size: 20px;
                  margin: 0px;
                }
                .label {
                  font-size: 16px;
                }
              }
              .Socialsdetail {
                > div {
                  display: inline;
                }
                .value {
                  font-size: 20px;
                  margin: 0px;
                }
                .label {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
