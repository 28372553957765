/* import font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
.terms-and-conditions-container {
  width: min(100%, 1280px);
  padding: 80px 20px 20px 20px;
  margin: auto;
}
.terms-and-conditions-header {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 700;
    text-align: center;
    padding: 30px 20px 10px 20px;
}

.terms-and-conditions-cross-btn {
    width: 40px;
    height: 40px;
    float: right;
    margin-top: -6px;
    margin-right: -15px;
}

.terms-and-conditions-body {
    font-family: 'Roboto';
    font-weight: 400;
    padding: 0 27px 27px 27px;
}

.terms-and-conditions-body .terms-and-conditions-line > p {
    margin-top: 20px;
}

.terms-and-conditions-body .terms-and-conditions-line
.terms-and-conditions-line-title {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;     
}
#page-abc {
    height: 100vh;
    min-height: 840px;
    background: linear-gradient(#2061e2,#b17dff);
    font-family: 'Spoqa Han Sans Neo';
}
.abc-terms-header {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 700;
    text-align: center;
    padding: 30px 20px 10px 20px;
    color: #fff;
}
.abc-terms-cross-btn {
    width: 40px;
    height: 40px;
    float: right;
    margin-top: -6px;
    margin-right: -15px;
}
.info-abc {
    border-radius: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 450px;
    width: 350px;
    left: 50%;
    transform: translate(-50%,-50%);
}
.agree-terms-wrapper {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 5px 5px 5px 25%;
}
.all-agree-terms-wrapper {
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.agree-terms-link {
    color: #fff;
    text-decoration: underline;
    display: inline;
    cursor: pointer;
    margin-right: 2px;
}
.agree-terms-button > img {
    margin-right: 10px;
    width: 12px;
}
.all-agree-terms-button > img {
    margin-right: 10px;
    width: 20px;
}
.abc-register-button-wrapper {
    position: fixed;
    bottom: 0;
}
.abc-register-button {
    width: 100%;
    background: #2061e2;
    background-image: none!important;
    box-shadow: unset!important;
    color: var(--background-color-1);
    text-shadow: none;
    height: 55px;
    border: 0;
    border-radius: 12px;
    font-size: var(--font-size-15);
    margin-top: 40px;
}
.abc-register-button:hover {
    width: 100%;
    background-color: #2245a5!important;
    background-image: none!important;
    box-shadow: unset!important;
    color: var(--background-color-1);
    text-shadow: none;
    height: 55px;
    border: 0;
    border-radius: 12px;
    font-size: var(--font-size-15);
    margin-top: 40px;
}
.abc-register-button:hover {
    width: 100%;
    background-color: #e6e6e6;
    background-image: none!important;
    box-shadow: unset!important;
    color: var(--background-color-1);
    text-shadow: none;
    height: 55px;
    border: 0;
    font-size: var(--font-size-15);
    margin-top: 40px;
}
.left-img {
    /* margin-top: -200px; */
    height: 0vh;
    z-index: 0;
}
.left-img img {
    vertical-align: middle;
    margin-top: 0vh;
}
.abc-cn-content-center img {
    width: 150px;
}
.abc-cn-content-center {
    padding-top: 11vh;
    text-align: center;
}
.abc-cn-content-center h1 {
    padding: 15px 0px;
    font-size: 20px;
    color: white;
}
.abc-cn-content-center p {
    color: white;
    opacity: 0.7;
    font-size: 14px;
}