.search-bar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 0px;
  .search {
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--bg-search-input);
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
    justify-content: space-between;
    .search-icon {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
    }
    .search-input {
      width: calc(100% - 60px);
      display: block;
      margin: 0px -15px 0px 0px;
      padding: 0px;
      height: 100%;
      color: var(--color-search-ipnut);
      background-color: transparent;
      border: 0px;
      overflow: visible;
      outline: none;
      // font-size: 16px;
      font-weight: 400;
      &::placeholder {
        color: var(--color-search-ipnut);
      }
    }
  }
  .category {
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 12px;
    align-items: baseline;
    .category-item {
      cursor: pointer;
      color: var(--color-select);
      font-size: 24px;
      font-weight: 500;
      &--select {
        color: var(--font-color);
      }
    }
    .total-items {
      color: var(--blue-point, #2f8af5);
      font-size: 24px;
      font-weight: 400;
    }
  }
  .option {
    color: var(--font-color);
    background: var(--bg-select);
    &:nth-last-child {
      border-radius: 0 0 10px 10px;
    }
    &:nth-child(1) {
      border-radius: 10px 10px 0 0;
    }
  }
  @media (min-width: 1025px) {
    .search-input {
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    .filter-list {
      .category {
        gap: 20px;
      }
    }
  }
  @media (max-width: 540px) {
    margin-bottom: 8px;
    .search {
      // max-width: 335px;
      // height: 36px;
    }

    .search-input {
      font-size: 14px;
    }

    .filter {
      width: 100%;
      height: 100%;
      margin-top: 16.5px;
      .dropdown-list {
        width: 120px;
        height: 29px;
        font-size: 10px;
        font-weight: 700;
        border-radius: 4px;
        padding: 5px 17px;
      }
    }
    .category {
      .category-item {
        cursor: pointer;
        color: var(--color-select);
        font-size: 16px;
        font-weight: 500;
        &--select {
          font-size: 16px;
          font-weight: 500;
          color: var(--font-color);
        }
      }
      .total-items {
        font-size: 16px;
      }
    }
  }
}
