.my-profile-new-design {
  padding: 110px 40px 50px;
  margin: 0px auto;
  max-width: 100vw;
  width: min(100%, $page-content-width);
  display: flex;
  justify-content: space-between;
  color: var(--font-color-08);

  .content-my-profile {
    width: 894px;

    .title-my-profile {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      margin: 0 auto;
      max-width: 100%;
      color: var(--font-color-08);
      height: 56px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding-left: 20px;
    }

    .wrapper-content-profile {
      margin-top: 24px;
      background: var(--background-bottom-box-card);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      justify-content: space-between;

      .content-profile-left {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        width: 189px;

        .title-profile-img {
          font-weight: 700;
          font-size: 14px;
        }

        .img-profile {
          width: 120px;
          height: 120px;
          position: relative;
          border: 2px solid var(--font-color);
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }

          input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            right: 1px;
            border: unset;
          }

          .icon-edit {
            position: relative;

            svg {
              width: 43px;
              height: 43px;
              position: absolute;
              bottom: 4px;
              right: 0px;
            }

            input {
              opacity: 0;
              width: 43px;
              height: 43px;
              border-radius: 0;
              position: absolute;
              bottom: 4px;
              right: 0px;
              cursor: pointer;
            }
          }

          * {
            cursor: pointer;
          }
        }

        .recommand-size {
          color: var(--color-recomand-size);
          font-size: 12px;
          text-align: center;
          font-weight: 500;
        }

        .upload-profile {
          position: relative;
          width: 100px;
          height: 38px;
          background: var(--bg-select-infor);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: var(--font-color);
          cursor: pointer;

          .input-upload-img {
            cursor: pointer !important;
            position: absolute;
            z-index: 1;
            opacity: 0;
            width: 100px;
            height: 38px;
          }
        }
      }

      .content-profile-right {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 600px;

        .wrapper-nickname {
          display: flex;
          flex-direction: column;

          //gap: 8px;
          .title-nickname {
            font-weight: 700;
            font-size: 14px;
            padding-bottom: 8px;
          }

          .wrapper-input-name {
            display: flex;
            justify-content: space-between;
            gap: 8px;

            .button-edit {
              width: 100px;
              height: 40px;
              background: var(--bg-button-profile);
              color: white;
              border-radius: 8px;
              font-size: 12px;
            }

            .input-change-nickname {
              width: 100%;
              height: 40px;
              background: var(--bg-input-profile);
              border: 1px solid var(--line-my-profile);
              border-radius: 8px;
              padding: 10px;
              outline: none;
              color: var(--font-color);
              font-size: 14px;
              margin-bottom: 4px;
            }

            .wrapper-buttons {
              display: flex;
              justify-content: flex-end;
              gap: 8px;

              .button-nickname {
                width: 90px;
                height: 40px;
                background: var(--bg-select-infor);
                border-radius: 6px;
                font-size: 12px;
                color: #ffffff;
              }

              .btn-cancel {
                background-color: #39396c;

                &:hover {
                  opacity: 0.7;
                }
              }

              .btn-save {
                background-color: #2f8af5;

                &:hover {
                  background-color: $blue-hover-primary;
                }
              }

              .disabled-button {
                color: #8c8c8c;
              }

              .button-edit {
                width: 100px;
                height: 38px;
                background: var(--bg-select-infor);
                border-radius: 8px;
                font-size: 12px;
              }
            }
          }

          .nickname-div {
            width: 500px;
            height: 44px;
            background: var(--bg-nickname);
            padding: 10px 0;
            outline: none;
            color: var(--color-email-account);
            font-size: 14px;
          }

          .duplicate-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            color: red;
            //margin-top: -7px;
            //margin-bottom: -25px;
            font-size: 12px;

            .duplicate-info-icon {
              width: 12px;
              margin-right: 2px;
            }

            .duplicate-info {
              padding-top: 3px;
            }
          }

          .bottom-wrapper-nickname {
            display: flex;
            justify-content: space-between;
            //align-items: center;
            flex-direction: column;

            //margin-top: 5px;
            .content-proviso {
              font-size: 12px;
              padding-left: 3px;
              color: var(--color-recomand-size);
              font-weight: 500;
            }
          }
        }

        .signed-in-social {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title-signed-in {
            font-weight: 700;
            font-size: 14px;
          }

          .infor-signed-in {
            display: flex;
            gap: 8px;
            align-items: center;

            .bg-social-account {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              background: var(--background-color);

              .img-social-account {
                width: 20px;
                height: 20px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .address-account {
              color: var(--color-email-account);
              font-weight: 700;

              input {
                width: 130px;
                height: 38px;
                background: var(--bg-input-profile);
                border: 1px solid var(--line-my-profile);
                border-radius: 8px;
                padding: 10px;
                outline: none;
                color: var(--font-color);
                font-size: 12px;
              }
            }
          }
        }

        // start UID & Referral link
        .box-uid-referral {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          background-color: #0e0f29;
          border-radius: 8px;
          color: #ffffff;
          font-weight: 500;
          height: 50px;
          width: 100%;
        }

        .wrapper-wallet-address {
          display: flex;
          flex-direction: column;

          //gap: 16px;
          .btn-circle {
            width: 38px;
            height: 38px;
            text-align: center;
            padding: 9px;
            background: var(--bg-button-profile);

            svg {
              width: 20px;
              height: 16px;
            }
          }

          .bg-color-avtivate {
            background-color: #0e0f29;
            border-radius: 8px;
          }

          .wallet-address-title-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .bottom-wallet-address {
            display: flex;
            justify-content: space-between;
            align-content: center;

            .wallet-address {
              font-size: 14px;
              display: flex;
              align-items: center;
            }

            .wallet-address-mobile {
              display: none;
            }
          }
        }

        .my-twitter {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title-my-twitter {
            font-weight: 700;
            font-size: 14px;
          }

          .bottom-my-twitter {
            display: flex;
            justify-content: space-between;

            .infor-my-twitter {
              display: flex;
              gap: 8px;
              align-items: center;

              .bg-social-account {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background: var(--background-color);

                .img-social-account {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .address-account {
                // color: var(--color-email-account);
                // font-weight: 700;
                font-size: 14px;

                input {
                  width: 300px;
                  height: 38px;
                  background: var(--bg-input-profile);
                  border: 1px solid var(--line-my-profile);
                  border-radius: 8px;
                  padding: 10px;
                  outline: none;
                  color: var(--font-color);
                  font-size: 14px;
                }
              }
            }
          }
        }

        .wrapper-log-out {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .wrapper-delete {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .button-delete {
            color: #ff4d4f;
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 40px;

    .content-my-profile {
      width: 100%;

      .wrapper-content-profile {
        gap: 40px;

        .content-profile-right {
          flex-grow: 1;
          width: 100%;

          .wrapper-nickname {
            .input-change-nickname {
              width: 100%;
            }

            .nickname-div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 840px) {
    .content-my-profile {
      .wrapper-content-profile {
        flex-direction: column;

        .content-profile-left {
          gap: 12px;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 110px 20px 50px;

    .content-my-profile {
      .wrapper-content-profile {
        padding: 32px 24px;
        gap: 32px;

        .content-profile-left {
          .title-profile-img {
            padding-bottom: 4px;
          }

          .img-profile {
            width: 80px;
            height: 80px;

            .icon-edit {
              svg {
                width: 29px;
                height: 29px;
              }

              input {
                width: 29px;
                height: 29px;
              }
            }
          }

          .recommand-size {
            font-size: 10px;
          }
        }

        .content-profile-right {
          flex-grow: 1;
          gap: 16px;
          width: 100%;

          .signed-in-social {
            .title-signed-in {
              font-size: 12px;
            }

            .infor-signed-in {
              font-size: 14px;

              .bg-social-account {
                width: 30px;
                height: 30px;

                .img-social-account {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          .wrapper-nickname {
            .title-nickname {
              font-size: 12px;
            }

            .wrapper-input-name {
              align-items: start;
              gap: 0;

              .button-edit {
                width: 66px;
                min-width: 66px;
              }

              .wrapper-buttons {
                width: 100%;
                justify-content: center;
                margin-top: 10px;
                gap: 10px;

                .button-nickname {
                  border-radius: 8px;
                  width: 88px;
                  height: 30px;
                }
              }
            }

            .bottom-wrapper-nickname {
              //margin-top: 15px;
            }

            .input-change-nickname {
              width: 100%;
            }

            .nickname-div {
              width: 100%;
            }
          }

          .wrapper-wallet-address {
            .bottom-wallet-address {
              .wallet-address {
                display: none;
              }

              .wallet-address-mobile {
                font-size: 14px;
                display: flex;
                align-items: center;
              }
            }
          }

          .my-twitter {
            .bottom-my-twitter {
              .infor-my-twitter {
                .address-account {
                  font-size: 12px;

                  input {
                    width: 130px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .content-my-profile {
      .wrapper-content-profile {
        .content-profile-right {
          .wrapper-nickname {
            .bottom-wrapper-nickname {
              //margin-top: 15px;
            }
          }

          .wrapper-wallet-address {
            .bottom-wallet-address {
              .wallet-address {
                display: none;
              }

              .wallet-address-mobile {
                font-size: 14px;
                display: flex;
                align-items: center;
              }
            }
          }

          .my-twitter {
            .bottom-my-twitter {
              .infor-my-twitter {
                .address-account {
                  font-size: 12px;

                  input {
                    width: 130px;
                    height: 38px;
                    background: var(--bg-input-profile);
                    border: 1px solid var(--line-my-profile);
                    border-radius: 8px;
                    padding: 10px;
                    outline: none;
                    color: var(--font-color);
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .content-my-profile {
      .wrapper-content-profile {
        .content-profile-right {
          .wrapper-wallet-address {
            .btn-circle {
              width: 33px;
              height: 33px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}