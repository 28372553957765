// user-dropdown-box
.box-shadow-box {
  box-shadow: rgb(99 99 99 / 30%) 0px 2px 8px 0px;
}
.user-dropdown-box {
  width: 220px;
  margin-right: 40px;
  height: auto;
  border-radius: 12px;
  // background-color: var(--background-modal);
  position: absolute;
  right: 0px;
  top: 100%;
  flex-direction: column;
  padding: 20px;
  z-index: 20;
  .close-sidebar {
    text-align: right;
    padding: 20px 20px 15px;
    display: none;

    .icon-close {
      display: inline-block;

      img {
        width: 20px;
      }
    }
  }

  // display: none;
  .wrapper-dropdown {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .wallet-button {
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 10px 0px;
      gap: 10px;
      color: var(--font-color);
      svg {
        margin-right: 5px;
        width: 25px;
        height: 25px;
      }
      &:hover {
        color: #2f8af5;
        svg {
          path {
            fill: #2f8af5;
          }
        }
      }
    }
    .color-darkmode {
      color: #ffffff;
    }
    .color-lightmode {
      color: #1f1f1f;
    }
    .wrapper-fanpages {
      display: none;
    }

    .wrapper-wallets {
      display: none;
    }
    @media (max-width: 325px) {
      .wallet-button {
        font-size: 12px;
      }
    }
  }

  .logout-btn {
    margin-top: 14px;
    border-radius: 10px;
    font-size: 13px;
    color: #ff6a6a;
    height: 40px;
    line-height: 40px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    &:hover {
      background-color: #2f8af5;
    }
  }
  .logout-darkmode {
    background: #ffffff;
  }
  .logout-lightmode {
    background: #f0f0f0;
  }

  @include Tablet() {
    // margin-right: 25px;

    .wrapper-dropdown {
      .wrapper-fanpages {
        border-top: var(--border-box-collection);
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        .custom-icon {
          width: 28px;

          img {
            width: 100%;
          }
        }
      }

      .wrapper-wallets {
        display: block;
        width: 100%;
        border-bottom: 1px solid #393939;
        font-size: 16px;
        font-weight: 600;
        color: #2f8af5;
        // padding: 13px 0;

        .btn-wallets {
          display: flex;
          justify-content: flex-start;
          gap: 14px;
          height: 35px;
          align-items: center;
          .img-wallet {
            width: 24px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    .close-sidebar {
      display: block;
    }
    .wrapper-dropdown {
      padding: 0 15px;
    }
    .logout-btn {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @include Mobile() {
    margin-right: 25px;

    .wrapper-dropdown {
      // padding: 0 15px;

      .wrapper-fanpages {
        border-top: var(--border-box-collection);
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        .custom-icon {
          width: 28px;

          img {
            width: 100%;
          }
        }
      }

      .wrapper-wallets {
        display: block;
        width: 100%;
        border-bottom: 1px solid #393939;
        font-size: 16px;
        font-weight: 600;
        color: #2f8af5;
        height: 35px;
        // padding: 13px 0;

        .btn-wallets {
          display: flex;
          justify-content: flex-start;
          gap: 14px;
          .img-wallet {
            width: 24px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 325px) {
    .wrapper-dropdown {
      font-size: 12px;
    }
  }
}
