.auction-sale-and-price {
  .box-count-down {
    height: 54px;
    border-radius: 12px;
    border: 2px solid var(--font-color);
    margin: 42px 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    font-weight: 600;
  }
  .icon-countdown-lightmode {
    svg {
      circle {
        stroke: #02021e;
      }
      path {
        stroke: #02021e;
      }
    }
  }
  .wrapper-bidding-period {
    margin: 26px 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .bidding-period-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #b9b9b9;
      .title-bidding-period {
        font-weight: 600;
        font-size: 18px;
        color: #acacac;
      }
    }
  }
  // section offers table
  .section-offers-table {
    // display: none;
    overflow: hidden;
    .accordion {
      --bs-accordion-border-color: unset;
      .accordion-item {
        background-color: transparent;
        border: 1px solid var(--bg-line-footer);
        color: var(--font-color);
        .accordion-header {
          background: var(--bg-accordion-header);
          .accordion-button {
            padding: 20px 16px;
            background-color: transparent;
            .wrapper-header-offers {
              display: flex;
              gap: 10px;
              align-items: center;
              color: var(--font-color);
              font-weight: 700;
            }
            .icon-offers-lightmode {
              rect {
                fill: #000000;
              }
            }
            &::after {
              background-size: 24px;
            }
            &:not(.collapsed) {
              color: #ffffff;
              background-color: transparent;
              box-shadow: unset;
              border-bottom: 1px solid var(--bg-line-footer);
            }
            &:not(.collapsed)::after {
              transition: 500ms;
              transform: rotateX(180deg);
            }
          }
        }
        .box-border {
          border-bottom-right-radius: 6px;
          overflow: hidden;
        }
        .padding-body-offers {
          // background: var(--bg-accordion-properties);
          padding: 0 16px 16px;
          max-height: 380px;
          overflow-x: auto;
          .header-table-offers {
            border-bottom: 1px solid var(--bg-line-footer);
            color: #8d8d8d;
          }
          th,
          tr,
          td {
            padding: 13px;
            font-size: 12px;
            font-weight: 400;
          }
          .body-table-offers {
            padding-top: 14px;
            .offer-price-nft {
              font-size: 16px;
              font-weight: 900;
            }
            .offer-price-usd {
              color: #8d8d8d;
            }
            .offer-form-column {
              color: #2f8af5;
            }
            .btn-offer-table {
              width: 100%;
              height: 32px;
              border-radius: 20px;
              display: grid;
              place-items: center;
              font-size: 10px;
              font-weight: 600;
              color: white;
              background: var(--btn-offer-table);
              &:hover,
              &:active {
                background: $blue-hover-primary;
              }
            }
          }
          .width-price {
            // width: 100px;
          }
          .width-floor-difference {
            // width: 130px;
          }
        }
      }
    }
    .btn-bottom-offer {
      margin: 24px 0 50px;
      width: 100%;
      height: 60px;
      border-radius: 26px;
      background-color: #2f8af5;
      display: grid;
      place-items: center;
      font-weight: 600;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .section-current-price {
    // .wrapper-current-price {
    //   height: 64px;
    //   width: 100%;
    //   border-radius: 8px;
    //   display: flex;
    //   justify-content: space-between;
    //   padding: 10px 120px;
    //   font-weight: 700;
    //   color: #ffffff;
    //   background-color: #363667;
    //   align-items: center;
    //   .wrapper-price {
    //     display: flex;
    //     gap: 8px;
    //   }
    // }
    .btn-bottom-price {
      margin: 24px 0 50px;
      width: 100%;
      height: 60px;
      border-radius: 26px;
      background-color: #2f8af5;
      display: grid;
      place-items: center;
      font-weight: 600;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .wrapper-current-price {
    height: 64px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 10px 120px;
    font-weight: 700;
    color: #ffffff;
    background-color: #363667;
    align-items: center;
    .wrapper-price {
      display: flex;
      gap: 4px;
      font-weight: 700;
    }
    .usd-price {
      font-size: 14px;
      padding-top: 1px;
    }
  }
  @media (max-width: 768px) {
    .wrapper-bidding-period {
      .bidding-period-item {
        .title-bidding-period {
          font-size: 12px;
        }
        .detailed-bidding-period {
          font-size: 10px;
        }
      }
    }
    .section-offers-table {
      .accordion {
        .accordion-item {
          .accordion-header {
            .accordion-button {
              padding: 12px 16px;
              .wrapper-header-offers {
                font-size: 10px;
                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
          .padding-body-offers {
            padding: 0 12px 16px;
            max-height: 190px;
            overflow: auto;
            th,
            tr,
            td {
              font-size: 10px;
              padding: 8px 6px;
            }
            .body-table-offers {
              .offer-price-nft {
                font-size: 10px;
                font-weight: 600;
              }
              .btn-offer-table {
                height: 24px;
                // width: 56px;
              }
              .btn-offer-mobile {
                width: 20px;
                height: 20px;
                display: grid;
                place-items: center;
                background-color: #313148;
                border-radius: 100%;
                &:hover {
                  background-color: $purple-color-primary;
                  svg {
                    rect {
                      stroke: #ffffff;
                    }
                  }
                }
              }
            }
            .width-price {
              // width: 60px;
            }
          }
        }
      }
      .btn-bottom-offer {
        height: 36px;
        font-size: 12px;
      }
    }
    .section-current-price {
      // .wrapper-current-price {
      //   height: 36px;
      //   font-size: 10px;
      //   padding: 12px 20px;
      //   .wrapper-price {
      //     font-size: 12px;
      //   }
      // }
      .btn-bottom-price {
        height: 36px;
        font-size: 12px;
        margin: 24px 0 40px;
      }
    }
    .wrapper-current-price {
      height: 36px;
      font-size: 10px;
      padding: 12px 20px;
      .wrapper-price {
        font-size: 11px;
        gap: 4px;
        .usd-price {
          font-size: 11px;
          padding-top: 0px;
        }
      }
    }
  }
}
