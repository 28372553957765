.dropdown-box-sns {
  width: 116px;
  height: auto;
  border-radius: 8px;
  background-color: var(--background-modal);
  position: absolute;
  right: 0px;
  flex-direction: column;
  padding: 6px;
  margin: 0;
  z-index: 20;
  list-style: none;
  top: 100%;
  font-weight: 600;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .list-dropdown-item {
    // display: flex;
    font-weight: 600;
    padding: 0px 0px;
    font-size: 14px;
    .dropdown-item-nft {
      // svg {
      //   margin-bottom: -5px;
      //   margin-right: 12px;
      //   // width: 19px;
      //   // height: 19px;
      // }
      &:hover {
        color: #2f8af5;
        svg {
          path {
            fill: #2f8af5;
          }
        }
      }
      .custom-link-sns {
        display: flex;
        gap: 4px;
        color: var(--font-color);
        align-items: center;

        .image-sns {
          width: 16px;
          height: 16px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          color: #2f8af5;
        }
      }
    }
    &:not(:last-child) {
      border-bottom: var(--border-box-collection);
    }
  }
}
