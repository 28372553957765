:root {
  --bs-font-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}
dl,
ol,
ul {
  margin-top: 1rem;
  margin-bottom: unset;
}
img,
svg {
  vertical-align: unset;
}
a:hover {
  color: unset;
}
