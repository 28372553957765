@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.main-app {
  text-align: center;
  margin: 108px auto;
  width: min(100%, 1200px);
}
.wallet-not-connect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Roboto';
}
.cta-button {
  border: none;
  border-radius: 8px;
  /* min-width: 300px; */
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto';
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta-button:hover {
  opacity: 0.7;
}
.connect-wallet-button {
  background: #2F8AF5;
  min-width: 300px;
}
.connect-wallet-button:disabled {
  background: rgb(114, 147, 234);
}
.claim-button {
  background: linear-gradient(93deg, black, #0d3290);
}
.claim-button:disabled {
  background: rgb(114, 147, 234);
}
.input-box {
  border-radius: 12px;
  width: 200px;
  height: 30px;
  font-size: 15px;
  margin: 10px 5px 15px 5px;
  padding: 0 15px;
}
.button-div {
  margin: 10px;
}
.wrapper-adm-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: 'Roboto';
}
.wrapper-header-adm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  background: #1B1B35;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
}
.wrapper-content-adm {
  border-radius: 8px;
  padding: 32px;
  background: #1B1B35;
  color: #8787D6;
  width: 100%;
}
.deploy-adm {
  min-width: 664px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.console-adm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 788px;
}
.wrapper-input-adm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
}
.title-input-adm {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
}
.custom-input-adm {
  border-radius: 8px;
  border: none;
  height: 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 16.41px;
  color: #777777;
  padding: 8px 20px 8px 12px;
  background: #0E0F29;
}
.button-adm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  background: #2F8AF5;
}

.btn-paste{
  background: #323249;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
}

.button-adm:hover {
  opacity: 0.7;
}
.validation-adm {
  font-size: 12px;
  line-height: 14.06px;
  font-weight: 500;
  color: #8D8D8D;
}
.line-adm {
  border-bottom: 1px solid #2D2D4F;
  width: 100%;
}
.header-console {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.terminal-adm {
  overflow: auto;
  text-align: start;
}
.terminal-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: #777777;
  padding-right: 6px;
  padding-bottom: 8px;
}
@media (max-width: 1920px) {
  .main-app {
    margin: 30px auto;
  }
}
