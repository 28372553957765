:root {
  .skeleton {
    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.4) 20%,
        rgba(255, 255, 255, 0.8) 60%,
        rgba(255, 255, 255, 0)
      ) !important;
    }
  }
}
[data-theme='dark'] {
  .skeleton {
    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(rgb(20, 20, 20), 0) 0,
        rgba(rgb(20, 20, 20), 0.4) 20%,
        rgba(rgb(20, 20, 20), 0.8) 60%,
        rgba(rgb(20, 20, 20), 0)
      ) !important;
    }
  }
}
.skeleton {
  position: relative;
  overflow: hidden;
  background: var(--bg-skeleton) !important;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.home-nft-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
