@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'variables';
@import 'mixin';
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.cjk-font {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

// @import '../../../components/togglelightmode/toggle-mode';
#root {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.min-height-content {
  min-height: calc(100vh - 337px);
}

.min-height-content-landingpage {
  min-height: calc(100vh - 337px);
}

.main {
  position: relative;
  background-color: var(--background-color);
  color: var(--font-color);

  .background-section01 {
    background-image: url('../../../assets/img/home_01.png');
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  @include Mobile() {
    .background-section01 {
      height: 540px;
      background-position: 35% center;
    }
  }
}

.button {
  color: inherit;
  font: inherit;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &-position-help-icon {
    position: relative;
    top: 3px;
  }
}

.gray-border {
  border: 1px solid #444;
}

.updown-border {
  border: 1px solid #525298;
}

.updown-border-select {
  border: 1px solid #2f8af5;
}

.page-grid {
  position: relative;
  margin: 0 auto 85px;
}

a {
  color: #555;
  text-decoration: none;
  transition: color 0.15s ease 0s, background-color 0.15s ease 0s;
}

.hot-ollectibles-wrapper {
  width: 282px;
  height: 355px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  border-radius: 15px;
  background-color: transparent;
  overflow: hidden;
  transition: transform 0.15s ease-in-out 0s;
  cursor: pointer;
  margin: 0px;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  // border: 1px solid #444444;
  border: var(--border-box-card);

  &:hover {
    transform: scale(1.05);
    transition: 0.5s;
    border: 1px solid #2f8af5;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);

    .img-token {
      .price-and-remaining {
        backdrop-filter: blur(5px);
        opacity: 1 !important;
      }

      .wrapper-price-type {
        backdrop-filter: blur(5px);
        opacity: 1 !important;
        height: 66px !important;

        .price-type-home {
          background-color: #2f8af5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 22px;
          border-radius: 20px;
          padding: 4px;
        }
      }
    }
  }

  @media (max-width: 639px) {
    flex-direction: column;
    flex-flow: column;
  }
}

.hot-ollectibles-item {
  width: 100%;
  // padding: 0px 12px;
  border-right: none;
}

.fw-600 {
  font-weight: 600;
}

.fw-400 {
  font-weight: 400;
}

// .disable {
//   background: $dark-color-39 !important;
//   color: $dark-color-70 !important;
// }
.font-size-14 {
  font-size: 14px;
}

.font-size-18 {
  font-size: 18px;
}

.on-hide {
  display: none;
}

.on-show {
  display: block;
}

.ds-flex {
  display: flex;
}

@media (max-width: 1024px) {
  .hide-max-1024px {
    display: none !important;
  }
}

@media (max-width: 540px) {
  .hide-max-540px {
    display: none !important;
  }
}

@media (min-width: 540px) {
  .hide-min-540px {
    display: none;
  }
}

@media (min-width: 1025px) {
  .hide-min-1025px {
    display: none !important;
  }
}

@include Mobile() {
  .hidden-mobile {
    display: none !important;
  }
}

@include Tablet() {
  .hidden-tablet {
    display: none !important;
  }
}

@include PC() {
  .hidden-pc {
    display: none !important;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: var(--background-line);
}

// margin
.right-40 {
  margin-right: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-56 {
  margin-bottom: 56px;
}

.title-profile {
  font-weight: 700;
  font-size: 14px;
}

.button-profile {
  height: 40px;
  width: 100px;
  background: var(--bg-button-profile);
  color: white;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.button-act .btn-act-w {
  width: 100px !important;
  white-space: nowrap;
}

.btn-act-w-a {
  width: 135px !important;
  white-space: nowrap;
}

.btn-act-w-b {
  width: 100px !important;
}

.button-profile-small {
  height: 38px;
  width: 60px;
  background: var(--bg-select-infor);
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 375px) {
    width: 60px;
  }
}

.profile-edit-button-wrapper {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.convert-url-link {
  cursor: pointer;
  color: #2f8af5;
}

.convert-url-link:hover {
  cursor: pointer;
  color: #2f8af5;
}

.btn-detail-nft {
  margin: 24px 0 50px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: $blue-color-primary;
  display: grid;
  place-items: center;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;

  // &-custom {
  //   background-color: #ffffff1a;
  //   color: var(--font-color);
  //   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //   &:hover {
  //     background-color: $blue-hover-primary;
  //   }
  // }
  &:hover {
    background-color: $blue-hover-primary;
  }

  @media (max-width: 768px) {
    height: 48px;
    font-size: 14px;
  }
}

.d-grid {
  display: grid;
}

@for $i from 1 through 5 {
  .grid-gap-#{$i * 5} {
    grid-gap: #{$i * 5}px;
  }
}

@for $i from 1 through 64 {
  .grid-col-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }

  .grid-span-#{$i} {
    grid-column: 1 / span #{$i};
  }

  .gap-#{$i}px {
    gap: #{$i}px;
  }

  .p-#{($i - 1) * 2} {
    padding: #{($i - 1) * 2}px;
  }

  .m-#{($i - 1) * 2} {
    margin: #{($i - 1) * 2}px;
  }

  .fs-#{$i} {
    font-size: #{$i}px;
  }

  .border-radius-#{($i - 1) * 2} {
    border-radius: #{($i - 1) * 2}px;
  }

  .lh-#{$i} {
    line-height: #{$i}px;
  }

  @each $shortD, $dir in $directions {
    .p-#{$shortD}-#{($i - 1) * 2} {
      padding-#{$dir}: #{($i - 1) * 2}px;
    }

    .m-#{$shortD}-#{($i - 1) * 2} {
      margin-#{$dir}: #{($i - 1) * 2}px;
    }

    .border-radius-#{$shortD}-#{($i - 1) * 2} {
      border-radius-#{$dir}: #{($i - 1) * 2}px;
    }
  }

  @media screen and (max-width: 768px) {
    .top-xs-15 {
      top: 15% !important;
    }

    .left-xs-4 {
      left: 4% !important;
    }

    .grid-col-xs-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr)) !important;
    }

    .grid-span-xs-#{$i} {
      grid-column: 1 / span #{$i} !important;
    }

    .gap-xs-#{$i}px {
      gap: #{$i}px !important;
    }

    .p-xs-#{($i - 1) * 2} {
      padding: #{($i - 1) * 2}px !important;
    }

    .m-xs-#{($i - 1) * 2} {
      margin: #{($i - 1) * 2}px !important;
    }

    .fs-xs-#{$i} {
      font-size: #{$i}px !important;
    }

    .border-radius-xs-#{($i - 1) * 2} {
      border-radius: #{($i - 1) * 2}px !important;
    }

    .lh-xs-#{$i} {
      line-height: #{$i}px !important;
    }

    @each $shortD, $dir in $directions {
      .p-#{$shortD}-xs-#{($i - 1) * 2} {
        padding-#{$dir}: #{($i - 1) * 2}px !important;
      }

      .m-#{$shortD}-xs-#{($i - 1) * 2} {
        margin-#{$dir}: #{($i - 1) * 2}px !important;
      }

      .border-radius-#{$shortD}-xs-#{($i - 1) * 2} {
        border-radius-#{$dir}: #{($i - 1) * 2}px !important;
      }
    }
  }
}

@for $i from 1 through 9 {
  .fw-#{$i} {
    font-weight: #{$i * 100};
  }

  @media screen and (max-width: 768px) {
    .fw-xs-#{$i} {
      font-weight: #{$i * 100};
    }
  }
}

@for $i from 1 through 1280 {
  .w-#{$i}px {
    width: #{$i}px;
  }

  .h-#{$i}px {
    height: #{$i}px;
  }

  .max-h-#{$i}px {
    max-height: #{$i}px;
  }

  .max-w-#{$i}px {
    max-width: #{$i}px;
  }

  .min-h-#{$i}px {
    min-height: #{$i}px;
  }

  .min-w-#{$i}px {
    min-width: #{$i}px;
  }

  @media screen and (max-width: 768px) {
    .w-xs-#{$i}px {
      width: #{$i}px !important;
    }

    .h-xs-#{$i}px {
      height: #{$i}px !important;
    }

    .max-h-xs-#{$i}px {
      max-height: #{$i}px !important;
    }

    .max-w-xs-#{$i}px {
      max-width: #{$i}px !important;
    }

    .min-h-xs-#{$i}px {
      min-height: #{$i}px !important;
    }

    .min-w-xs-#{$i}px {
      min-width: #{$i}px !important;
    }
  }
}

.w-full {
  width: 100vw;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}

.line-banner {
  border-bottom: 1px solid var(--border-line-modal);
  margin-top: 16px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

.line-vertical {
  border: 1px solid #7380a2;
  margin-left: 36px;
  height: 24px;

  @media (max-width: 640px) {
    border: 1px solid #484848;
    height: 18px;
    margin-left: 8px;
    display: none;
  }
}

.line-profile {
  width: 100%;
  height: 1px;
  background: var(--background-line-profile);
}

.text-bg-purple {
  background-color: $purple-color-primary;
  color: $white-color-primary;
}

.pt-12 {
  padding-top: 12px;
}

.path-black path {
  fill: $dark-color-primary !important;
}

.path-partner path {
  fill: $dark-color-primary !important;
}

.svg-color {
  fill: $black-color-primary;

  path {
    fill: $black-color-primary;
  }
}

.close-icon {
  fill: $black-color-primary;

  path {
    fill: $black-color-primary;
  }
}

.close-icon-2 {
  fill: $black-color-primary;

  path {
    fill: $black-color-primary;
  }
}

.svg-color-525298 {
  fill: $black-color-primary;

  path {
    fill: $black-color-primary;
  }
}

.transfer-color-525298 {
  fill: #525298;

  path {
    stroke: #525298;
  }
}

.fill-black {
  svg {
    path {
      fill: #1f1f1f;
    }
  }
}

.svg-path-black {
  svg {
    rect {
      fill: #1f1f1f;
    }
  }
}

.stroke-black {
  svg {
    path {
      stroke: #1f1f1f;
    }
  }
}

[data-theme='dark'] {
  .dark\:text-525298 {
    color: #525298 !important;
  }

  .dark\:text-ffffffb2 {
    color: #ffffffb2;
  }

  .dark\:fill-white {
    svg {
      path {
        fill: white !important;
      }
    }
  }

  .dark\:fill-525298 {
    svg {
      path {
        fill: #525298 !important;
      }
    }
  }

  .dark\:stroke-updown {
    svg {
      path {
        stroke: #525298 !important;
      }
    }
  }

  .dark\:stroke-updown-select {
    svg {
      path {
        stroke: #2f8af5 !important;
      }
    }
  }

  .dark\:svg-path-white {
    svg {
      rect {
        fill: white !important;
      }
    }
  }

  .dark\:svg-path-updown {
    svg {
      rect {
        fill: #525298 !important;
      }
    }
  }

  .dark\:svg-path-updown-select {
    svg {
      rect {
        fill: #2f8af5 !important;
      }
    }
  }

  .dark\:text-white {
    color: $white-color-primary !important;
  }

  .dark\:bg-white-10 {
    background-color: var(--background-box-wallet) !important;
  }

  .dark\:bg-select-lst {
    background-color: var(--background-color) !important;
  }

  .dark\:path-white path {
    fill: $white-color-primary !important;
  }

  .dark\:path-partner path {
    fill: #9a9aa5 !important;
  }

  .svg-color {
    fill: $white-color-primary;

    path {
      fill: $white-color-primary;
    }
  }

  .close-icon {
    fill: $color-8787d6;

    path {
      fill: $color-8787d6;
    }
  }

  .close-icon-2 {
    fill: #ffffff;

    path {
      fill: #ffffff;
    }
  }

  .svg-color-525298 {
    fill: #525298;

    path {
      fill: #525298;
    }
  }

  .transfer-color-525298 {
    fill: #525298;

    path {
      stroke: #525298;
    }
  }
}

.form-control:focus {
  box-shadow: none;
}

.form-control::placeholder {
  color: $white-color-primary;
  opacity: 0.5;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.bg-box-card {
  background: var(--background-bottom-box-card);
}

.bg-default {
  background-color: var(--background-color);
}

.bg-accordion-body {
  background: var(--bg-accordion-body) !important;
}

.bg-button-sale {
  background-color: $blue-color-primary;
  color: $white-color-primary;
  white-space: nowrap;
  text-align: center;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  height: 30px;

  &:hover {
    background-color: $blue-hover-primary;
  }

  @media (max-width: 768px) {
    padding: 6px 16px;
    height: 26px;
  }
}
.collectibles-disable-list {
  background-color: #1b1b35;
  color: #777777;
  cursor: default !important;
  &:hover {
    background-color: #1b1b35;
  }
}

.w-sale-info {
  width: 102px;
}

.w-list-sale {
  width: 113px;
}

.hover-text-bg-purple {
  &:hover {
    background-color: $purple-color-primary;
    color: $white-color-primary;
  }
}

.hover-text-bg-primary {
  &:hover {
    background-color: $blue-hover-primary;
    color: $white-color-primary;
  }
}

.word-break {
  word-break: break-all;
}

.modal-primary {
  position: fixed;
  inset: 0;
  margin: auto;
  outline: none;
  transition: all 0.5s;
  background: var(--background-modal);
  color: var(--font-color);
  border-radius: 24px;

  @media (max-width: 768px) {
    border-radius: 12px;
  }
}

.close-button-modal {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    top: 20px;
  }
}

.bg-input-modal {
  background-color: var(--bg-input-modal);
}

.color-money {
  color: #ffffffb2;
}

.color-name-box {
  color: var(--color-title-chain);
}

@for $i from 1 through 10 {
  .line-clamp-#{$i} {
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i;
  }

  .opacity-#{$i} {
    opacity: #{$i * 0.1};
  }

  .faucet-table > tbody > tr > td {
    padding: 0.3rem;
    font-weight: 400;
  }

  @media screen and (max-width: 640px) {
    .line-clamp-xs-#{$i} {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: $i;
    }
  }
}

@for $i from 1 through 100 {
  .w-#{$i} {
    width: #{calc(100% * $i / 100)};
  }

  .left-#{$i} {
    left: #{calc(100% * $i / 100)};
  }

  .right-#{$i} {
    right: #{calc(100% * $i / 100)};
  }

  .top-#{$i} {
    top: #{calc(100% * $i / 100)};
  }

  .bottom-#{$i} {
    bottom: #{calc(100% * $i / 100)};
  }

  @media screen and (max-width: 768px) {
    .w-xs-#{$i} {
      width: #{calc(100% * $i / 100)} !important;
    }

    .left-xs-#{$i} {
      left: #{calc(100% * $i / 100)} !important;
    }

    .right-xs-#{$i} {
      right: #{calc(100% * $i / 100)} !important;
    }

    .top-xs-#{$i} {
      top: #{calc(100% * $i / 100)} !important;
    }

    .bottom-xs-#{$i} {
      bottom: #{calc(100% * $i / 100)} !important;
    }
  }
}

.hover-cusor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.hover-opa-7 {
  &:hover {
    opacity: 0.7 !important;
  }
}

.bg-box-modal {
  background-color: var(--background-box-wallet) !important;
}

.bg-icon {
  background-color: #58588c;
}

.color-primary {
  color: #2f8af5;
}

.color-secondary {
  color: #944fff;
}

@media screen and (max-width: 768px) {
  .w-xs-100 {
    width: 100% !important;
  }

  .flex-xs-row {
    flex-direction: row !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .item-xs-end {
    align-items: end !important;
  }

  .j-xs-bw {
    justify-content: space-between;
  }

  //set width arrow icon react-select
  .css-1zi56g-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .css-fttci2-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .css-l8g5a6-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .css-rso1rx-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .css-16fp3qc-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .css-vux9er-indicatorContainer {
    svg {
      width: 10px;
    }
  }

  .w-sale-info {
    width: 86px;
  }

  .w-list-sale {
    width: 97px;
  }

  .gap-0-16 {
    gap: 0 16px;
  }

  .text-xs-center {
    text-align: center;
  }

  .lh-xs-inherit {
    line-height: inherit;
  }
}

.text-base {
  color: var(--font-color);
}

input:focus {
  outline: none;
}

.detail-box-modal {
  height: 50px;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #ffffff1a;
  color: var(--font-color);
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media (max-width: 768px) {
    height: 32px;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 12px;
  }
}

.border-unset {
  border: unset;
}

.grid-row-unset {
  grid-template-rows: unset !important;
}

.line-share-to {
  width: 100%;
  display: flex;

  &::before {
    content: '';
    border-bottom: 1px solid var(--color-or-02);
    flex: 1 0 auto;
    height: 10px;
    margin: 0;
  }

  .title-share-to {
    text-align: center;
    flex: 0.3 0 auto;
    margin: 0;
    color: var(--font-color-08);
  }

  &::after {
    content: '';
    border-bottom: 1px solid var(--color-or-02);
    flex: 1 0 auto;
    height: 10px;
    margin: 0;
  }
}

.wrapper-share-sns {
  height: 56px;

  .icon-share-sns {
    background-color: #ffffff;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    cursor: pointer;

    &:hover {
      background-color: #bae7ff;
    }

    img {
      width: 16px;
    }
  }

  @media (max-width: 768px) {
    height: 40px;

    .icon-share-sns {
      width: 24px;
      height: 24px;

      img {
        width: 10px;
      }
    }
  }
}

.wrapper-input-otp {
  padding: 8px 0 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  // font-size: 14px;
  color: var(--font-color);

  &-icon-plholder {
    background-color: #ffffff1a;
    height: 50px;
    width: 100%;
    padding: 10px 16px 10px 16px;
    border-radius: 10px;
    border: none;
    color: var(--font-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &:focus {
      outline: none;
    }
  }

  input {
    background-color: #ffffff1a;
    height: 50px;
    width: 100%;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    border: none;
    color: var(--font-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &:focus {
      outline: none;
    }
  }

  .btn-paste-otp {
    height: 50px;
    width: 100px;
    min-width: 100px;
    border-radius: 8px;
    background-color: $blue-color-primary;
    font-weight: 400;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #ffffff;

    &:hover {
      background-color: $blue-hover-primary;
    }
  }

  @media (max-width: 768px) {
    padding: 8px 0 16px;
    font-size: 10px;
    gap: 10px;

    input {
      height: 32px;
      padding: 10px 14px;
      border-radius: 6px;
    }

    .btn-paste-otp {
      width: 74px;
      min-width: 74px;
      height: 32px;
      border-radius: 6px;
    }
  }
}

.wrapper-input-and-icon {
  padding: 12px 0 56px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--font-color);

  &-icon-plholder {
    background-color: #ffffff1a;
    height: 48px;
    width: 10%;
    padding: 10px 10px 10px 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: none;
    color: var(--font-color);
  }

  input {
    background-color: #ffffff1a;
    height: 48px;
    width: 90%;
    padding: 10px 0px 10px 5px;
    margin-right: 20px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    color: var(--font-color);

    &:focus {
      outline: none;
    }
  }

  .btn-paste-otp {
    height: 48px;
    width: 112px;
    border-radius: 10px;
    background-color: var(--bg-button-max);
    font-weight: 700;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #ffffff;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 0 36px;
    font-size: 10px;

    input {
      height: 32px;
      padding: 10px 5px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    &-icon-plholder {
      background-color: #ffffff1a;
      height: 32px;
      width: 10%;
      padding: 6px 0px 0px 5px;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      border: none;
      color: var(--font-color);
    }

    .btn-paste-otp {
      width: 74px;
      height: 32px;
      border-radius: 6px;
    }
  }
}

.wrapper-input-success {
  padding: 12px 0 56px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--font-color);

  &-icon-plholder {
    background-color: #ffffff1a;
    height: 48px;
    width: 10%;
    padding: 10px 10px 10px 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: none;
    color: var(--font-color);
  }

  input {
    background-color: #ffffff1a;
    height: 48px;
    width: 100%;
    padding: 10px 0px 10px 5px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    color: var(--font-color);

    &:focus {
      outline: none;
    }
  }

  .btn-paste-otp {
    height: 48px;
    width: 112px;
    border-radius: 10px;
    background-color: var(--bg-button-max);
    font-weight: 700;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #ffffff;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    padding: 10px 0 36px;
    font-size: 10px;

    input {
      height: 32px;
      padding: 10px 14px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }

    &-icon-plholder {
      background-color: #ffffff1a;
      height: 32px;
      width: 10%;
      padding: 6px 0px 0px 5px;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      border: none;
      color: var(--font-color);
    }

    .btn-paste-otp {
      width: 74px;
      height: 32px;
      border-radius: 6px;
    }
  }
}

.wrapper-input-otp::placeholder {
  color: red;
  opacity: 0.5;
}

.circle-box-icon {
  width: 26px;
  height: 26px;
  background-color: var(--circle-box-icon);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: #2f8af5;
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
}

.circle-box-icon-transfer-item {
  @extend .circle-box-icon;

  &.variant-circle-1 {
    background-color: var(--bg-link-icon);
  }
}

.bg-abc-wallet {
  //display: grid;
  //place-items: center;
  margin-left: 0px;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 0px 5px 0px;
  background-color: #0e0f29;
  color: #ffffff;
  border-radius: 8px;
  height: 50px;
}

.bg-abc-wallet-begin {
  //display: grid;
  //place-items: center;
  margin-left: 0px;
  // margin-bottom: 5px;
  width: 75%;
  padding: 5px 0px 5px 0px;
  background-color: #0e0f29;
  color: #ffffff;
  border-radius: 8px;
  height: 50px;
}

.bg-abc-wallet-begin-mb {
  //display: grid;
  //place-items: center;
  margin-left: 0px;
  // margin-bottom: 5px;
  width: 62%;
  padding: 5px 0px 5px 0px;
  background-color: #0e0f29;
  color: #ffffff;
  border-radius: 8px;
  height: 50px;
}

.col-1 {
  &-bg-color {
    background-color: #2f8af533;
  }
}

.meta-connect {
  //display: grid;
  //place-items: center;
  margin-left: 0px;
  //margin-bottom: 5px;
  width: 100%;
  //padding: 5px 0px 5px 0px;
  //background-color: #2f8af533;
  //border-radius: 4px;
}

.meta-wallet {
  margin-left: 0px;
  width: 100%;
}

.space-width {
  width: 88px;
}

.space-between {
  width: 95px;
}

.space-bw {
  width: 55px;
}

.name-nft-card {
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-title-chain);

  @media (max-width: 639px) {
    // font-size: 10px;
  }
}

.text-over-ellip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price-type {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 82px;
  height: 20px;
  border-radius: 16px;
  background-color: #2c2c2c;
  font-size: 12px;
  font-weight: 700;
  //text-transform: uppercase;
  color: $purple-color-primary;
  text-align: center;
  padding: 1px 0;

  @media (max-width: 639px) {
    font-size: 10px;
    width: 70px;
    padding: 2px 0;
  }
}

.price-type-variant {
  @extend .price-type;

  &.variant-1 {
    top: 23px;
  }
}

.select-htr {
  width: 120px;
  height: 47px;
}

.disabled {
  background-color: var(--bg-button-disabled);
  cursor: not-allowed;
}

.object-fit-cover {
  object-fit: cover;
}

// hide arrow input type number
/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: none;
}

.bg-clicked-filter {
  background: rgba(255, 255, 255, 0.1);
}

.bg-clicked-item-filter {
  background: var(--bg-color-clicked-item-filter);
}

@media (max-width: 768px) {
  .d-xs-grid {
    display: grid !important;
  }
}

.MuiStack-root {
  padding-top: 0px !important;
  width: 100%;

  .MuiTextField-root {
    min-width: 220px !important;

    .MuiInputBase-root {
      color: white;

      .MuiInputBase-input {
        padding: 13.5px 24px;
        font-size: 16px;
        text-align: center;
        color: var(--bd-color-calendar-text);
        cursor: pointer;
      }

      .MuiOutlinedInput-notchedOutline {
        border: var(--bd-color-calendar);
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  .MuiStack-root {
    padding-top: 0px !important;
    width: 100%;

    .MuiTextField-root {
      min-width: 140px !important;

      .MuiInputBase-root {
        color: white;

        .MuiInputBase-input {
          padding: 8px;
          font-size: 11px;
          text-align: center;
        }

        .MuiOutlinedInput-notchedOutline {
          border: var(--bd-color-calendar);
          border-radius: 6px;
        }
      }
    }
  }
}

.MuiDialog-container {
  .MuiTabs-root,
  .MuiPickersLayout-actionBar,
  .MuiTypography-overline,
  .MuiTypography-subtitle1,
  .MuiTypography-h4 {
    display: none;
    font-family: Roboto, serif !important;
  }

  .MuiPickersToolbar-content {
    justify-content: space-between;
    border: solid;
    border-color: #525298;
    border-radius: 8px;
    border-width: 1px;
    background-color: #202052;
    width: 100%;
    padding: 2px 16px;
  }

  .MuiDateTimePickerToolbar-timeContainer {
    width: 100%;
  }

  .MuiTypography-root {
    font-size: 16px !important;

    .MuiPickersToolbarText-root {
      line-height: 3.167 !important;
    }

    .MuiTypography-h3 {
      color: #2f8af5 !important;
    }
  }

  .MuiDialog-paper {
    background-color: #23235c;
    color: #8787d6;
    border-radius: 16px;
  }

  .MuiDateTimePickerToolbar-separator {
    line-height: 3.167 !important;
    margin: 0 4px 0 4px !important;
  }

  .MuiPickersLayout-root {
    display: flex;
    flex-direction: column-reverse;
  }

  .MuiDateTimePickerToolbar-ampmSelection {
    display: flex !important;
    flex-direction: row !important;
    margin-left: auto !important;
    margin-right: 0 !important;
    gap: 16px;
  }
}

.word-break-history {
  width: 130px;
  overflow-wrap: break-word;
  inline-size: 130px;
  height: 48px;
}

.bg-circle-button {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bg-circle-button), var(--bs-bg-opacity)) !important;
}

.bg-oval-button {
  width: 75px;
  height: 90px;
  flex-shrink: 0;
  --bs-bg-opacity: 1;
  border-radius: 8px;
  background-color: var(--bg-button-coin);
}

.bg-square-button {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  --bs-bg-opacity: 1;
  border-radius: 8px;
  background-color: var(--bg-button-coin);
}

.bg-oval-button-klaytn-clicked {
  width: 75px;
  height: 90px;
  flex-shrink: 0;
  --bs-bg-opacity: 1;
  border-radius: 8px;
  background: var(--bg-button-coin-clicked);
  border: 1px solid #528cfb !important;
}

.bg-sign-blue-button {
  display: flex;
  width: 370px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  --bs-bg-opacity: 1;
  border-radius: 36px;
  background: var(--blue-point, #2f8af5);

  &:hover {
    background-color: #0461cf;
  }
}

.bg-sign-gray-button {
  display: flex;
  width: 370px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  --bs-bg-opacity: 1;
  border-radius: 36px;
  background: #adb5bd;

  &:hover {
    background-color: #ced4da;
  }
}

.bg-copy-invite-button {
  display: flex;
  width: 180px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: var(--blue-point, #2f8af5);

  &:hover {
    background-color: #0461cf;
  }
}
.bg-copy-invite-not-login {
  display: flex;
  width: 180px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: #adb5bd;
}
.outline-search-history {
  border: var(--boder-search-history);
}

.title-input-modal {
  color: var(--font-color-03);
}

.boder-top-accordion {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .accordion-button {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.boder-bottom-accordion {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.my-tooltip {
  .multi-line {
    width: 250px;
    white-space: normal;
    text-align: left !important;
  }
}

.opacity-1 {
  opacity: 1;
}

.horizontal-scroll {
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  clear: both;
  width: 100%;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // @media (max-width: 1024px) {
  //   -ms-overflow-style: inherit; /* IE and Edge */
  //   scrollbar-width: thin; /* Firefox */
  //   &::-webkit-scrollbar {
  //     display: block;
  //   }
  // }
  &::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (max-width: 768px) {
    &::-webkit-scrollbar {
      height: 4px;
    }
  }
}

@media (max-width: 639px) {
  .bg-sign-blue-button {
    display: flex;
    width: 180px;
    height: 40px;
    padding: 16px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--blue-point, #2f8af5);
    margin-bottom: 22px;
  }

  .bg-sign-gray-button {
    display: flex;
    width: 180px;
    height: 40px;
    padding: 16px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: #adb5bd;
    margin-bottom: 22px;
  }

  .bg-copy-invite-button {
    display: flex;
    width: 129px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--blue-point, #2f8af5);
  }
  .bg-copy-invite-not-login {
    display: flex;
    width: 129px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: #adb5bd;
  }
  .bg-oval-button {
    width: 65px;
    height: 80px;
    flex-shrink: 0;
    --bs-bg-opacity: 1;
    border-radius: 8px;
    background-color: var(--bg-button-coin);
  }

  .bg-oval-button-klaytn-clicked {
    width: 65px;
    height: 80px;
    flex-shrink: 0;
    --bs-bg-opacity: 1;
    border-radius: 8px;
    background: var(--bg-button-coin-clicked);
    border: 1px solid #528cfb !important;
  }

  .bg-square-button {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    --bs-bg-opacity: 1;
    border-radius: 8px;
    background-color: var(--bg-button-coin);
  }
}

.position-right-20 {
  right: -12px;
}

.boder-color-faucet {
  border-color: #484855 !important;
}

.line-reward {
  border-bottom: 1px solid var(--color-line-reward);
  margin-top: 16px;
  margin-bottom: 24px;

  @media (max-width: 840px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.justify-content-space-between {
  justify-content: space-between;
}

.btn-claim-reward {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background-color: #323249;
  font-weight: 700;
}

.color-reward {
  color: #8d8d8d !important;
}

.align-item-center {
  align-items: center;
  line-height: 16.41px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.color-inherit {
  color: inherit;
}

.padding-left-unset {
  padding-left: unset;
}

/* tooltip coming soon  */
.wrapper-bonk-tooltip {
  z-index: 99999;
}

.wrapper-bonk-tooltip .tooltip-arrow {
  display: none !important;
}

.wrapper-bonk-tooltip .tooltip-inner {
  // background: #f0f5ff;
}

.box-bonk-tooltip {
  position: absolute;
  /* left: 50%; */
  transform: translatex(-50%);
  top: 10px;
  background: transparent;
  font-size: 10px;
  color: #2061e2;
  background: #ffffff;
  height: 20px;
  width: 80px;
  border-radius: 30px;
  border: 1px solid #2061e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

/* tooltip traits */
.wrapper-trait-tooltip {
  z-index: 98;
}

.wrapper-trait-tooltip .tooltip-arrow {
  display: none !important;
}

.wrapper-trait-tooltip .tooltip-inner {
  // background: #f0f5ff;
}

.box-trait-tooltip {
  position: absolute;
  /* left: 50%; */
  transform: translatex(-50%);
  top: 10px;
  background: transparent;
  font-size: 10px;
  color: #2061e2;
  background: #ffffff;
  // height: 20px;
  padding: 5px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #2061e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: left;
}

.tooltip-box-trait {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
  color: #92929f !important;
  font-size: 12px !important;
  line-height: 18px !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
  max-width: 240px !important;
  font-weight: 400;
}
.tooltip-box-trait::after {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
}
.color-aaa {
  color: #aaaaaa;
}

.color-2f8af5 {
  color: #2f8af5;
}

.color-777 {
  color: #777;
}

.color-525298 {
  color: #525298;
}

.color-8d8d8d {
  color: #8d8d8d;
}

.color-dbdbdb {
  color: #dbdbdb;
}

.color-8787d6 {
  color: #8787d6;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.over-flow-x-hidden {
  overflow-x: hidden !important;
}

.p-t-11 {
  padding-top: 11px;
}

.text-2f8af5 {
  color: #2f8af5 !important;
}

.bg-button-fixed {
  width: 100%;
  z-index: 99;
  position: fixed;
  bottom: 0px;
  left: 0px;
  border-radius: 20px 20px 0 0;
  background-color: var(--background-modal);
  padding: 24px 34px 32px 34px;
}

.border-desciption {
  border: var(--border-box-collection);
  border-radius: 4px;
  padding: 16px;
}

.form-check-input {
  --bs-form-check-bg: var(--background-color);
  border: var(--border-checkbox);
  cursor: pointer;
  width: 13px;
  height: 13px;
  margin-top: 0;
}

.form-check-label {
  cursor: pointer;
}

.w-12 {
  width: 12%;
}

.w-6 {
  width: 6%;
}

.price-type-box {
  background-color: #000000;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
}

.price-type-box {
  background-color: #777777;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 16px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 16px;
  }
}

.collectibles-item-amount {
  background-color: #2f8af5b2;
  font-weight: 700;
  font-size: 10px;
  // line-height: 12px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 16.64px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
}

.collectibles-item-detail-amount {
  background-color: #2f8af5b2;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 16.64px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  @media (max-width: 768px) {
    top: 16px;
    right: 16px;
  }
}

.button-transfer-box {
  height: 30px;
  padding: 0;

  &:hover {
    svg {
      circle {
        fill: #0461cf;
      }
    }
  }

  @media (max-width: 768px) {
    height: 26px;

    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.disabled-send {
  svg {
    circle {
      fill: #0e0f29;
    }

    path {
      fill: #8787d6;
    }

    rect {
      fill: #8787d6;
    }
  }

  &:hover {
    svg {
      circle {
        fill: #0e0f29;
      }
    }
  }
}

.top-8px {
  top: 8px;
}

.right-8px {
  right: 8px;
}

@media (max-width: 768px) {
  .top-xs-2px {
    top: 2px;
  }

  .right-xs-4px {
    right: 4px;
  }
}

.title-input-otp {
  color: var(--font-color-03);
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.padding-modal {
  padding: 24px 24px 32px 24px;

  @media (max-width: 768px) {
    padding: 20px 20px 24px 20px;
  }
}

.mode-color-8787d6 {
  color: var(--font-color-08) !important;
}

.mode-color-white {
  color: var(--font-color);
}

.h-fit-content {
  height: fit-content;
}

.referral-body {
  background: #ffffff1a;
  border-radius: 8px;
  padding: 16px;
  color: var(--font-color-03);
  font-size: 12px;
  line-height: 15px;
}

.bg-blue-primary {
  background-color: $blue-color-primary;

  &:hover {
    background-color: $blue-hover-primary;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #2f8af5;

  &-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.padding-24px-important {
  padding: 24px !important;
}

.color-08 {
  color: var(--font-color-08);
}

.color-08-important {
  color: var(--font-color-08) !important;
}

.lh-normal {
  line-height: normal;
}

.h-min-content {
  height: min-content;
}

// custom datetimepicker
.MuiDialog-paper {
  background-color: #23235c !important;
  color: #8787d6 !important;
  border-radius: 16px !important;
}

.MuiSvgIcon-root .MuiPickersCalendarHeader-switchViewIcon {
  color: #8787d6;
}

.MuiSvgIcon-root {
  color: #8787d6;
}

.MuiDayCalendar-weekDayLabel {
  color: #8787d6 !important;
}

.MuiPickersDay-root {
  color: #8d8d8d !important;
}

.MuiPickersDay-root.Mui-selected {
  color: #ffffff !important;
  background-color: #2f8af5 !important;
}

.MuiPickersToolbarText-root {
  color: #2f8af5 !important;
}

.MuiPickersToolbarText-root .MuiDateTimePickerToolbar-separator {
  color: #2f8af5 !important;
}

.MuiTypography-root.MuiTypography-subtitle2 {
  color: #525298 !important;
}

.MuiTypography-root.MuiPickersToolbarText-root.Mui-selected {
  color: #2f8af5 !important;
}

button.MuiPickersYear-yearButton.Mui-selected {
  background-color: #2f8af5 !important;
}

//clock
.MuiClock-pin {
  background-color: #2f8af5 !important;
}

.MuiClockPointer-root {
  background-color: #2f8af5 !important;
}

.MuiClockPointer-thumb {
  background-color: #2f8af5 !important;
  border: 16px solid #2f8af5 !important;
}

span.MuiClockNumber-root {
  color: #8d8d8d !important;
}

button.MuiButtonBase-root.MuiPickersDay-root {
  font-size: 16px;
}

.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
  min-height: 200px;
}

.MuiDateCalendar-root {
  height: fit-content !important;
}

.btn-disable {
  &:disabled {
    opacity: 0.7;
    background-color: #807f7f;
  }
}

.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}

.color-92929f {
  color: #92929f;
}

div#helpTooltip {
  background: #26263f;
  border-radius: 6px;
  border: 1px solid #484855;
  max-width: 332px;
  min-width: 332px;
  padding: 16px;
  opacity: 1;

  @media (max-width: 768px) {
    left: calc((100% - 332px) / 2) !important;

    &::after {
      visibility: hidden;
    }
  }

  &::after {
    background: #26263f;
    border: 1px solid #484855;
  }

  &::before {
    background: #26263f;
  }
}
.outline-unset {
  outline: unset;
}
.color-white {
  color: white;
}
.input-total-token {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #616189;
  font-size: 16px;
  line-height: 18.75px;
  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
    font-size: 10px;
    line-height: normal;
  }
}
.img-about{
  float: left;
  margin-right: 10px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.img-about img{
  object-fit: cover;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
  }
}

.item-about-container p{
  text-align: left;
  white-space: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.terminal-scroll-adm {
  max-width: 462px;
  overflow: hidden;
  overflow-y: auto;
  clear: both;
  word-wrap: break-word;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}


.item-mint-admin{
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  gap: 8px;

  .content-01{
    min-width: 107px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    color: #8787D6;
  }
  .content-02{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    word-break: break-all;
    text-align: right;
    color: #FFFFFF;
  }
}

.tooltip-mint-admin {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
  color: #777777 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  border-radius: 8px !important;
  padding: 16px !important;
  max-width: 240px !important;
  font-weight: 400;
  word-break: break-all;
}
.tooltip-mint-admin::after {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
}

.tooltip-token-uri {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
  color: #777777 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  border-radius: 8px !important;
  padding: 16px !important;
  max-width: 240px !important;
  font-weight: 400;
}
.tooltip-token-uri::after {
  border: 1px solid #484855 !important;
  background-color: #26263f !important;
}

