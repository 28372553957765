.aggregate-user-infor {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 10px;
  gap: 16px;
  width: 282px;
  height: auto;
  background: var(--background-bottom-box-card);
  border-radius: 8px;
  font-size: 14px;
  .mobile-hiden {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .accordion-button {
    background: var(--background-accordion-header);
  }
  .accordion-body {
    background: transparent;
  }
  .accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #40406c !important;
  }
  .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  }
  .accordion-button::after {
    position: absolute;
    left: 50%;
    bottom: 0;
  }
  .select-infor-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 256px;
    height: 48px;
    // background: var(--bg-select-infor);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    color: var(--font-color-08);
    &:hover {
      background: #2f8af5;
      .img-select-infor {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
      .title-select-infor {
        color: #ffffff;
      }
    }
  }
  .focus-select-infor {
    background: #2f8af5;
    .img-select-infor {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
    .title-select-infor {
      color: #ffffff;
    }
  }
  @media (max-width: 1279px) {
    width: 100%;
    .select-infor-item {
      width: 100%;
    }
  }

  @media (max-width: 1023px) {
    //display: none;
  }
  .border-dark {
    border: 1px solid #40406c !important;
  }
}
// .accordion-button::after {
//   display: none;
// }
.width-without-dropdown {
  width: 110% !important;
  border-bottom: 1px dashed #40406c;
  padding-bottom: 8px;
  margin-bottom: 2px;
}
