@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@keyframes fade-in {
  0% {
    box-shadow: 0 0 0 hsl(0deg 0% 95% / 0%);
    opacity: 0;
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }

  1% {
    box-shadow: 0 0 0 hsl(0deg 0% 95% / 0%);
    opacity: 0;
    -webkit-transform: scale(0.96) translateY(10px);
    transform: scale(0.96) translateY(10px);
  }

  100% {
    box-shadow: 0 0 500px hsl(0deg 0% 95% / 0%);
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }
}

.popup-content {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Poppins,
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  .modal_collectibles {
    animation: 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards fade-in;
    height: max-content;
    margin: auto;
    padding: 24px 50px 44px;
    color: white;
    outline: none;
    min-width: 600px;
    min-height: 744px;
    background: #23235c;
    border-radius: 30px;
    .contents {
      position: relative;
      text-align: center;
      height: 100%;
      min-height: 400px;
      width: 100%;
      margin: 0px auto;

      svg {
        // vertical-align: middle;
      }

      .flex {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        padding-top: 10px;
      }

      .titles {
        padding-bottom: 24px;
        height: 44px;
        display: flex;

        .gift {
          margin-bottom: 0px;
        }

        .title {
          font-size: 26px;
          font-weight: 700;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin: 0px auto;
          text-align: center;

          > svg {
            margin-right: 10px;
          }

          .icon {
            height: 32px;
            width: 32px;

            .modal_svg_path {
              fill: rgb(92, 189, 109);
            }
          }
        }

        .close_button {
          position: absolute;
          top: 0px;
          right: 0px;
          width: fit-content;

          .button {
            background-color: transparent;
            border: none;
            padding: 0;
          }
        }
      }

      .img_box {
        display: flex;
        position: relative;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        padding: 24px 0px;
        border-top: 1px solid #ffffff14;

        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }
      }

      .text_sending {
        color: white;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 24px;
        .purple_text {
          color: #2f8af5;
          // display: inline-block;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 700;
        }
      }
      .input_address_box {
        margin: auto;
        // padding-top: 24px;
        .input_address {
          height: 10%;
          width: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 16px;
          border-radius: 10px;
          background: rgba($color: #ffffff, $alpha: 0.1);
          color: #aaaaaa;
          font-size: 16px;
          outline: none;
          border: none;
        }
      }

      .input_address_text {
        width: 100%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding-top: 24px;
        color: white;
        font-size: 16px;
        opacity: 0.7;
      }

      .text_small {
        padding-top: 24px;
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        .purple_text {
          color: #2f8af5;
          display: inline-block;
        }
      }

      .warning {
        font-size: 20px;
        color: #FC615A;
        margin-bottom: 24px;
        display: flex;
        gap: 8px;
        text-transform: uppercase;
        justify-content: center;
        .warning_svg {
          width: 25px;
          height: 25px;
          vertical-align: sub;
        }
      }

      .warning_text {
        font-size: 14px;
        line-height: 20px;
        margin: auto;
        color: white;
        text-align: justify;
      }

      .success {
        font-size: 24px;
        color: white;
        padding-bottom: 22px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        gap: 8px;
        img {
          vertical-align: middle;
        }
      }

      .understand {
        padding-top: 24px;

        .link {
          text-decoration: none;
        }

        .understand_button {
          border: none;
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Poppins, 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
          text-transform: none;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          position: relative;
          height: 60px;
          border-radius: 10px;
          width: 100%;
          color: white;
          padding: 24px;
          background: #2f8af5;
        }
      }
    }
  }
  .sending-modal,
  .success-modal {
    min-width: 600px;
    min-height: 614px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: unset;
    .modal_collectibles {
      min-width: unset;
      min-height: unset;
      // height: 675px;
      width: 100%;
      height: 100%;
      border-radius: unset;
      .contents {
        .titles {
          .title {
            font-size: 17px;
          }
        }
        .warning_text {
          font-size: 11px;
        }
      }
    }
  }

  @include Mobile() {
    .modal_collectibles {
      border-radius: 0;
      min-width: auto;
      height: 100%;
      padding: 24px 20px 44px;
      .contents {
        .titles {
          .title {
            font-size: 17px;
          }
        }
        .warning_text {
          font-size: 11px;
        }
      }
    }
  }

}
