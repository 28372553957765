/* import font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&family=Poppins:wght@300;400;500;600;700&display=swap');

.web3-landing-main {

}

.web3-landing-main > .header {
  background: linear-gradient(90deg, #2061e2, #af7dff);
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
}

.web3-landing-main > .header > img {
  width: 180px;
}

.web3-landing-main > .header > .position-round {
  position: absolute;
}

.web3-landing-main > .header > .position-round > .round1 {
  width: 500px;
  left: -435px;
  top: -100px;
  z-index: 0;
  position: absolute;
}

.web3-landing-main > .header > .position-round > .round2 {
  width: 350px;
  position: absolute;
  top: -100px;
  left: -180px;
}

.web3-landing-main > .body {
  text-align: center;
}
.web3-landing-main > .body > .title {
  padding: 40px 0;
}

.web3-landing-main > .body > .title > h1 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
}

.web3-landing-main > .body > .sub > h5 {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  line-height: 14px;
}