@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

.ko {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}
.en {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}
.main-aquarium-01 {
  position: relative;
  background-color: #02021e;
  .more-wrapper {
    position: fixed;
    bottom: 2.5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    cursor: pointer;
    .more-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: #FFFFFF;
      img {
        width: 30px;
      }
    }
  }
  .hide {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
  .appear {
    opacity: 1;
    transition: opacity 0.3s linear;
  }
  .background-image-aquarium {
    position: absolute;
    background: linear-gradient(
        to bottom,
        rgba(2, 2, 30, 0) 0%,
        rgba(2, 2, 30, 0.25) 70%,
        rgba(2, 2, 30, 0.5) 80%,
        rgba(2, 2, 30, 0.75) 90%,
        rgba(2, 2, 30, 1) 100%
      ),
      url(../../../assets/img/aquarium_01_3.png);
    // background-image: url(../../../assets/img/aquarium_01_4.png);
    height: 1000px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% center;
    color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
  }
  @media (max-width: 540px) {
    .more-wrapper {
      display: none;
    }
    .background-image-aquarium {
      position: absolute;
      background: linear-gradient(
          to bottom,
          rgba(2, 2, 30, 0) 0%,
          rgba(2, 2, 30, 0.25) 70%,
          rgba(2, 2, 30, 0.5) 80%,
          rgba(2, 2, 30, 0.75) 90%,
          rgba(2, 2, 30, 1) 100%
        ),
        url(../../../assets/img/aquarium_01_mobile_2.png);
      // background-image: url(../../../assets/img/aquarium_01_3.png);
      height: 935px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% center;
      color: #ffffff;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .aquarium1402-1 {
    .lang-switch {
      // padding: 5%;
      padding: 60px 40px 180px;
      max-width: $page-content-width;
      width: 100%;
      margin: auto;
      font-weight: 500;
      display: flex;
      justify-content: end;
      position: relative;
      color: #ffffff;
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      .en {
        padding: 0 10px;
        cursor: pointer;
      }
      .kr {
        padding: 0 10px;
        cursor: pointer;
      }
    }
    .section-01 {
      max-width: $page-content-width;
      width: 100%;
      height: 700px;
      padding: 0 40px;
      margin: auto;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: inherit;
      gap: 270px;
      .header-content {
        position: relative;
        font-weight: 700;
        font-size: 64px;
        line-height: 75px;
        text-align: center;
        text-shadow: 0px 0px 30px rgba(0, 0, 0);
      }
      .bottom-content {
        // min-height: 242px;
        background: linear-gradient(180deg, rgba(2, 2, 30, 0) 0%, #02021e 100%);
        padding-bottom: 190px;
        .box {
          position: relative;
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(5px);
          border-radius: 30px;
          .wrapper-sounhong {
            padding: 20px 50px;
            width: 100%;
            display: flex;
            border-radius: 12px;
            gap: 30px;
            .avt-sounhong {
              min-width: 120px;
              height: 120px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .about-sounhong {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              text-align: justify;
              display: grid;
              gap: 12px;
              .about-sidescape {
                // font-weight: 400;
                font-weight: 300;
              }
            }
          }
        }
        .box::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: 30px;
          padding: 1px;
          // background: linear-gradient(
          //   180deg,
          //   rgba(107, 106, 106, 1) 0%,
          //   rgba(107, 106, 106, 0) 100%
          // );
          background: linear-gradient(
              180deg,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.1),
              rgba(255, 255, 255, 0.1)
            );

          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          height: 100%;
        }
      }
    }
    .section-02 {
      // min-height: 950px;
      height: 100%;
      background-color: #02021e;
      color: #ffffff;
      max-width: $page-content-width;
      width: 100%;
      padding: 0px 40px 70px;
      margin: auto;
      .wrapper-header {
        padding: 0 57px 64px;
        // font-weight: 700;
        font-size: 44px;
        line-height: 52px;
        text-align: center;
        position: relative;
        z-index: 1;
        .title {
          text-align: center;
          font-weight: 700;
        }
        .content-header {
          font-weight: 400;
          color: #aaaaaa;
          font-size: 16px;
          line-height: 19px;
          padding-top: 12px;
        }
      }
      .wrapper-bottom {
        // border: 1px solid #444444;
        border-radius: 30px;
        padding: 24px 24px 29px;
        display: flex;
        gap: 24px;
        align-items: center;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        position: relative;
        border-radius: 30px;
        .image-aquarium {
          flex: 1 1 50%;
          max-width: 564px;
          max-height: 366px;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .wrapper-infor-nft {
          flex: 1 1 50%;
          // font-weight: 400;
          font-weight: 300;
          font-size: 16px;
          line-height: 26px;
          text-align: justify;
          .title {
            font-size: 24px;
            font-weight: 700;
          }
          .info-nft-ul {
            position: relative;
          }
          a {
            color: #aaaaaa;
          }
          a:hover {
            color: unset;
          }
          .btn-buy-now {
            background: #2f8af5;
            border-radius: 8px;
            width: 127px;
            height: 49px;
            margin-top: 16px;
          }
        }
      }
      .wrapper-bottom::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 30px;
        padding: 1px;
        background: linear-gradient(
          180deg,
          rgba(107, 106, 106, 1) 0%,
          rgba(107, 106, 106, 0) 100%
        );
        // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
        // linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        height: 100%;
      }
    }
    .vector-background-left {
      position: absolute;
      // width: 552.07px;
      width: 350px;
      height: 804.13px;
      left: -156.94px;
      top: 800px;
      // background: rgba(5, 0, 255, 0.33);
      background: rgba(0, 255, 209, 0.33);
      filter: blur(200px);
      transform: rotate(-100.76deg);
    }
    .vector-background-right {
      position: absolute;
      width: 380.4px;
      height: 756.35px;
      right: -200px;
      top: 850px;
      // background: rgba(173, 0, 255, 0.35);
      background: rgba(4, 62, 149, 0.35);
      filter: blur(80.2901px);
      transform: rotate(15.67deg);
    }
    .wrapper-wave-border {
      position: absolute;
      left: 0%;
      right: 0%;
      // top: 84.4%;
      bottom: -1.5%;
      margin: auto;
      text-align: center;
      display: flex;
      // height: 330px;
      img {
        width: 100vw;
      }
    }
    @media (max-width: 1920px) {
      .section-02 {
        // padding: 120px 40px;
      }
    }
    @media (max-width: 1900px) {
      .vector-background-left {
        width: 250px;
        left: -300px;
        // top: 800px;
      }
    }
    @media (max-width: 768px) {
      .section-01 {
        .header-content {
          font-size: 48px;
        }
        .bottom-content {
          .box {
            .wrapper-sounhong {
              padding: 26px 20px;
            }
          }
        }
      }
      .section-02 {
        .wrapper-bottom {
          flex-direction: column;
        }
      }
      .vector-background-left {
        width: 200px;
        left: -300px;
        top: 800px;
      }
      .vector-background-right {
        left: unset;
        right: -300px;
        top: 1400px;
      }
    }
    @media (max-width: 540px) {
      .lang-switch {
        padding: 24px 32px 140px;
      }
      .section-01 {
        padding: 0px 16px 24px;
        gap: 140px;
        .header-content {
          font-size: 32px;
          line-height: 48px;
        }
        .bottom-content {
          .box {
            border-radius: 24px;
            .wrapper-sounhong {
              flex-direction: column;
              gap: 12px;
              align-items: center;
              .avt-sounhong {
                min-width: unset;
                width: 120px;
              }
              .about-sounhong {
                .name-sounhong {
                  text-align: center;
                  // padding-bottom: 12px;
                }
              }
            }
          }
          .box::before {
            border-radius: 24px;
          }
        }
      }
      .section-02 {
        padding: 10px 16px 35px;
        .wrapper-header {
          padding: 0 0 36px;
          font-size: 24px;
          line-height: 32px;
          .content-header {
            text-align: justify;
          }
        }
        .wrapper-bottom {
          padding: 20px 16px;
          border-radius: 24px;
          .wrapper-infor-nft {
            .title {
              text-align: center;
            }
            .info-nft-ul {
              position: relative;
              font-weight: 400;
              font-size: 16px;
              line-height: 26px;
            }
            a {
              color: #aaaaaa;
            }
            a:hover {
              color: unset;
            }
            .btn-buy-now {
              display: grid;
              place-items: center;
              align-items: center;
              margin: 20px auto 0;
            }
          }
        }
        .wrapper-bottom::before {
          border-radius: 24px;
        }
      }
      .vector-background-right {
        right: -300px;
        top: 1250px;
      }
      .wrapper-wave-border {
        bottom: -0.5%;
      }
    }
    @media (max-width: 320px) {
      .more-wrapper {
        display: none;
      }
      .section-01 {
        .header-content {
          font-size: 26px;
          line-height: 40px;
        }
      }
    }
  }
}
