//home page
// homepage PC
.home-page {
  min-height: calc(100vh - 50px - 215px - 65px);
  // background: #02021e;
  // section-01
  .section-01 {
    // height: 3000px;
    width: 100%;
    margin: 0px auto;
    position: relative;
    // padding-top: 114px;
    padding-bottom: 40px;
    .background-section-01 {
      position: absolute;
      width: 100%;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      filter: blur(100px);
      height: 500px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .background-section-01-mobile {
      img {
        display: none;
      }
    }
    .content-header {
      // inset: 0;
      position: relative;
      width: 100%;
      max-width: $page-content-width;
      // padding-top: 200px;
      padding: 200px 40px 30px 40px;
      // top: 200px;
      // right: 40px;
      // bottom: 30px;
      // left: 40px;
      z-index: 1;
      margin: auto;
      color: var(--font-color-title);

      .text-head {
        font-size: 50px;
        margin-bottom: 5px;
        line-height: 54px;
        // font-family: VennExBold, sans-serif;
        font-style: normal;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
      }

      .text-bottom {
        font-size: 22px;
        max-width: 530px;
        // margin-bottom: 30px;
        // font-family: venn, sans-serif;
        font-style: normal;
        font-weight: 500;
      }
      .text-head-mobile {
        display: none;
      }
    }
    .custom-slider-main {
      // padding-top: 114px;
      max-width: 1200px;
      margin: 0 auto;
      &:hover {
        .arrow-first-carousel {
          img {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
      .slick-list {
        border-radius: 15px;
      }
      // arrow first carousel custom
      .arrow-first-carousel {
        width: 40px;
        height: 40px;
        z-index: 1;
        &::before {
          content: unset;
          font-size: 40px;
        }
        img {
          width: 100%;
          height: 100%;
          opacity: 0.2;
          cursor: pointer;
        }
      }
      .slick-prev {
        left: -15px;
      }
      .slick-next {
        right: -15px;
      }
      .slide-item {
        cursor: pointer;
        & > div {
          margin-inline: 8px;
          position: relative;
        }
        .slide-item-info {
          position: absolute;
          inset: 0;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 17.58%,
            rgba(0, 0, 0, 0.416038) 53.89%,
            rgba(0, 0, 0, 0.7) 91.93%
          );
          border-radius: 15px;
          padding: 18px 16px 28px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .slide-content-bottom {
            display: flex;
            justify-content: space-between;
            max-height: 86px;
            color: #ffffff;
            align-items: end;
            padding: 0 14px;
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 24px;
                font-weight: 700;
                height: 26px;
                text-transform: uppercase;
              }
              .slide-total {
                font-size: 16px;
                color: #ffffffb2;
                height: 9px;
                padding-top: 8px;
              }
            }
            .btn-view-drops {
              display: none;
            }
          }
        }
      }
      .slick-slide {
        height: 500px;
        transition: width 500ms;
        & * {
          height: 100%;
        }
        .wrapper-editions {
          border-radius: 100%;
          background-color: black;
          width: 36px;
          height: 36px;
          padding: 6px;
          .icon-eternal {
            width: 24px;
            border-radius: 100%;
          }
          .title-eternal {
            display: none;
          }
        }
        &:has(+ .slick-current) {
          .wrapper-editions {
            display: flex;
            justify-content: center;
            padding: 8px;
            gap: 8px;
            align-items: center;
            border-radius: 15px;
            background-color: black;
            width: 220px;
            height: 40px;
            .icon-eternal {
              width: 24px;
              border-radius: 100%;
            }
            .title-eternal {
              //text-transform: uppercase;
              font-size: 16px;
              color: white;
              text-wrap: nowrap;
              display: block !important;
              font-weight: 800;
            }
          }
          .slide-info-bottom {
            .slide-title-bottom {
              font-size: 40px !important;
              height: 46px !important;
            }
            .slide-total {
              font-size: 28px !important;
              height: 30px !important;
            }
          }
          .btn-view-drops {
            width: 168px;
            height: 60px;
            background: #00000080;
            display: grid !important;
            place-items: center;
            font-size: 20px;
            font-weight: 500;
            color: #ffffffb2;
            border-radius: 16px;
            cursor: pointer;
            &:hover {
              background: #000000;
              color: #ffffff;
            }
          }
        }
        &.slick-current {
          .wrapper-editions {
            display: flex;
            justify-content: center;
            padding: 8px;
            gap: 8px;
            align-items: center;
            border-radius: 15px;
            background-color: black;
            width: 220px;
            height: 40px;
            .icon-eternal {
              width: 24px;
              border-radius: 100%;
            }
            .title-eternal {
              //text-transform: uppercase;
              font-size: 16px;
              color: white;
              text-wrap: nowrap;
              display: block !important;
              font-weight: 800;
            }
          }
          .slide-info-bottom {
            .slide-title-bottom {
              font-size: 40px !important;
              height: 46px !important;
            }
            .slide-total {
              font-size: 28px !important;
              height: 30px !important;
            }
          }
          .btn-view-drops {
            width: 168px;
            height: 60px;
            background: #00000080;
            display: grid !important;
            place-items: center;
            font-size: 20px;
            font-weight: 500;
            color: #ffffffb2;
            border-radius: 16px;
            cursor: pointer;
            &:hover {
              background: #000000;
              color: #ffffff;
            }
          }
        }

        .img-event-banner {
          width: 100%;
          object-fit: cover;
          border-radius: 15px;
        }
      }
      @media (min-width: 1300px) {
        .slick-slide {
          transition: width 500ms;
          &:has(+ .slick-current) {
            width: 836px !important;
            .wrapper-editions {
              display: flex;
              justify-content: center;
              padding: 8px;
              gap: 8px;
              align-items: center;
              border-radius: 15px;
              background-color: black;
              width: 184px;
              height: 39px;
              .icon-eternal {
                width: 24px;
                border-radius: 100%;
              }
              .title-eternal {
                //text-transform: uppercase;
                font-size: 16px;
                color: White;
                text-wrap: nowrap;
                display: block !important;
                font-weight: 800;
              }
            }
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 40px !important;
                height: 46px !important;
              }
              .slide-total {
                font-size: 28px !important;
                height: 30px !important;
              }
            }
            .btn-view-drops {
              width: 168px;
              height: 60px;
              background: #00000080;
              display: grid !important;
              place-items: center;
              font-size: 20px;
              font-weight: 500;
              color: #ffffffb2;
              border-radius: 16px;
              cursor: pointer;
              &:hover {
                background: #000000;
                color: #ffffff;
              }
            }
          }
          &.slick-current {
            width: 286px !important;
            .wrapper-editions {
              border-radius: 100%;
              background-color: black;
              width: 36px;
              height: 36px;
              padding: 6px;
              .icon-eternal {
                width: 24px;
                border-radius: 100%;
              }
              .title-eternal {
                display: none !important;
              }
            }
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 24px !important;
                font-weight: 700;
                height: 26px !important;
                text-transform: uppercase;
              }
              .slide-total {
                font-size: 16px !important;
                color: #ffffffb2;
                height: 9px !important;
                padding-top: 8px;
              }
            }
            .btn-view-drops {
              display: none !important;
            }
          }
        }
      }
      @media (max-width: 1240px) {
        .slick-prev {
          left: 15px;
        }
        .slick-next {
          right: 15px;
        }
      }
      @media (max-width: 1024px) {
        .slick-prev {
          left: 40px;
        }
        .slick-next {
          right: 40px;
        }
        .slick-slide {
          height: 182px;
          .slide-item-info {
            padding: 8px 10px 14px;
            .wrapper-editions {
              padding: 6px 10px;
              border-radius: 8px;
              width: 120px;
              height: 22px;
              .icon-eternal {
                width: 10px;
                border-radius: 100%;
              }
              .title-eternal {
                font-size: 9px;
                height: auto;
              }
            }
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 18px !important;
                height: 16px !important;
              }
              .slide-total {
                font-size: 12px !important;
                height: 10px !important;
                padding-top: 4px !important;
              }
            }
            .btn-view-drops {
              width: 78px;
              height: 26px;
              font-size: 10px;
              border-radius: 8px;
            }
            .slide-content-bottom {
              height: 32px;
              padding: 0 4px;
            }
          }

          &:has(+ .slick-current) {
            .wrapper-editions {
              padding: 6px 10px;
              border-radius: 8px;
              width: 120px;
              height: 22px;
              .icon-eternal {
                width: 10px;
                border-radius: 100%;
              }
              .title-eternal {
                font-size: 9px;
                height: auto;
              }
            }
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 18px !important;
                height: 16px !important;
              }
              .slide-total {
                font-size: 12px !important;
                height: 10px !important;
                padding-top: 4px !important;
              }
            }
            .btn-view-drops {
              width: 78px;
              height: 26px;
              font-size: 10px;
              border-radius: 8px;
            }
          }
          &.slick-current {
            .wrapper-editions {
              padding: 6px 10px;
              border-radius: 8px;
              width: 120px;
              height: 22px;
              .icon-eternal {
                width: 10px;
                border-radius: 100%;
              }
              .title-eternal {
                font-size: 9px;
                height: auto;
              }
            }
            .slide-info-bottom {
              .slide-title-bottom {
                font-size: 18px !important;
                height: 16px !important;
              }
              .slide-total {
                font-size: 12px !important;
                height: 10px !important;
                padding-top: 4px !important;
              }
            }
            .btn-view-drops {
              width: 78px;
              height: 26px;
              font-size: 10px;
              border-radius: 8px;
            }
          }
        }
      }
      @media (max-width: 768px) {
        .slick-prev {
          left: 38px;
        }
        .slick-next {
          right: 38px;
        }
      }
      @media (max-width: 639px) {
        .slick-prev {
          left: 10px;
        }
        .slick-next {
          right: 10px;
        }
        .arrow-first-carousel {
          width: 30px;
          height: 30px;
        }
      }
      //custom dots slick
      .slick-dots {
        bottom: -45px;
      }
      .slick-dots li {
        width: 16px;
        height: 16px;
      }
      .slick-dots li button {
        border: 2px solid #434343;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        background: #464661;
      }
      .slick-dots li button:before {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #464661;
        line-height: 18px;
      }
      .slick-dots li.slick-active button:before {
        color: #2f80ed;
      }
      @media (max-width: 1024px) {
        .slick-dots {
          bottom: -40px;
        }
        .slick-dots li {
          width: 8px;
          height: 8px;
        }
        .slick-dots li button {
          width: 8px;
          height: 8px;
          padding: 4px;
        }
        .slick-dots li button:before {
          width: 8px;
          height: 8px;
          font-size: 12px;
          top: -2px;
          left: 0px;
        }
      }
      @media (max-width: 639px) {
        .slick-dots li {
          margin: 0 3px;
        }
        .slick-dots li button {
          width: 6px;
          height: 6px;
          border-width: 1px;
        }
        .slick-dots li button:before {
          width: 8px;
          height: 8px;
          font-size: 10px;
          top: -3px;
          left: 0px;
        }
      }
    }
    .carousel-main-page {
      // margin-top: 200px;
      .custom-dot {
        bottom: -20px;
        .react-multi-carousel-dot--active button {
          background: $purple-color-primary;
        }
      }
      .home-carousel {
        z-index: 50;
        // padding-bottom: 20px;
        max-width: 1200px;
        margin: 0 auto;

        .slide-item {
          display: flex;

          & > * {
            flex: 1 1 100%;
            margin: 0 8px;

            img {
              border-radius: 20px;
              width: 100%;
              height: 100%;
              // aspect-ratio: 24/7;
              // max-height: 280px;
              // max-width: 1036px;
              aspect-ratio: 41/25;
              max-height: 500px;
              max-width: 820px;
              object-fit: cover;
              cursor: pointer;
              touch-action: none;
            }
          }
        }
      }
    }
  }
  //section 02
  .section-02 {
    color: var(--font-color-title);
    width: $page-content-width;
    padding: 0 40px;
    margin: auto;
    .featured-collections {
      // margin-bottom: 105px;
      padding-bottom: 0px;
      .wrapper-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: 100px auto 10px;
        padding: 80px 10px 10px;
        font-weight: 700;
        font-size: 28px;
        white-space: nowrap;
        .show-all-item {
          // font-size: 16px;
          // line-height: 45px;
          // color: #dc4de8;
          // font-weight: 700;
          width: 28px;
          .icon-see-all {
            width: 100%;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .spacing-featured {
        padding: 100px 10px 10px;
      }
      .grid-container-featured {
        // max-width: 1380px;
        width: 100%;
        position: relative;
        // margin: 120px auto 60px;
        place-items: center;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        .react-multi-carousel-item {
          display: flex;
          justify-content: center;
        }
        .custom-link {
          text-decoration: none;
          color: #000000;
          display: inline-block;
          width: 220px;
          height: 220px;
          // border-radius: 15px;
          margin: 10px;
          .grid-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: end;
            justify-content: space-between;
            border: var(--border-box-card);
            border-radius: 15px;
            // background-color: #333333;
            margin: 0;
            padding: 0;
            overflow: hidden;
            position: relative;
            box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
            //background-color: #ffffff;

            .banner-image {
              width: 100%;
              height: 110px;
              img {
                // position: absolute;
                top: 0px;
                height: 110px;
                width: 100%;
                z-index: 2;
                object-fit: cover;
                touch-action: none;
              }
            }

            .wrapper-content {
              padding: 0px 15px 15px;
              z-index: 3;
              flex: 0.5 1 0%;
              text-align: left;
              width: 100%;
              color: var(--font-color);
              background-color: var(--background-color);

              .avatar {
                position: absolute;
                top: 35%;
                z-index: 1;
                width: 60px;
                height: 60px;
                img {
                  min-width: 60px;
                  min-height: 60px;
                  max-width: 60px;
                  max-height: 60px;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  border: 2px solid #ffffff;
                  object-fit: cover;
                  background-color: #ffffff;
                }
              }

              .name-label {
                margin-top: 38px;
                margin-bottom: 2px;
                font-size: 16px;
                letter-spacing: -1px;
                font-weight: 700;
              }

              .total-item {
                font-size: 16px;
                color: var(--font-color-03);
                font-weight: 500;
              }
            }
          }
          &:hover {
            transform: scale(1.05);
            transition: 0.5s;
            .grid-item {
              border: 1px solid #2f8af5;
              box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
    .hover-featured-collections {
      &:hover {
        .arrow-featured-collections {
          .arrow-one {
            // background: #ffffff;
            opacity: 1;
          }
          .arrow-two {
            // background: #ffffff;
            opacity: 1;
          }
        }
      }
    }

    .page-grid {
      margin: 0 auto;
      &:last-child {
        margin: 0 auto 46px;
      }
      .title-header {
        font-weight: 700;
        font-size: 28px;
        // margin-bottom: 20px;
      }
      .hot-collectibles {
        z-index: 100;
        position: unset;
        .react-multi-carousel-track {
          .react-multi-carousel-item {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .custom-box {
          // background-color: var(--background-color);
          border-radius: 15px;
          display: flex;
          color: var(--font-color);
          width: 282px;
          padding: 10px 0;
          // border: 1px solid #444444;
          margin: auto;
          .hot-ollectibles-wrapper {
            position: relative;
            padding: 16px 24px;
            .hot-ollectibles-item:nth-child(1) {
              width: 100%;
              font-size: 12px;
              // margin: 10px 0px 0px;
              // padding: 0px 0px 0px 12px;
              // flex: 1 1 0%;
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .chain-name {
                font-weight: 400;
                font-size: 12px;
                color: #777777;
                text-transform: capitalize;
              }
            }
            // .hot-ollectibles-item:nth-child(2) {
            //   width: auto;
            //   text-align: right;
            //   // margin: 10px 12px 0 0;
            //   color: var(--color-title-chain);
            //   font-size: 12px;
            //   padding-left: 0px;
            //   text-transform: uppercase;
            // }
            .hot-ollectibles-item:nth-child(2) {
              padding: 8px 0 12px;
              display: flex;
              justify-content: center;
              width: fit-content;
              .img-token {
                width: 234px;
                height: 234px;
                position: relative;
                // margin: 32px auto;
                img,
                video {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  touch-action: none;
                  border-radius: 8px;
                }
              }
            }
            .hot-ollectibles-item:nth-child(3) {
              padding-bottom: 8px;
              .wrapper-item {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                // margin: 0 12px;
                font-size: 18px;
                font-weight: 700;
                font-size: 12px;
                // min-height: 52px;
                .content-left {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  gap: 4px;
                  color: var(--color-title-chain);

                  .avatar {
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      background-color: #ffffff;
                    }
                  }
                }
                // .content-left::after {
                //   background-color: transparent;
                //   background-position: 50% center;
                //   background-repeat: no-repeat;
                //   background-size: cover;
                //   content: '';
                //   display: block;
                //   margin: 0px 0px 0.3125rem 0.2rem;
                //   width: 0.55rem;
                //   height: 0.55rem;
                //   background-image: url('../../../assets/img/verify-icon.png');
                // }
                .content-right {
                  color: $purple-color-primary;
                  font-size: 12px;
                  // font-family: BrandonBlack, sans-serif;
                  text-transform: uppercase;
                  white-space: nowrap;
                }
              }
            }
            .hot-ollectibles-item:nth-child(4) {
              // display: flex;
              // justify-content: center;
              // text-align: center;
              .product-name {
                font-size: 16px;
                text-align: left;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 700;
                // margin: 12px;
              }
            }
            .hot-items-editions {
              // position: absolute;
              // top: 30px;
              // left: 10px;
              border-radius: 100%;
              background-color: #777777;
              width: 26px;
              height: 26px;
              display: grid;
              place-items: center;
              .icon-eternal {
                width: 24px;
                border-radius: 100%;
              }
            }
            .price-and-remaining {
              position: absolute;
              background: rgba(27, 27, 53, 0.5);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 9px;
              gap: 8px;
              left: 8px;
              bottom: 8px;
              width: 218px;
              border-radius: 8px;
              opacity: 0.9;
              color: #fff;
              .wrapper-price {
                width: 50%;
              }
              .wrapper-remaining {
                width: 50%;
              }
            }
            .wrapper-price-type {
              color: #fff;
              position: absolute;
              background: rgba(27, 27, 53, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              padding: 8px;
              gap: 8px;
              left: 8px;
              bottom: 8px;
              width: 218px;
              border-radius: 8px;
              opacity: 0.9;
              height: 57px;
              .price-type-home {
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .hover-carousel {
      &:hover {
        .custom-arrow-carousel {
          .arrow-one {
            // background: #ffffff;
            opacity: 1;
          }
          .arrow-two {
            // background: #ffffff;
            opacity: 1;
          }
        }
      }
    }
  }
  @media (min-width: 1921px) {
    .section-01 {
      .carousel-main-page {
        // margin-top: 20vw;
      }
    }
  }
  @media (max-width: 1920px) {
    .section-01 {
      .carousel-main-page {
        // margin-top: 10vw;
      }
    }
  }
  @media (max-width: 1367px) {
    .section-02 {
      .featured-collections {
        .grid-container-featured {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
  @media (max-width: 1180px) {
    .section-02 {
      .featured-collections {
        .grid-container-featured {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .section-01 {
      padding-bottom: 20px;
      .background-section-01 {
        height: 200px;
      }
    }
  }
  @media (max-width: 900px) {
    .section-01 {
      .content-header {
        padding: 150px 40px 30px 40px;
      }
    }
    .section-02 {
      .featured-collections {
        .grid-container-featured {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  @media (max-width: 768px) {
    .section-01 {
      .custom-slider-main {
        .slick-list {
          border-radius: 0;
        }
      }
      .carousel-main-page {
      }
      .content-header {
        padding: 100px 40px 30px 40px;
        .text-head {
          font-size: 36px;
          line-height: 40px;
        }
        .text-bottom {
          font-size: 18px;
        }
      }
    }
    .section-02 {
      .page-grid {
        .hot-collectibles {
        }
      }
    }
  }
  @media (max-width: 639px) {
    .section-01 {
      // padding-top: 90px;
      .carousel-main-page {
      }
      .custom-slider-main {
        .slick-dots {
          bottom: -26px;
        }
      }
      .background-section-01 {
        img {
          // object-fit: contain;
          // aspect-ratio: 16/8;
          // width: 100%;
          // height: 400px;
          // object-fit: cover;
          // display: none;
        }
      }
      .background-section-01-mobile {
        img {
          width: 100%;
          display: block;
          position: absolute;
        }
      }
      .carousel-main-page {
        // margin-top: 20px;
        .home-carousel {
          z-index: 50;
          .slide-item {
            display: flex;
            & > * {
              flex: 1 1 100%;
              margin: 0 20px;

              img {
                border-radius: 15px;
                width: 100%;
                max-height: 280px;
                height: 280px;
              }
            }
          }
        }
      }
    }
    .section-02 {
      padding: 0 0 0 20px;
      .page-grid {
        &:last-child {
          margin: 0 auto 15px;
        }
        .hot-collectibles-mb {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          overflow-x: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          // .custom-box {
          //   width: 100%;
          //   margin: 10px;
          //   .hot-ollectibles-wrapper {
          //     width: 100%;
          //     height: auto;
          //     .hot-ollectibles-item:nth-child(3) {
          //       .img-token {
          //         width: 80%;
          //         height: 250px;
          //         margin: 15px auto 10px;
          //         img,
          //         video {
          //           width: 100%;
          //           height: 100%;
          //           object-fit: contain;
          //           touch-action: none;
          //         }
          //       }
          //     }
          //     .hot-ollectibles-item:nth-child(5) {
          //       .product-name {
          //         white-space: unset;
          //       }
          //     }
          //   }
          // }
          .custom-box {
            border-radius: 15px;
            display: flex;
            color: var(--font-color);
            width: 162px;
            padding: 10px 0;
            .hot-ollectibles-wrapper {
              position: relative;
              width: 162px;
              height: 100%;
              padding: 8px 8px 12px 8px;
              // gap: 10px;
              .hot-ollectibles-item:nth-child(1) {
                width: auto;
                font-size: 10px;
                // margin: 12px 0px 0px;
                // padding: 0px 0px 0px 10px;
                // flex: 1 1 0%;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .chain-name {
                  font-size: 10px;
                  font-weight: 400;
                  color: #777777;
                  text-transform: capitalize;
                }
              }
              // .hot-ollectibles-item:nth-child(2) {
              //   width: auto;
              //   text-align: right;
              //   // margin: 10px 8px 0 0;
              //   color: var(--color-title-chain);
              //   // opacity: 0.8;
              //   font-size: 10px;
              //   // padding-left: 0px;
              //   text-transform: uppercase;
              // }
              .hot-ollectibles-item:nth-child(2) {
                padding: 4px 0 46px;
                width: fit-content;
                .img-token {
                  width: 146px;
                  height: 146px;
                  position: relative;
                  // margin: 24px auto 14px;
                  img,
                  video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    touch-action: none;
                    border-radius: 8px;
                  }
                }
              }
              .hot-ollectibles-item:nth-child(3) {
                .wrapper-item {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  // margin: 0 10px;
                  // font-weight: 700;
                  font-size: 12px;
                  .content-left {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    gap: 4px;
                    color: var(--color-title-chain);
                    .avatar {
                      min-width: 18px;
                      width: 18px;
                      height: 18px;
                      border-radius: 100%;
                      box-shadow: rgb(255 255 255 / 76%) 0px 0px 0px 1px;
                      overflow: hidden;
                      img {
                        width: 100%;
                        height: 100%;
                        background-color: #ffffff;
                      }
                    }
                  }
                  .content-right {
                    color: $purple-color-primary;
                    font-size: 10px;
                    text-transform: uppercase;
                    white-space: nowrap;
                  }
                }
              }
              .hot-ollectibles-item:nth-child(4) {
                display: flex;
                justify-content: center;
                text-align: center;
                .product-name {
                  font-size: 14px;
                  text-align: left;
                  overflow: hidden;
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-weight: 700;
                  // margin: 8px 10px;
                  padding-top: 6px;
                }
              }
              .hot-items-editions {
                // position: absolute;
                // top: 28px;
                // left: 10px;
                border-radius: 100%;
                background-color: #777777;
                width: 14px;
                height: 14px;
                display: grid;
                place-items: center;
                .icon-eternal {
                  width: 12px;
                  border-radius: 100%;
                }
              }
              .price-and-remaining {
                color: #fff;
                position: absolute;
                background: rgba(27, 27, 53, 0.5);
                backdrop-filter: blur(5px);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 4px;
                gap: 8px;
                left: 8px;
                bottom: -40px;
                width: 130px;
                height: 86px;
                border-radius: 8px;
                opacity: 1;
                flex-direction: column-reverse;
                .wrapper-price {
                  width: 100%;
                }
                .wrapper-remaining {
                  width: 100%;
                }
              }
              .wrapper-price-type {
                color: #fff;
                background: rgba(26, 26, 51, 0.5);
                backdrop-filter: blur(5px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 8px 4px;
                gap: 8px;
                left: 8px;
                bottom: -28px;
                width: 130px;
                height: 58px;
                border-radius: 8px;
                opacity: 1;
                .price-type-home {
                  width: 80px;
                  border-radius: 20px;
                  font-size: 10px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  background-color: #2f8af5;
                  padding: 2px;
                }
              }
            }
            &:last-child {
              margin-right: 20px;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .featured-collections {
        padding-bottom: 15px;
        .featured-collections:first-child {
          padding-bottom: 25px;
        }
        .wrapper-header {
          padding: 0px 20px 6px 0;
          font-size: 18px;
          .show-all-item {
            width: 22px;
          }
        }
        .grid-container-featured {
          grid-template-columns: 1fr;
          .custom-link {
            margin: 10px;
            height: 260px;
            width: 100%;
            .grid-item {
              width: 100%;
            }
          }
        }
        .featured-creators-mb {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 12px;
          overflow-x: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          .custom-link {
            text-decoration: none;
            color: #000000;
            display: inline-block;
            min-width: 158px;
            height: 158px;
            // border-radius: 15px;
            // margin: 10px 0;
            .grid-item {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              -webkit-box-pack: end;
              justify-content: flex-end;
              border: var(--border-box-card);
              border-radius: 15px;
              margin: 0;
              padding: 0;
              overflow: hidden;
              position: relative;
              // box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;

              .banner-image {
                width: 100%;
                height: 80px;
                img {
                  // position: absolute;
                  top: 0px;
                  height: 80px;
                  width: 100%;
                  z-index: 2;
                  object-fit: cover;
                  touch-action: none;
                }
              }

              .wrapper-content {
                padding: 0px 10px 20px;
                z-index: 3;
                flex: 0.5 1 0%;
                text-align: left;
                width: 100%;
                color: var(--font-color);
                background-color: var(--background-color);
                height: 50%;
                .avatar {
                  position: absolute;
                  top: 35%;
                  z-index: 1;
                  width: 44px;
                  height: 44px;
                  img {
                    min-width: 44px;
                    min-height: 44px;
                    max-width: 44px;
                    max-height: 44px;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: 2px solid #ffffff;
                    object-fit: cover;
                    background-color: #ffffff;
                  }
                }

                .name-label {
                  margin-top: 30px;
                  margin-bottom: 2x;
                  font-size: 14px;
                  letter-spacing: -1px;
                  font-weight: 700;
                }

                .total-item {
                  font-size: 12px;
                  font-weight: 500;
                  color: #777777;
                }
              }
            }
            &:last-child {
              margin-right: 20px;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .padding-section01 {
        .wrapper-header {
          padding: 40px 20px 12px 0;
        }
        .spacing-featured {
          padding: 50px 20px 12px 0;
        }
      }
    }
  }
  @media (max-width: 540px) {
    .section-01 {
      .background-section-01-mobile {
        img {
          width: 100%;
          display: block;
          position: absolute;
        }
      }
      .content-header {
        padding: 75px 20px 30px 20px;
        margin-bottom: 50px;
        .text-head {
          font-size: 36px;
          line-height: 35px;
          padding-top: 280px;
        }
        .text-bottom {
          font-size: 14px;
          max-width: 280px;
        }
        .text-head-pc {
          display: none;
        }
        .text-head-mobile {
          display: block;
        }
      }
      .carousel-main-page {
        // margin-top: 70px;
        .home-carousel {
          .slide-item {
            display: flex;
            & > * {
              flex: 1 1 100%;
              margin: 0 20px;

              img {
                border-radius: 20px;
                height: 280px;
                max-height: 280px;
                width: 100%;
                touch-action: none;
              }
            }
          }
        }
      }
    }
    .section-02 {
      .featured-collections {
        .wrapper-header {
          // padding: 40px 0 10px;
        }
        // padding-bottom: 40px;
      }
      .page-grid {
        .hot-collectibles {
        }
      }
    }
  }
  @include Mobile() {
    .section-01 {
      // .content-header {
      //   padding: 90px 20px 30px 20px;
      //   .text-head {
      //     font-size: 36px;
      //     line-height: 40px;
      //   }

      //   .text-bottom {
      //     font-size: 16px;
      //     max-width: 100%;
      //   }
      // }
      .background-section-01 {
        img {
          // object-fit: contain;
          // aspect-ratio: 16/8;
          // width: 100%;
          // height: 400px;
          // object-fit: cover;
          // display: none;
        }
      }
      .background-section-01-mobile {
        img {
          width: 100%;
          display: block;
          position: absolute;
        }
      }
      .carousel-main-page {
        // margin-top: 20px;
        .home-carousel {
          z-index: 50;
          .slide-item {
            display: flex;
            & > * {
              flex: 1 1 100%;
              margin: 0 20px;

              img {
                border-radius: 15px;
                width: 100%;
                height: 200px;
                touch-action: none;
              }
            }
          }
        }
      }
    }
    .section-02 {
      // margin-top: 50px;
      .featured-collections {
        .wrapper-header {
          margin: auto;
          gap: 10px;
          // .header-name {
          //   font-size: 22px;
          // }
          .show-all-item {
            // font-size: 14px;
          }
        }
      }
      .grid-container-featured {
      }
      .page-grid {
        .hot-collectibles {
        }
        .title-header {
          // font-size: 22px;
          // margin-bottom: 5px;
        }
        margin: 0 auto;
        &:last-child {
          padding-bottom: 0;
          // margin: 0 auto 74px;
        }
      }
    }
  }
  @media (max-width: 400px) {
    .section-01 {
      .background-section-01-mobile {
        img {
          width: 100%;
          display: block;
          position: absolute;
        }
      }
      .content-header {
        padding: 60px 20px 30px 20px;
        margin-bottom: -20px;
      }
      .text-head {
        font-size: 36px;
        line-height: 35px;
        padding-top: 100px;
      }
    }
    .section-02 {
      .featured-collections {
        .wrapper-header {
          gap: 10px;
          // .header-name {
          //   font-size: 20px;
          // }
        }
      }
      .grid-container-featured {
      }
      .page-grid {
        .hot-collectibles {
        }
      }
    }
  }
  @media (max-width: 340px) {
    .section-01 {
      .background-section-01-mobile {
        img {
          width: 100%;
          display: block;
          position: absolute;
        }
      }
      .content-header {
        padding: 60px 20px 30px 20px;
        margin-bottom: 100px;
        .text-head {
          font-size: 36px;
          line-height: 35px;
          padding-top: 100px;
        }
        .text-bottom {
          font-size: 12px;
        }
      }
    }
    .section-02 {
      .grid-container-featured {
      }
      .page-grid {
        .hot-collectibles {
        }
      }
    }
  }
}
