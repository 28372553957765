@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@keyframes top_to_down {
  0% {
    opacity: 0;
    margin-top: -20px;
    padding-top: -20px;
  }

  10% {
    opacity: 0.3;
  }

  20% {
    opacity: 0.5;
  }

  40% {
    opacity: 0.8;
  }

  60% {
    opacity: 1;
    margin-top: 0;
    padding-top: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    box-shadow: 0 0 0 hsl(0deg 0% 95% / 0%);
    opacity: 0;
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }

  1% {
    box-shadow: 0 0 0 hsl(0deg 0% 95% / 0%);
    opacity: 0;
    -webkit-transform: scale(0.96) translateY(10px);
    transform: scale(0.96) translateY(10px);
  }

  100% {
    box-shadow: 0 0 500px hsl(0deg 0% 95% / 0%);
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }
}

.popup-content {
  margin: auto;
  background: transparent;
  width: 600px;
  padding: 0px;
  border: none;
  display: flex;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Poppins,
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  .login_form {
    animation: 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards fade-in;
    z-index: 30;
    // background-color: #23235c;
    background-color: var(--background-modal);
    color: var(--font-color);
    border: none;
    outline: none;
    margin: auto;
    border-radius: 30px;
    height: 470px;
    width: 600px;

    .box-content {
      position: relative;
      height: 100%;
      text-align: center;

      input {
        border: 1px solid white;
        border-radius: 10px;
        padding: 10px 10px 14px;
        outline: none;
        font-size: 18px;
        background: transparent;
        color: white;
      }

      button {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        border: none;
        cursor: pointer;
      }

      .div-title {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        // display: flex;
        // -webkit-box-pack: justify;
        // justify-content: space-between;
        // margin-bottom: 25px;
        // height: 44px;
        // -webkit-box-align: center;
        // align-items: center;
        // padding-top: 5%;
        // padding-right: 10%;
        display: flex;
        justify-content: flex-end;
        height: 80px;
        align-items: center;
        padding-right: 30px;

        .title {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          // font-size: 24px;
          // font-weight: 600;
          // flex: 1 1 0%;
          // padding-left: 20%;
          font-size: 24px;
          font-weight: 600;
          flex: 1 1;
          padding-left: 20%;
        }
      }
      .div-methods {
        color: white;
        font-size: 15px;
      }

      .methods {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 60%;
        margin: 35px auto 15px;
        -webkit-box-pack: justify;
        justify-content: space-between;

        button {
          cursor: pointer;
          width: 50px;
          height: 50px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 4px;
        }

        .method-apple {
          background-color: black;
        }

        .method-twitter {
          background-color: #54b0e1;
        }

        .method-google {
          background-color: white;
        }
      }

      .text_switch {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        height: 50px;
        text-align: center;
        margin: 10px auto 0px;
        width: 100%;
        margin-top: 5%;

        button {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          font-size: 15px;
          font-weight: 600;
          color: white;
          border: none;
          background-color: transparent;
        }
      }
      .box-input {
        padding: 27px 30px 0;
        .div-mobile {
          color: white;
          font-size: 15px;
          margin-top: 5%;
        }

        form {
          .phone_number {
            margin-top: 5%;
            font-size: 18px;
            border: 1px solid white;
            border-radius: 10px;
            padding: 3px 10px;

            .react-tel-input {
              // font-family: Poppins, -apple-system, BlinkMacSystemFont,
              //   'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
              //   'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              font-size: 15px;
              position: relative;
              width: 100%;

              .country-list {
                .flag {
                  transform: scale(1.5);
                }

                .country {
                  &:hover {
                    background-color: #ffffff40;
                  }
                }

                .country.highlight {
                  background-color: #ffffff40;
                }
              }

              .selected-flag {
                &:hover {
                  background-color: transparent;
                }

                &:focus {
                  background-color: transparent;
                }

                .flag {
                  transform: scale(1.5);
                }
              }

              .flag-dropdown.open {
                .selected-flag {
                  background: transparent;
                  border-radius: 3px 0 0 0;
                }
              }
            }

            .react-tel-input {
              > input.form-control {
                width: 100%;
                border: none;
                padding: 0px 0px 0px 35px;
                font-size: 16px;
                background-color: transparent;
                margin-left: 4%;
              }
            }
          }
        }

        .button-forgot-password {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          font-weight: 100;
          font-size: 14px;
          margin-top: 10px;
          color: white;
          background-color: transparent;
        }

        .div-keep_logged_in {
          margin-top: 20px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;

          input {
            border: 1px solid white;
            border-radius: 10px;
            padding: 10px 10px 14px;
            outline: none;
            font-size: 18px;
            background: transparent;
            color: white;
          }

          span {
            margin-left: 15px;
          }
        }
        .box-top {
          width: 100%;
          height: 94px;
          background-color: var(--background-box-wallet);
          display: flex;
          align-items: center;
          border-radius: 10px;
          .div-wallets_1 {
            background-color: transparent;
            height: 62px;
            overflow: hidden;
            display: flex;
            justify-content: space-around;
            gap: 8px;
            padding: 0 16px;
            width: 100%;
            button {
              font-size: 14px;
              font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
                'Segoe UI', Poppins, 'Oxygen', 'Ubuntu', 'Cantarell',
                'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              color: rgb(65, 65, 65);
              width: 100%;
              height: 100%;
              border-radius: 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              background-color: rgba(121, 121, 121, 0.18);
              color: white;
              &.active {
                background-color: #bae7ff;
                //border: 2px solid #2f8af5;
                //color: rgba(121, 121, 121, 0.8);;
                color: white;
              }

              &.unused {
                background-color: rgba(121, 121, 121, 0.18);
                color: white;
                cursor: default;
              }
              &.focused {
                //background-color: rgba(121, 121, 121, 0.18);
                background-color: white;
                //border: 2px solid #2f8af5;
                color: rgba(121, 121, 121, 0.8);
              }
              .custom-image {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }

              // &:not(:last-child) {
              //   margin-right: 2%;
              // }
            }
          }
        }
        .div-wallets_2 {
          width: 100%;
          height: 235px;

          background-color: var(--background-box-wallet);
          border-radius: 10px;
          margin: 16px 0px 33px;
          .row-wallet {
            background-color: transparent;
            width: 100%;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row: auto;
            gap: 8px;
            padding: 16px;

            &:not(:last-child) {
              margin-bottom: 3%;
            }

            button {
              font-size: 14px;
              text-align: left;
              font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
                'Segoe UI', Poppins, 'Oxygen', 'Ubuntu', 'Cantarell',
                'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              color: rgb(0, 0, 0);
              width: 100%;
              height: 62px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              gap: 8px;
              padding-left: 20px;
              background: #ffffff;
              // &:not(:last-child) {
              //   margin-right: 2%;
              // }

              &.active {
                background-color: #bae7ff;
                border: 2px solid #2f8af5;
              }
              .custom-image {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }
            }
          }
          .error-message {
            width: 100%;
            //border: 1px solid red;
            margin-top: -10px;
            color: red;
          }
        }

        .animated {
          animation: top_to_down 3s;

          .reCaptcha {
            margin-top: 8%;
            margin-bottom: 8%;
          }
        }

        .button-login {
          background: linear-gradient(
            to left,
            rgb(103, 129, 249),
            rgb(227, 100, 190)
          );
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          width: 100%;
          margin-top: 25px;
          border-radius: 40px;
          outline: none;
          font-size: 22px;
          height: 70px;
          color: white;
        }
      }

      .button-close {
        background-color: transparent;
        padding: 0;
      }
    }
  }
  @media (max-width: 600px) {
    .login_form {
      border-radius: 0;
      height: 100vh;
      width: 100vw;

      .box-content {
        .div-title {
          .title {
            font-size: 22px;
          }
        }
        form {
          .box-input {
            padding: 27px 10px 0;
            .box-top {
              .div-wallets_1 {
                padding: 0 10px;
                button {
                  font-size: 12px;
                  flex-direction: column;
                }
              }
            }

            .div-wallets_2 {
              .row-wallet {
                button {
                  // font-size: 11.7px;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 340px) {
    .login_form {
      .box-content {
        form {
          .box-input {
            .box-top {
              .div-wallets_1 {
                button {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  // @include Mobile() {
  //   .login_form {
  //     border-radius: 0;
  //     height: 100vh;
  //     width: 100vw;

  //     .box-content {
  //       form {
  //         .box-input {
  //           .div-wallets_1 {
  //             button {
  //               font-size: 9px;
  //             }
  //           }

  //           .div-wallets_2 {
  //             .row-wallet {
  //               button {
  //                 font-size: 11.7px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

