// side bar before login
.side-bar {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: fixed;
  top: 0;
  z-index: 99999;
  left: 100%;
  // padding: 20px 20px 0;
  transition: all 1000ms;
  font-weight: 500;
  &.show {
    // left: 0;
    transform: translateX(-100%);
  }
  .wrapper-backdrop {
    background: var(--background-modal);
    width: 35%;
    height: 100%;
    position: absolute;
    right: 0;
    box-shadow: rgb(99 99 99 / 30%) 0px 2px 8px 0px;
    .user-dropdown-box {
      position: unset;
      width: 100%;
      // padding: 0;
    }
  }

  .close-sidebar {
    text-align: right;
    padding: 20px 20px 15px;

    .icon-close {
      display: inline-block;
      cursor: pointer;
      img {
        width: 20px;
      }
    }
  }

  .login-signup {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    text-transform: uppercase;
    span {
      background-color: #2f8af5;
      background-clip: text;
      color: transparent;
      font-weight: 600;
      cursor: pointer;
    }
    .wrapper-wallets {
      display: block;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      color: #2f8af5;
      // padding: 13px 0;

      .btn-wallets {
        display: flex;
        justify-content: flex-start;
        gap: 12px;
        height: 25px;
        align-items: center;
        text-transform: none;
        .img-wallet {
          width: 25px;
          height: 25px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .wrapper {
    // margin-top: 25px;
    padding: 0 20px;

    .wrapper-item {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 12px;

      img {
        // width: 25px;
        // height: 25px;
      }
    }

    // .help {
    //   font-size: 16px;
    //   display: flex;
    //   align-items: center;
    //   margin-bottom: 15px;
    //   gap: 10px;

    //   img {
    //     width: 25px;
    //     height: 25px;
    //   }
    // }
  }

  .fanpage-icons-sidebar {
    border-top: 1px solid var( --line-bottom-boxcard);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 20px;

    a {
      width: 30px;

      img {
        width: 100%;
      }
    }
  }
  // tablet view
  @include Tablet() {
  }
  //mobile view

  @media (max-width: 540px) {
    transition: all 500ms;
    .wrapper-backdrop {
      width: 55%;
      .user-dropdown-box {
        padding: 0;
      }
    }
    width: 100%;
  }
  @media (max-width: 340px) {
    .login-signup {
      font-size: 14px;
    }
  }
}
